import { ArrowDownward, ArrowUpward, ClearAll } from "@mui/icons-material";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { SubjectService } from "../../../services/SubjectService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import AddSubject from "./AddSubject";
import EditSubject from "./EditSubject";

export function Subject() {
    const { user } = useContext(AppContext);
    const [changes, setChanges] = useState(0);
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        classroom: "",
        publisher: "",
        search: "",
        status: "",
        apply: 0,
    });

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus: "",
            classroom: "",
            publisher: "",
            search: "",
            status: "",
            apply: 0,
        });
    };

    const ChangeSubjectSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await SubjectService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <Grid container justifyContent="space-between" align-item="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Subject
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE SUBJECT"]}>
                        <AddView
                            label="add subject"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddSubject />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    {/* <Grid item xs={12} lg={3}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid> */}

                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} lg={3}>
                        {/* <SearchableInput
                            api={`${baseAPI}/org/academics/classroom`}
                            label="Classroom"
                            _name="classroom"
                            filter={filter}
                            setFilter={setFilter}
                            params={{ campus: filter.campus ?? undefined }}
                            dep={filter.campus}
                        /> */}
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    classroom: c?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            apiParams={{
                                campus: filter.campus,
                                status: "active",
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Autocomplete
                            setOutput={(c) =>
                                setFilter({
                                    ...filter,
                                    status: c?.id || "",
                                })
                            }
                            label="Status"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultOptions={[
                                { id: "active", name: "Active" },
                                { id: "inactive", name: "Inactive" },
                            ]}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Apply Filter
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    SubjectService.getSubject(page, limit, {
                        campus: filter.campus ? filter.campus : undefined,
                        status: filter.status ? filter.status : undefined,
                        classroom: filter.classroom
                            ? filter.classroom
                            : undefined,

                        search: filter.search ? filter.search : undefined,
                    })
                }
                editor={SubjectService.editSubject}
                deletor={
                    user?.baseUser?.ops.includes("DELETE SUBJECT")
                        ? (id: string | number) =>
                              SubjectService.deleteSubjectSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    classroomName: row.classroom.name,
                    publisherName: row.publisher?.name || "No publisher",
                    campusName: row.classroom.campus.name,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    classroomName: "Class",
                    publisherName: "Publisher",
                    campusName: "Campus",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                    status: "Status",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeSubjectSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE SUBJECT")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE SUBJECT"]}>
                                <AddView
                                    label="Edit subject"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <EditSubject row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, filter.search, changes]}
                ops={{
                    read: "READ SUBJECT",
                    edit: "",
                    delete: "DELETE SUBJECT",
                }}
                storageKey="subject"
            />
        </div>
    );
}
