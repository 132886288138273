import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class CustomFieldService {
  static url = `${baseAPI}/customfield`;

  static async createCustomFields(data: any) {
    try {
      const response = await axios.post(CustomFieldService.url, data, {
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getCustomFields(page = 1, limit = 10, filter = {}) {
    try {
      const response = await axios.get(CustomFieldService.url, {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getCustomFieldsTemplates(page = 1, limit = 10, filter = {}) {
    try {
      const response = await axios.get(CustomFieldService.url + "/template", {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getCustomFieldsTemplateComponents(
    page = 1,
    limit = 10,
    filter = {}
  ) {
    try {
      const response = await axios.get(
        CustomFieldService.url + "/template-component",
        {
          params: { page, limit, ...filter },
          withCredentials: true,
        }
      );

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteTemplateComponentSoft(id: string | number) {
    try {
      const response = await axios.delete(
        CustomFieldService.url + "/template-component/soft",
        {
          withCredentials: true,
          params: { id },
        }
      );
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteHard(id: string | number) {
    try {
      const response = await axios.delete(CustomFieldService.url + "/hard", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }
}
