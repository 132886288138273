import {
  Checkbox,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Pagination,
} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useGetAPI } from "../../../services/Hooks";
import { baseAPI } from "../../constants";
import SearchBar from "./SearchBar";

type Props = {
  user: any;
  setUser: Dispatch<SetStateAction<any>>;
  changes: any;
  setChanges: Dispatch<SetStateAction<any>>;
  updateMode: boolean;
};

type RegionStackProps = {
  label: string;
  api: string;
  variant: "campus" | "classroom" | "section";
  regions: any;
  setRegions: Dispatch<SetStateAction<any[]>>;
  changes: any;
  setChanges: Dispatch<SetStateAction<any>>;
  updateMode: boolean;
};

const RegionStack = ({
  label,
  api,
  variant,
  regions,
  setRegions,
  updateMode,
  changes,
  setChanges,
}: RegionStackProps) => {
  const [filter, setFilter] = useState<any>({ search: "" });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
    count: 0,
  });

  const otherOps = JSON.stringify([
    {
      op: "in",
      isDate: false,
      operands: [
        regions?.map(
          (r: any) => r[variant === "section" ? "classroom" : "campus"]
        ),
      ],
      col: variant === "section" ? "classroom" : "campus",
    },
  ]);

  const [res, count, status, message] = useGetAPI(
    api,
    pagination.page,
    pagination.limit,
    {
      otherOps: variant !== "campus" ? otherOps : undefined,
      search: filter.search ?? undefined,
    },
    [
      label,
      changes[
        variant === "classroom"
          ? "campus"
          : variant === "section"
          ? "classroom"
          : ""
      ],
      filter,
      pagination.page,
      pagination.limit,
    ]
  );

  /*  const { data: res, isFetching } = useQuery(
        [
            label,
            changes[
                variant === "classroom"
                    ? "campus"
                    : variant === "section"
                    ? "classroom"
                    : ""
            ],
            filter,
            pagination.page,
            pagination.limit,
        ],
        () => {
            const otherOps =
                variant !== "campus"
                    ? JSON.stringify([
                          {
                              op: "in",
                              isDate: false,
                              operands: [
                                  regions.map(
                                      (r: any) =>
                                          r[
                                              variant === "section"
                                                  ? "classroom"
                                                  : "campus"
                                          ]
                                  ),
                              ],
                              col:
                                  variant === "section" ? "classroom" : "campus",
                          },
                      ])
                    : undefined;

            return GET(api, {
                otherOps,
                search: filter.search,
                page: pagination.page,
                limit: pagination.limit,
            });
        },
        {
            onSuccess(res) {
                setPagination({ ...pagination, count: res.data.count });
            },
        }
    ); */

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
  }, [filter.search]);

  const handleChange = (ev: ChangeEvent<any>, checked: boolean) => {
    if (variant === "campus") {
      const targetRegion = regions?.find(
        (r: any) => r.campus == ev.target.value
      );

      if (targetRegion) {
        setRegions(regions?.filter((r: any) => r != targetRegion));
      } else {
        setRegions([...regions, { campus: ev.target.value }]);
      }
    } else if (variant === "classroom") {
      const classroom = res?.find((v: any) => v.id == ev.target.value);
      const campusId = classroom.campus.id;

      const targetRegions = regions.filter((r: any) => r.campus == campusId);

      const newTargetRegions: any[] = [];
      let found = false;

      for (const region of targetRegions) {
        if (region.classroom == ev.target.value) {
          newTargetRegions.push({ ...region, classroom: null });
          found = true;
        } else {
          newTargetRegions.push(region);
        }
      }

      const finalTargetRegions: any[] = [];
      let set = false;

      if (!found) {
        for (const r of targetRegions) {
          if (!r.classroom && !set) {
            finalTargetRegions.push({
              ...r,
              classroom: ev.target.value,
            });
            set = true;
          } else {
            finalTargetRegions.push(r);
          }
        }

        if (set) {
          setRegions([
            ...regions.filter((r: any) => !targetRegions.includes(r)),
            ...finalTargetRegions,
          ]);
        } else {
          setRegions([
            ...regions,
            { campus: campusId, classroom: ev.target.value },
          ]);
        }
      } else {
        setRegions([
          ...regions.filter((r: any) => !targetRegions.includes(r)),
          ...newTargetRegions,
        ]);
      }
    } else {
      const section = res?.find((h: any) => h.id == ev.target.value);
      const classroomId = section.classroom.id;

      const targetRegions = regions.filter(
        (r: any) => r.classroom == classroomId
      );

      const newTargetRegions: any[] = [];
      let found = false;

      for (const region of targetRegions) {
        if (region.section == ev.target.value) {
          newTargetRegions.push({ ...region, section: null });
          found = true;
        } else {
          newTargetRegions.push(region);
        }
      }

      const finalTargetRegions: any[] = [];
      let set = false;

      if (!found) {
        for (const r of targetRegions) {
          if (!r.section && !set) {
            finalTargetRegions.push({
              ...r,
              section: ev.target.value,
            });
            set = true;
          } else {
            finalTargetRegions.push(r);
          }
        }

        if (set) {
          setRegions([
            ...regions.filter((r: any) => !targetRegions.includes(r)),
            ...finalTargetRegions,
          ]);
        } else {
          setRegions([
            ...regions,
            {
              campus: targetRegions[0].campus,
              classroom: classroomId,
              section: ev.target.value,
            },
          ]);
        }
      } else {
        setRegions([
          ...regions.filter((r: any) => !targetRegions.includes(r)),
          ...newTargetRegions,
        ]);
      }
    }

    setChanges({ ...changes, [variant]: changes[variant] + 1 });
  };

  return (
    <>
      <div>
        <SearchBar filter={filter} setFilter={setFilter} />
        {status === "loading" && <CircularProgress size="1.5rem" />}
        <List dense sx={{ width: "100%" }}>
          {res?.map((row: any) => (
            <ListItem key={row.id}>
              <ListItemText primary={row.name} />
              <ListItemSecondaryAction>
                <Checkbox
                  checked={Boolean(
                    regions?.find((r: any) => r[variant] == row.id)
                  )}
                  onChange={handleChange}
                  value={row?.id}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <Pagination
        size="small"
        count={Math.ceil(count / pagination.limit)}
        page={pagination.page}
        onChange={(ev, page) => setPagination({ ...pagination, page })}
      />
    </>
  );
};

export const UserRegions = ({
  user,
  setUser,
  updateMode,
  changes,
  setChanges,
}: Props) => {
  return (
    <>
      <Grid item xs={4}>
        <RegionStack
          api={`${baseAPI}/org/campus`}
          label="Campus"
          variant="campus"
          regions={user.regions}
          setRegions={(value: any) => setUser({ ...user, regions: value })}
          updateMode={updateMode}
          changes={changes}
          setChanges={setChanges}
        />
      </Grid>

      <Grid
        item
        xs={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <RegionStack
          api={`${baseAPI}/org/academics/classroom`}
          label="Classroom"
          variant="classroom"
          regions={user.regions}
          setRegions={(value: any) => setUser({ ...user, regions: value })}
          updateMode={updateMode}
          changes={changes}
          setChanges={setChanges}
        />
      </Grid>

      <Grid
        item
        xs={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <RegionStack
          api={`${baseAPI}/org/academics/section`}
          label="Section"
          variant="section"
          regions={user.regions}
          setRegions={(value: any) => setUser({ ...user, regions: value })}
          updateMode={updateMode}
          changes={changes}
          setChanges={setChanges}
        />
      </Grid>
    </>
  );
};
