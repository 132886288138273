import { ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useGetAPI, usePostAPI } from "../../../services/Hooks";
import { baseAPI } from "../../constants";
import CertificateText from "./CertificateText";
import Footer from "./Footer";
import Header from "./Header";
import UserType from "./UserType";
import Variables from "./Variables";

type Props = {
    fields: any;
    setFields: Dispatch<SetStateAction<any>>;
};

type Pagination = {
    campus: { page: number; limit: number };
};

const useStyles = makeStyles((theme: Theme) => ({
    scrollBar: {
        "&::-webkit-scrollbar": {
            width: "6px",
            borderRadius: "1000px",
        },

        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.background.default,
            borderRadius: "2.5px",
        },

        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
    },
}));

const Menu = ({ fields, setFields }: Props) => {
    const classes = useStyles();

    const [pagination, setPagination] = useState<Pagination>({
        campus: { page: 1, limit: 0 },
    });
    const [feedback, setFeedback] = useState<any>({
        message: "",
        loading: false,
        hidden: true,
        severity: "",
    });
    const [filter, setFilter] = useState<any>({
        type: "student",
    });
    const [variables, setVariables] = useState<any>({
        student: {
            name: { label: "Name", checked: true },
            fatherName: { label: "Father Name", checked: true },
            campus: { label: "Campus", checked: true },
            gender: { label: "Gender", checked: false },
            fileNo: { label: "File No.", checked: false },
            slcNo: { label: "SLC No.", checked: false },
            dob: { label: "Date of Birth", checked: false },
        },

        employee: {
            name: { label: "Name", checked: true },
            gender: { label: "Gender", checked: false },
            dob: { label: "Date of Birth", checked: false },
        },

        faculty: {
            name: { label: "Name", checked: true },
            gender: { label: "Gender", checked: false },
            dob: { label: "Date of Birth", checked: false },
        },
    });

    const selectedVariables = useMemo(
        () =>
            Object.entries(variables)
                .map(([k, v]: any) => ({
                    [k]: Object.values(v)
                        .filter((item: any) => item.checked === true)
                        .map((item: any) => item.label),
                }))
                .filter((item) => item[filter.type] !== filter.item),
        [variables, filter.type]
    );

    const [saveCertificate, response, status, message] = usePostAPI(
        `${baseAPI}/certificate`
    );

    const [customFields, customFieldsCount, customFieldsStatus] = useGetAPI(
        `${baseAPI}/customfield`,
        pagination.campus.page,
        pagination.campus.limit,
        {},
        [pagination.campus]
    );

    const handleSaveCertificate = async () => {
        setFeedback({ ...feedback, loading: true });

        saveCertificate(
            {
                type: fields.type,
                content: JSON.stringify({
                    title: fields.title,
                    header: fields.header,
                    subTitle: fields.subTitle,
                    userName: fields.userName,
                    body: fields.body,
                    footer: fields.footer,
                    additionalBody: fields.additionalBody,
                }),
            },
            {}
        );
    };

    useEffect(() => {
        if (status === "success") {
            setFeedback({
                message: response.message,
                loading: false,
                severity: "success",
                hidden: false,
            });
        }
        if (status === "error") {
            setFeedback({
                message: message,
                loading: false,
                severity: "error",
                hidden: false,
            });
        }
    }, [status]);

    useEffect(() => {
        if (customFieldsStatus === "success") {
            setFields({
                ...fields,
                footer: customFields
                    .filter((field: any) => field.type === "image")
                    .map((field: any) => ({
                        id: field.id,
                        targetId: field.targetId,
                        targetType: field.targetType,
                        key: field.key,
                        value: field.value,
                        type: field.type,
                        checked: false,
                    })),
            });
        }
    }, [customFieldsStatus, pagination.campus]);

    return (
        <Grid
            item
            xs={4}
            sx={{
                position: "fixed",
                height: "calc(100vh - 80px)",
                right: "8px",
                left: "calc(70% + 8px)",
                overflowY: "auto",
            }}
            component={Paper}
            variant="outlined"
            className={classes.scrollBar}
        >
            <Option label="Select a user type">
                <UserType filter={filter} setFilter={setFilter} />
            </Option>

            <Option label="Header">
                <Header fields={fields} setFields={setFields} />
            </Option>

            <Option label="Variables">
                <Variables
                    variables={variables}
                    setVariables={setVariables}
                    userType={filter?.type}
                />
            </Option>

            <Option label="Certificate Text">
                <CertificateText
                    fields={fields}
                    setFields={setFields}
                    variables={selectedVariables}
                    userType={filter.type}
                />
            </Option>

            <Option label="Footer">
                <Footer
                    fields={fields}
                    setFields={setFields}
                    count={customFieldsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </Option>

            <Button
                fullWidth
                variant="outlined"
                onClick={handleSaveCertificate}
                disabled={feedback.loading}
                endIcon={
                    feedback.loading ? <CircularProgress size="1rem" /> : null
                }
            >
                {feedback.loading ? " saving template..." : "save template"}
            </Button>

            {!feedback.hidden && (
                <Alert severity={feedback.severity} sx={{ m: 2 }}>
                    {feedback.message}
                </Alert>
            )}
        </Grid>
    );
};

export default Menu;

// ====================================

type OptionProps = {
    label: string;
    children: JSX.Element;
};

const Option = ({ label, children }: OptionProps) => {
    return (
        <Accordion variant="outlined" disableGutters>
            <AccordionSummary expandIcon={<ExpandMore />}>
                {label}
            </AccordionSummary>

            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};
