import { DarkMode, FormatPaint, LightMode } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useContext, useRef } from "react";
import { Mode } from "../types";
import { AppContext } from "./AppContext";

const ThemeToggle = () => {
    const { mode, setMode } = useContext(AppContext);
    const btnRef = useRef<any>(null);

    const handleModeChange = (mode: Mode) => {
        setMode(mode);
        localStorage.setItem("theme", mode);
    };

    return (
        <div
            title="Toggle Theme"
            style={{ display: "flex", gap: "1rem", alignItems: "center" }}
        >
            <IconButton onClick={() => handleModeChange("dark")}>
                <DarkMode color={mode === "dark" ? "primary" : "inherit"} />
            </IconButton>
            <IconButton onClick={() => handleModeChange("light")}>
                <LightMode color={mode === "light" ? "primary" : "inherit"} />
            </IconButton>
            <IconButton onClick={() => handleModeChange("custom")}>
                <FormatPaint
                    color={mode === "custom" ? "primary" : "inherit"}
                />
            </IconButton>
        </div>
    );
};

export default ThemeToggle;
