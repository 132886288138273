import { Breadcrumbs, Link, Paper, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme: Theme) => ({
  breadcrum: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
}));
export const BaseBreadcrum = ({ links }: any) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb">
        {links.map((link: any) => (
          <Link key={link} href={link.linkUrl}>
            {link.linkName}
          </Link>
        ))}
      </Breadcrumbs>
    </Paper>
  );
};
