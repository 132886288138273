import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";
import { UserService } from "./UserService";

export class ClientService {
    static url = `${baseAPI}/superadmin/client`;

    static async createClient(data: any) {
        try {
            const response = await axios.post(ClientService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClient(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(ClientService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClientTheme() {
        try {
            const response = await axios.get(
                UserService.url + "/client-theme",
                {
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteClientSoft(id: string | number) {
        try {
            const response = await axios.delete(ClientService.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteClientHard(id: string | number) {
        try {
            const response = await axios.delete(ClientService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editClient(id: string, newClient: any) {
        try {
            const response = await axios.patch(ClientService.url, newClient, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async updateTheme(id: string, theme: string) {
        try {
            const response = await axios.patch(
                ClientService.url + "/client-theme",
                { theme },
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClientTypes(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                ClientService.url + "/client-types",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClientPackages(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                ClientService.url + "/client-packages",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClientStatuses(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                ClientService.url + "/client-statuses",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
