import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class DashboardService {
    static url = `${baseAPI}/org/dashboard`;

    static async getData() {
        try {
            const response = await axios.get(DashboardService.url, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
