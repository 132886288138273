import { Avatar, Grid, Typography } from "@mui/material";

const Academic = ({ fields }: any) => {
    console.log(fields);
    if (!fields) return <h1>fields not found</h1>;

    return (
        <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
            sx={{
                bgcolor: "white",
                color: "black",
                aspectRatio: "11/8.5",
                p: 2,
                width: "750px",
            }}
        >
            <Grid
                item
                container
                alignItems="center"
                justifyContent={fields?.header?.position}
                width="90%"
            >
                {fields?.header?.logo && (
                    <Grid item>
                        <img src="/images/icon.png" width="75px" />
                    </Grid>
                )}

                {fields?.header?.profilePicture && (
                    <Grid item>
                        <Avatar sx={{ width: "60px", height: "60px" }}>
                            {fields?.userName.text[1]}
                        </Avatar>
                    </Grid>
                )}
            </Grid>

            <Grid item>
                <Typography
                    textTransform="uppercase"
                    sx={{
                        color: fields?.subTitle.color,
                        fontSize: fields?.subTitle.fontSize,
                    }}
                    fontWeight="bold"
                    letterSpacing={2}
                >
                    {fields?.subTitle.text}
                </Typography>
            </Grid>

            <Grid item>
                <Typography
                    variant="h4"
                    textTransform="uppercase"
                    fontWeight="bold"
                    sx={{
                        color: fields?.userName.color,
                        fontSize: fields?.userName.fontSize,
                    }}
                >
                    {fields?.userName.text}
                </Typography>
            </Grid>

            <Grid item>
                <Typography
                    width="60%"
                    mx="auto"
                    textAlign="center"
                    sx={{
                        color: fields?.body.color,
                        fontSize: fields?.body.fontSize,
                    }}
                >
                    {fields?.body.text}
                </Typography>
            </Grid>

            <Grid item>
                <Typography
                    variant="h3"
                    textTransform="uppercase"
                    letterSpacing={2}
                    fontWeight="bold"
                    sx={{
                        color: fields?.title.color,
                        fontSize: fields?.title.fontSize,
                    }}
                >
                    {fields?.title.text}
                </Typography>
            </Grid>

            <Grid item>
                <Typography
                    width="60%"
                    mx="auto"
                    textAlign="center"
                    sx={{
                        color: fields?.additionalBody?.color,
                        fontSize: fields?.additionalBody?.fontSize,
                    }}
                >
                    {fields?.additionalBody.text}
                </Typography>
            </Grid>

            <Grid
                item
                container
                width="90%"
                spacing={1}
                alignItems="end"
                justifyContent="space-between"
            >
                {fields?.footer
                    ?.filter((item: any) => item.checked)
                    .map((item: any, id: number) => (
                        <Grid item key={id} textAlign="center">
                            <img src={item.imgUrl} width="75px" />

                            <span
                                style={{
                                    width: "100%",
                                    height: "2px",
                                    backgroundColor: "gray",
                                    display: "block",
                                }}
                            />
                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                color="Gray"
                            >
                                {item.key}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    );
};

export default Academic;
