import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class UserService {
    static url = `${baseAPI}/org/user`;

    static async loginUser(data: any) {
        try {
            const response = await axios.post(
                UserService.url + "/login",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async logoutUser() {
        try {
            const response = await axios.post(
                UserService.url + "/logout",
                {},
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async createUser(data: any) {
        try {
            const response = await axios.post(UserService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getUsers(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(UserService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteUserSoft(id: string | number) {
        try {
            const response = await axios.delete(UserService.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteUserHard(id: string | number) {
        try {
            const response = await axios.delete(UserService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editUser(id: string, newUser: any) {
        try {
            const response = await axios.patch(UserService.url, newUser, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getUserTypes() {
        try {
            const response = await axios.get(UserService.url + "/types", {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async isLoggedIn() {
        try {
            const response = await axios.get(UserService.url + "/is-logged", {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClient() {
        try {
            const response = await axios.get(UserService.url + "/client", {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getUserImages(username: string) {
        try {
            const response = await axios.get(UserService.url + "/images", {
                withCredentials: true,
                params: { username },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }
}
