import { TableCell, TableFooter, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { dateFormatter } from "../../constants";
const Footer = ({ span }: { span?: number }) => {
    const { user } = useContext(AppContext);
    return (
        <TableFooter
            style={{
                position: "relative",
                bottom: "0",
                left: "0",
                right: "0",
            }}
        >
            <TableRow>
                <TableCell colSpan={span ? span : 1}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "8px 16px",
                        }}
                    >
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Typography
                                fontWeight="bold"
                                letterSpacing={1}
                                variant="caption"
                                sx={{ minWidth: "10ch" }}
                            >
                                Operator :
                            </Typography>

                            <Typography
                                fontWeight="bold"
                                letterSpacing={1}
                                variant="caption"
                            >
                                {" "}
                                {user?.baseUser?.name}
                            </Typography>
                        </div>

                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Typography
                                fontWeight="bold"
                                letterSpacing={1}
                                variant="caption"
                                sx={{ minWidth: "10ch" }}
                            >
                                Timestamp
                            </Typography>

                            <Typography
                                fontWeight="bold"
                                letterSpacing={1}
                                variant="caption"
                            >
                                {dateFormatter.format(new Date())}
                            </Typography>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            alt="Techable logo"
                            width="16px"
                            height="16px"
                            src={`/images/icon.png`}
                        />

                        <Typography fontSize=".85rem" fontWeight="bold">
                            Powered by ZamaApp&deg;
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};
export default Footer;
