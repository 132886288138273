import { Edit } from "@mui/icons-material";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { TestService } from "../../services/TestResultService";
import { AppContext } from "../AppContext";

const EditSingleTestResult = ({ row }: any) => {
    const [marksData, setMarksData] = useState<any>({
        marks: row.marks ? row.marks : 0,
        test: row.testId ? row.testId : "",
    });
    const { feedback, setFeedback } = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const handleSubmit = async (ev: any) => {
        console.log("it hits");
        ev.preventDefault();
        const [data, err] = await TestService.editTestResult(row.id, marksData);

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });

            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000);
        }
    };
    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <Edit fontSize="small" />
            </IconButton>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Edit Single Test Result</DialogTitle>

                    <Grid item xs={12} p={"10px"}>
                        {feedback.show && (
                            <Alert severity={feedback.severity}>
                                {feedback.message}
                            </Alert>
                        )}
                    </Grid>
                    <DialogContent style={{ paddingTop: "1rem" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    size="small"
                                    name="marks"
                                    label="Marks"
                                    value={marksData.marks}
                                    onChange={(ev) =>
                                        setMarksData({
                                            ...marksData,
                                            marks: ev.target.value,
                                        })
                                    }
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpen(false)}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Edit
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>{" "}
        </>
    );
};

export default EditSingleTestResult;
