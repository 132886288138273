import Add from "@mui/icons-material/Add";
import { Button, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import BaseAccordian from "../BaseAccordian";
import { Search } from "../components/Filters/Search";
import { RUDTable } from "../components/Table/RUDTable";
import { RoleService } from "../services/RoleService";
import { SupderAdminService } from "../services/SuperAdminService";

const SuperAdmins = () => {
    const [filter, setFilter] = useState<any>({});

    const [rows, setRows] = useState<any>({
        role: [],
    });

    const handleChange = (ev: ChangeEvent<any>, external: boolean) => {
        if (!external) {
            setFilter({ ...filter, [ev.target.name]: ev.target.value });
        }
    };

    const getSuperAdminRoles = async () => {
        const [data, err] = await SupderAdminService.getSuperAdmin();

        if (data) {
            setRows((rows: any) => ({ ...rows, role: data.rows }));
        }
    };

    useEffect(() => {
        getSuperAdminRoles();
    }, []);
    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Superadmins
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        startIcon={<Add />}
                        href={`/add-super-admin`}
                    >
                        Create Superadmin
                    </Button>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Search filter={filter} setFilter={setFilter} />
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    SupderAdminService.getSuperAdmin(page, limit, filter)
                }
                editor={SupderAdminService.editSuperAdmin}
                deletor={(id: string | number) =>
                    SupderAdminService.deleteSuperAdminSoft(id)
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.baseUser.id,

                    name: row.baseUser.name,
                    username: row.baseUser.username,
                    role: row.baseUser.role.name,
                })}
                readables={{
                    name: "Name",
                    email: "Email",
                    username: "Username",
                    role: "Role",
                }}
                editables={[
                    {
                        name: "name",
                        type: "textfield",
                        label: "Name",
                    },
                    {
                        name: "email",
                        type: "textfield",
                        label: "Email",
                    },
                    {
                        name: "username",
                        type: "textfield",
                        label: "Username",
                    },
                    {
                        name: "role",
                        type: "select",
                        label: "Role",
                        options: {
                            label: "name",
                            value: "id",
                            valuesFetcher: RoleService.getRoles,
                        },
                    },
                ]}
                ops={{
                    read: "READ SUPERADMIN",
                    edit: "UPDATE SUPERADMIN",
                    delete: "DELETE SUPERADMIN",
                }}
            />
        </div>
    );
};

export default SuperAdmins;
