import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

type Props = {
  variables: any;
  setVariables: Dispatch<SetStateAction<any>>;
  userType: string;
};

const Variables = ({ variables, setVariables, userType }: Props) => {
  const handleCheckbox = (key: any, variable: any) => {
    setVariables({
      ...variables,
      [userType]: {
        ...variables[userType],
        [key]: { ...variable, checked: !variable.checked },
      },
    });
  };

  return (
    <FormGroup>
      <Grid container justifyContent="space-between">
        {Object.entries(variables[userType]).map(([k, v]: any, id: number) => (
          <Grid item key={id} xs={12} lg={6}>
            <FormControlLabel
              label={v.label}
              control={
                <Checkbox
                  value={v}
                  checked={v.checked}
                  onChange={() => handleCheckbox(k, v)}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
};

export default Variables;
