import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { FinanceService } from "../../services/BankService";
import AddView from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { Search } from "../Filters/Search";
import { RUDTable } from "../Table/RUDTable";
import AddBank from "./AddBank";

const Banks = () => {
    const { user } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
    });
    const [changes, setChanges] = useState(0);

    const ChangeBankSequence = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await FinanceService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Banks
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE BANK"]}>
                        <AddView
                            label="add bank"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddBank />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1}>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={6} lg={6}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                            />
                        </Grid>
                    )}

                    {/* */}

                    <Grid item xs={12} md={6} lg={6}>
                        <Search filter={filter} setFilter={setFilter} />
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    FinanceService.getBanks(page, limit, {
                        search: filter.search ? filter.search : null,
                        campus: filter.campus ? filter.campus : null,
                    })
                }
                editor={FinanceService.editBank}
                deletor={
                    user?.baseUser?.ops.includes("DELETE BANK")
                        ? (id: string | number) =>
                              FinanceService.deleteBankSoft(id)
                        : undefined
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    branchName: row.branchName,
                    branchCode: row.branchCode,
                    iban: row.iban,
                    campusName: row?.campus.name,
                    campusId: row?.campus.id,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    branchName: "Branch name",
                    type: "Bank Type",
                    branchCode: "Branch code",
                    iban: "IBAN",
                    campusName: "Campus",
                    Edit: "Edit",
                    Changesequence: "Change Sequence",
                }}
                customCols={[
                    {
                        header: "Changesequence",
                        content: (row) => (
                            <ChangeBankSequence
                                row={row}
                                setChangesCount={setChanges}
                            />
                        ),
                    },
                    {
                        header: user?.baseUser?.ops.includes("UPDATE BANK")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE BANK"]}>
                                <AddView
                                    label="Edit bank"
                                    updateMode
                                    triggerRowsRefetch={() =>
                                        setChanges(changes + 1)
                                    }
                                >
                                    <AddBank row={row} updateMode />
                                </AddView>
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter, changes]}
                ops={{
                    read: "READ BANK",
                    edit: "",
                    delete: "DELETE BANK",
                }}
                storageKey="banks"
            />
        </>
    );
};

export default Banks;
