import {
    Grid,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CampusService } from "../../../../services/CampusService";
import { useGetAPI } from "../../../../services/Hooks";
import { SessionService } from "../../../../services/SessionService";
import { StudentService } from "../../../../services/StudentService";
import { Header } from "../../../Settings/Header/Header";

export const CountReport = () => {
    const [campuses, setCampuses] = useState<any>([]);
    const [countReport, setCountReport] = useState<any>(null);
    const [sessions, setSessions] = useState<any>([]);
    const printRef = useRef<any | null>(null);
    const [printMode, setPrintMode] = useState(false);

    const [selected, setSelected] = useState({
        campus: "",
        session: "",
    });
    const [pagination, setPagination] = useState({
        page: 1,
        count: 0,
        limit: 10,
    });
    const [filter, setFilter] = useState<any>({
        campus: "",
        session: "",
    });

    const getReport = async () => {
        const [data, err] = await StudentService.getCountReport(
            selected.campus,
            selected.session
        );
        if (data) {
            setCountReport(data.report);
        }
    };

    const getCampuses = async () => {
        const [data, err] = await CampusService.getCampus(1, 100);
        if (data) {
            setCampuses(data.rows);
        }
    };

    const [session, _, sessionStatus, sessionMessage] = useGetAPI(
        SessionService.url,
        pagination.page,
        pagination.limit,
        { campus: filter.campus },
        [filter],
        [filter.campus]
    );

    const handleChange = (ev: ChangeEvent<any>) => {
        setFilter({ ...filter, [ev.target.name]: ev.target.value });
        setSelected({ ...selected, [ev.target.name]: ev.target.value });
    };

    useEffect(() => {
        getCampuses();
    }, [selected.campus]);

    useEffect(() => {
        if (selected.campus) {
            getReport();
        }
    }, [selected.session]);

    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
                <TextField
                    label="Campus"
                    name="campus"
                    onChange={handleChange}
                    value={filter.campus}
                    select
                    fullWidth
                >
                    {campuses.map((c: any, idx: number) => (
                        <MenuItem value={c.id} key={idx}>
                            {c.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* <PrintTable componentRef={printRef} setPrintMode={setPrintMode} /> */}
            <Grid item xs={12} md={6} ref={printRef}>
                <TextField
                    label="Session"
                    name="session"
                    onChange={handleChange}
                    value={filter.session}
                    select
                    fullWidth
                >
                    {session.map((s: any, idx: number) => (
                        <MenuItem value={s.id} key={idx}>
                            {s.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {selected.session && (
                <Grid item xs={12}>
                    <Header _campus={selected.campus} />
                </Grid>
            )}

            {countReport && (
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Classroom</TableCell>
                                <TableCell>Section</TableCell>
                                <TableCell>Male</TableCell>
                                <TableCell>Female</TableCell>
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.entries(countReport.report).map(
                                (
                                    [classroomId, sections]: any[],
                                    idx: number
                                ) => (
                                    <>
                                        <TableRow key={idx}>
                                            <TableCell
                                                rowSpan={
                                                    Object.keys(sections)
                                                        .length + 2 || 1
                                                }
                                            >
                                                {
                                                    countReport.classrooms.find(
                                                        (cls: any) =>
                                                            cls.id ==
                                                            classroomId
                                                    ).name
                                                }
                                            </TableCell>
                                        </TableRow>

                                        {Object.entries(sections).map(
                                            ([
                                                sectionId,
                                                { male, female },
                                            ]: any[]) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell>
                                                            {
                                                                countReport.sections.find(
                                                                    (
                                                                        sec: any
                                                                    ) =>
                                                                        sec.id ==
                                                                        sectionId
                                                                ).name
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {male}
                                                        </TableCell>
                                                        <TableCell>
                                                            {female}
                                                        </TableCell>
                                                        <TableCell>
                                                            {male + female}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        )}
                                        <TableRow
                                            sx={{
                                                "&>*": {
                                                    bgcolor: "background.paper",
                                                },
                                            }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>
                                                {Object.values(
                                                    countReport.report[
                                                        classroomId
                                                    ]
                                                )
                                                    .map(
                                                        (secValue: any) =>
                                                            secValue.male +
                                                            secValue.female
                                                    )
                                                    .reduce(
                                                        (prev, current) =>
                                                            prev + current,
                                                        0
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            )}
                        </TableBody>
                    </Table>
                </Grid>
            )}
        </Grid>
    );
};
