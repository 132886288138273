import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class StockService {
  static url = `${baseAPI}/library/book-instance`;

  static async createStock(data: any) {
    try {
      const response = await axios.post(StockService.url, data, {
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async updateStockQuantity(data: any) {
    try {
      const response = await axios.post(
        StockService.url + "/by-quantity",
        data,
        {
          withCredentials: true,
        }
      );

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getStock(page = 1, limit = 20, filter = {}) {
    try {
      const response = await axios.get(StockService.url, {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteStockSoft(id: string | number) {
    try {
      const response = await axios.delete(StockService.url + "/soft", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteStockHard(id: string | number) {
    try {
      const response = await axios.delete(StockService.url + "/hard", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async editStock(id: string, data: any) {
    try {
      const response = await axios.patch(StockService.url, data, {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      console.log(err.response);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async returnBook(id: string, data: any) {
    try {
      const response = await axios.patch(
        StockService.url + "/return-book",
        data,
        {
          withCredentials: true,
          params: { id },
        }
      );
      return [response.data, null];
    } catch (err: any) {
      console.log(err.response);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getBookCondition(page = 1, limit = 20, filter = {}) {
    try {
      const response = await axios.get(StockService.url + "/condition-types", {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getBookStatusTypes(page = 1, limit = 20, filter = {}) {
    try {
      const response = await axios.get(StockService.url + "/status-types", {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }
}
