import { ExpandMoreOutlined } from "@mui/icons-material";
import { Accordion, AccordionSummary, Grid, Typography } from "@mui/material";

export const SearchNFilter = ({ children }: any) => {
    return (
        <>
            <Accordion sx={{ p: 1, marginTop: 2 }} elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography fontFamily="monospace" color="primary">
                        Filters
                    </Typography>
                </AccordionSummary>

                <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    paddingTop={2}
                    // paddingBottom={2}
                >
                    {children.slice(0, -1).map((child: any, idx: number) => (
                        <Grid key={idx} item xs={12} md={3}>
                            {child}
                        </Grid>
                    ))}
                    <Grid item xs={12} md={5}>
                        {children[children.length - 1]}
                    </Grid>
                </Grid>
            </Accordion>
        </>
    );
};
