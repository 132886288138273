import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SubjectService {
    static url = `${baseAPI}/org/Academics/Subject`;

    static async createSubject(data: any) {
        try {
            const response = await axios.post(
                SubjectService.url,
                { ...data, publisher: data.publisher || null },
                {
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSubject(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(SubjectService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSubjectSoft(id: string | number) {
        try {
            const response = await axios.delete(SubjectService.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSubjectHard(id: string | number) {
        try {
            const response = await axios.delete(SubjectService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editSubject(id: string, newSubject: any) {
        try {
            const response = await axios.patch(SubjectService.url, newSubject, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                SubjectService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
