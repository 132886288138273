import { Add, ClearAll, Person } from "@mui/icons-material";
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { BookService } from "../../../services/BookService";
import { UserService } from "../../../services/UserService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import EditBook from "./EditBook";
import { ShowBookImage } from "./ShowBookImage";
import { ShowBookPdf } from "./ShowBookPdf";

const Book = () => {
    const [filter, setFilter] = useState<any>({
        publisher: "",
        binding: "",
        type: "",
        source: "",
        search: "",
        apply: 0,
    });
    const { user } = useContext(AppContext);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            publisher: "",
            binding: "",
            type: "",
            source: "",
            search: "",
            apply: 0,
        });
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Books
                    </Typography>
                </Grid>
                <MultiPermissionAuthorize ops={["CREATE BOOK"]}>
                    <Grid>
                        <Link
                            to="/library/books/add"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                startIcon={<Add />}
                                variant="outlined"
                                color="primary"
                                style={{ height: "100%" }}
                            >
                                Add Book
                            </Button>
                        </Link>
                    </Grid>
                </MultiPermissionAuthorize>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/academics/publisher"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    publisher: c?.id || "",
                                })
                            }
                            label="Publisher"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    binding: c?.id || "",
                                })
                            }
                            label="BindingType"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            defaultOptions={[
                                {
                                    id: "softBinding",
                                    name: "Soft Binding",
                                },
                                {
                                    id: "hardBinding",
                                    name: "Hard Binding",
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {/* <TextField
                            label="Source"
                            size="small"
                            fullWidth
                            select
                            onChange={(e) =>
                                setFilter({ ...filter, source: e.target.value })
                            }
                        >
                            <MenuItem value="donated">Donated</MenuItem>
                            <MenuItem value="purchased">Purchased</MenuItem>
                        </TextField> */}

                        <Autocomplete
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    source: c?.id || "",
                                })
                            }
                            label="Source"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            defaultOptions={[
                                {
                                    id: "donated",
                                    name: "Donated",
                                },
                                {
                                    id: "purchased",
                                    name: "Purchased",
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        {/* <TextField
                            label="Book Type"
                            size="small"
                            fullWidth
                            select
                            onChange={(e) =>
                                setFilter({ ...filter, type: e.target.value })
                            }
                        >
                            <MenuItem value="refrence">Reference</MenuItem>
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </TextField> */}

                        <Autocomplete
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    type: c?.id || "",
                                })
                            }
                            label="Type"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            defaultOptions={[
                                {
                                    id: "refrence",
                                    name: "Reference",
                                },
                                {
                                    id: "text",
                                    name: "Text",
                                },
                                {
                                    id: "other",
                                    name: "Other",
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={3}>
                        <Search
                            filter={filter}
                            setFilter={setFilter}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Apply Filter
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    BookService.getBook(page, limit, {
                        publisher: filter.publisher
                            ? filter.publisher
                            : undefined,
                        source: filter.source ? filter.source : undefined,

                        binding: filter.binding ? filter.binding : undefined,
                        type: filter.type ? filter.type : undefined,
                        search: filter.search ? filter.search : undefined,
                    })
                }
                editor={UserService.editUser}
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                    publisher: row.publisher?.name,
                    publisherDetail: row.publisher,
                    authors: row.authors.map((item: any) => item.author.name),
                    authorsDetail: row.authors.map((item: any) => item.author),
                    _genres: row.genres.map((data: any) => data.genre),
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    description: "Description",
                    pages: "Pages",
                    price: "Price",
                    year: "Year",
                    binding: "Binding Type",
                    cellNo: "Cell No.",
                    publisher: "Publisher",
                    type: "Book Type",
                    Authors: "Authors",
                    Genres: "Genres",
                    BookCover: "Book Cover",
                    ReadBook: "Read Book",
                    Edit: "Edit",
                }}
                customCols={[
                    {
                        header: "Authors",
                        content: (row: any) => (
                            <Grid container spacing={1}>
                                {row.authors.map((author: any, id: number) => (
                                    <Grid key={id} item>
                                        <Chip
                                            icon={<Person fontSize="small" />}
                                            label={author}
                                            size="small"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ),
                    },

                    {
                        header: "Genres",
                        content: (row: any) => (
                            <Grid container spacing={1}>
                                {row.genres.map((genre: any, id: number) => (
                                    <Grid key={id} item>
                                        <Chip
                                            label={genre.genre.name}
                                            size="small"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ),
                    },

                    {
                        header: "BookCover",
                        content: (row: any) => <ShowBookImage row={row} />,
                    },

                    {
                        header: "ReadBook",
                        content: (row: any) => <ShowBookPdf row={row} />,
                    },

                    {
                        header: user?.baseUser?.ops.includes("UPDATE BOOK")
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize ops={["UPDATE BOOK"]}>
                                <EditBook row={row} />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter.apply, filter.search]}
                ops={{
                    read: "READ BOOK",
                    edit: "",
                    delete: "DELETE BOOK",
                }}
                storageKey="book"
            />
        </div>
    );
};

export default Book;
