import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { PATCH } from "../../../services/BaseService";

export const Surcharge = ({ setting }: any) => {
  const [lateFeeFine, setLateFeeFine] = useState(setting.value);
  const [feedback, setFeedback] = useState({
    loading: false,
    show: false,
    message: "",
    severity: "warning",
  });

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setLateFeeFine(ev.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFeedback({ ...feedback, loading: true });

    const [data, err] = await PATCH(
      "/settings",
      { value: lateFeeFine },
      { id: setting.id }
    );

    if (data) {
      setFeedback({
        loading: false,
        show: true,
        message: data.message,
        severity: "success",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {feedback.show && (
        <Alert severity={feedback.severity as any} sx={{ mb: 1.5 }}>
          {feedback.message}
        </Alert>
      )}

      <Card>
        <CardContent
          component={Grid}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography>{setting.label}</Typography>
          </Grid>

          <Grid item>
            <TextField
              size="small"
              value={lateFeeFine}
              onChange={handleChange}
              type="number"
              inputProps={{ min: 0 }}
              variant="outlined"
            />
          </Grid>
        </CardContent>

        <Divider />
      </Card>

      <Button
        variant="outlined"
        type="submit"
        sx={{ mt: 1.5 }}
        disabled={feedback.loading}
        endIcon={feedback.loading ? <CircularProgress size="1rem" /> : null}
      >
        {feedback.loading ? "updating setting..." : "update setting"}
      </Button>
    </form>
  );
};
