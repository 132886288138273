import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SupderAdminService {
    static url = `${baseAPI}/superadmin`;

    static async createSuperAdmin(data: any) {
        try {
            const response = await axios.post(SupderAdminService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSuperAdmin(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(SupderAdminService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSuperAdminSoft(id: string | number) {
        try {
            const response = await axios.delete(
                SupderAdminService.url + "/soft",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSuperAdminHard(id: string | number) {
        try {
            const response = await axios.delete(
                SupderAdminService.url + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editSuperAdmin(id: string, newSuperAdmin: any) {
        try {
            const response = await axios.patch(
                SupderAdminService.url,
                newSuperAdmin,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
