import { Hidden } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useLocation } from "react-router-dom";
import MainView from "./MainView";
import { Navbar } from "./Navbar";
import Sidebar from "./Sidebar";

export const Layout = ({ sidebarOpen, setSidebarOpen, ...props }) => {
    const location = useLocation();

    React.useEffect(() => {
        if (
            ["/login", "/_superadminlogin"].includes(
                location.pathname.toLocaleLowerCase()
            )
        ) {
            setSidebarOpen(false);
        } else {
            setSidebarOpen(true);
        }
    }, [location.pathname]);

    return (
        <Box
            sx={{ display: "flex", height: "100%" }}
            {...props}
            component={"div"}
        >
            <CssBaseline />

            {location.pathname != "/login" &&
                location.pathname != "/_superAdminLogin" && (
                    <Navbar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                )}

            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />

            <MainView open={sidebarOpen} />
        </Box>
    );
};
