import { DialogContent, makeStyles, MenuItem, Theme } from "@material-ui/core";
import { FileUpload } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
  Input,
  IconButton,
  Box,
} from "@mui/material";
import { handleBreakpoints } from "@mui/system";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { AddStudent } from "../../Admission/Student/AddStudent";

type FieldType = any;

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}));

const initialFeedback = {
  show: false,
  message: "",
  severity: "success",
};

export default function AddCustomField({
  open,
  setOpen,
  item,
  setItem,
  updateMode,
}: any) {
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [field, setField] = useState<FieldType>({
    key: "",
    value: "",
    type: "text",
    picture: "",
  });
  const [feedback, setFeedback] = useState(initialFeedback);

  const handleClose = () => {
    setOpen(false);
    setField({ key: "", value: "", type: "text", picture: "" });
    setFeedback(initialFeedback);
  };

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setField({ ...field, [ev.target.name]: ev.target.value });
  };

  const handleAddField = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const existingCustomFieldKeys = item.customFields.map((f: any) => f.key);

    if (existingCustomFieldKeys.includes(field.key)) {
      setFeedback({
        ...feedback,
        severity: "error",
        message: "A field with this key already exists",
        show: true,
      });
      return;
    }

    setItem((item: any) => ({
      ...item,
      customFields: [...item.customFields, field],
    }));
    setFeedback({
      ...feedback,
      show: true,
      message: "Field added successfully",
      severity: "success",
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setField({
        ...field,
        picture: event.target.files[0],
        value: event.target.files[0].type,
      });
    }
  };

  useEffect(() => {
    if (field.type === "text") {
      setField({ ...field, value: "", picture: "" });
    }
  }, [field.type]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <form onSubmit={handleAddField} ref={formRef}>
        <DialogTitle>Add Custom Field</DialogTitle>

        <DialogContent className={classes.content}>
          {feedback.show && (
            <Alert severity={feedback.severity as any}>
              {feedback.message}
            </Alert>
          )}
          <TextField
            size="small"
            select
            variant="outlined"
            fullWidth
            onChange={handleChange}
            value={field.type}
            label="Field type"
            name="type"
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="image">Image</MenuItem>
          </TextField>
          <TextField
            size="small"
            required
            variant="outlined"
            name="key"
            label="Field name"
            value={field.key}
            onChange={handleChange}
          />
          {field.type === "text" ? (
            <TextField
              size="small"
              variant="outlined"
              name="value"
              label="Field value"
              value={field.value}
              onChange={handleChange}
              multiline
              minRows={3}
            />
          ) : (
            <Box
              component="div"
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <span>
                <IconButton component="label" sx={{ alignSelf: "flex-start" }}>
                  <FileUpload />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </IconButton>
                <Typography variant="caption">
                  {field.picture?.name || "Select file"}
                </Typography>
              </span>

              {field.picture && (
                <img
                  src={URL.createObjectURL(field.picture)}
                  style={{
                    width: "50%",
                    aspectRatio: "4/3",
                    borderRadius: 2,
                  }}
                />
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="outlined" type="submit">
            Add Field
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
