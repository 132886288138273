import { Grid } from "@material-ui/core";
import { Add, Remove } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    IconButton,
    LinearProgress,
    TextField,
} from "@mui/material";
import { FormEvent, Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { StockService } from "../../../services/StockService";
import { AppContext } from "../../AppContext";
import { BaseBreadcrum } from "../../BaseBreadcrum";
import { baseAPI } from "../../constants";
import SearchableInput from "../../SearchableInput";

type Stock = {
    [id: number]: {
        quantity: number | string;
        book: number | string;
    };
};

const AddStock = () => {
    const { user } = useContext(AppContext);
    const history = useHistory();
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
    });
    const [feedback, setFeedback] = useState<any>({
        message: "",
        hidden: true,
        loading: false,
        severity: "",
    });

    const [stock, setStock] = useState<Stock>({
        0: {
            quantity: 1,
            book: "",
        },
    });

    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId =
                parseInt(Object.keys(stock)[Object.entries(stock).length - 1]) +
                1;

            setStock({ ...stock, [newEntryId]: stock[newEntryId - 1] });

            setFilter({ ...filter, [newEntryId]: "" });
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(stock).filter(
                    ([id, stock]) => parseInt(id) !== rowId
                )
            );

            setStock(updatedStock);

            const updatedFilter = Object.fromEntries(
                Object.entries(filter).filter(
                    ([id, book]) => parseInt(id) !== rowId
                )
            );

            setFilter(updatedFilter);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const rows = Object.entries(stock).map(([rowId, value]: any) => ({
            book: filter[rowId],
            quantity: parseInt(value.quantity),
        }));

        setFeedback({ ...feedback, loading: true });

        const [data, err] = await StockService.createStock({
            campus: filter.campus,
            stock: rows,
        });

        if (data) {
            setFeedback({
                message: data?.message,
                severity: "success",
                hidden: false,
                loading: false,
            });

            const timer = setTimeout(() => {
                history.replace("/library/stock");
            }, 3000);
        } else {
            setFeedback({
                message: data?.err,
                severity: "error",
                hidden: false,
                loading: false,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <BaseBreadcrum
                links={[
                    {
                        linkName: "Stock",
                        linkUrl: "/library/stock",
                    },

                    {
                        linkName: "Add Stock",
                        linkUrl: "/library/stock/add",
                    },
                ]}
            />

            <Grid container spacing={1} alignItems="center">
                {user.baseUser.userRegions.length > 1 && (
                    <Grid xs={10} item>
                        <SearchableInput
                            api={`${baseAPI}/org/campus`}
                            label="Campus"
                            filter={filter}
                            setFilter={setFilter}
                            _name="campus"
                            required
                        />{" "}
                    </Grid>
                )}

                {Object.entries(stock).map(([id, _stock]: any) => (
                    <Fragment key={id}>
                        <Grid item xs={12} md={5}>
                            <TextField
                                label="Quantity"
                                value={_stock?.quantity}
                                type="number"
                                inputProps={{ min: 1 }}
                                fullWidth
                                size="small"
                                onChange={(e) =>
                                    setStock({
                                        ...stock,
                                        [id]: {
                                            ...stock[id],
                                            quantity: e.target.value,
                                        },
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <SearchableInput
                                api={`${baseAPI}/library/book`}
                                label="Book"
                                filter={filter}
                                setFilter={setFilter}
                                _name={id}
                                required
                            />
                        </Grid>

                        <Grid item xs={1}>
                            {parseInt(id) === 0 ? (
                                <IconButton onClick={() => handleRow("add")}>
                                    <Add />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() =>
                                        handleRow("remove", parseInt(id))
                                    }
                                >
                                    <Remove />
                                </IconButton>
                            )}
                        </Grid>
                    </Fragment>
                ))}

                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        size="large"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {feedback.loading ? "adding stock..." : "add stock"}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {feedback.loading && <LinearProgress />}
                    {!feedback.hidden && (
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default AddStock;
