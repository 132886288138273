import { ChevronLeft } from "@mui/icons-material";
import { Divider, Drawer, IconButton, List } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import BaseCollapse from "./BaseCollapse";

const drawerWidth = 240;
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
    const theme = useTheme();

    const handleClose = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <Drawer
            sx={{
                position: "relative",
                width: sidebarOpen ? drawerWidth : "0",
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },

                transition: "0.35s all ease",
            }}
            variant="persistent"
            anchor="left"
            open={sidebarOpen}
            PaperProps={{
                sx: {
                    bgcolor: "background.default",
                    "&::-webkit-scrollbar": {
                        width: "6px",
                        borderRadius: "1000px",
                    },

                    "&::-webkit-scrollbar-thumb": {
                        background: theme.palette.divider,
                        borderRadius: "2.5px",
                    },
                },
            }}
        >
            <DrawerHeader
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <IconButton href="/" size="small">
                    {/* <Home /> */}
                    <img src="/images/icon.png" height={40} />
                </IconButton>

                {/* <ListItemText primaryTypographyProps={{ variant: "h6" }}>
                    Zama school
                </ListItemText> */}

                <IconButton onClick={handleClose}>
                    <ChevronLeft />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List disablePadding>
                <BaseCollapse />
            </List>
        </Drawer>
    );
}
//  sx={{position:"relative", height:"100vh"}}
