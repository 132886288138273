import { Chip, ChipProps, TableCell, TableRow } from "@mui/material";

type Props = {
    log: any;
};

export enum ActionResponse {
    Success = "success",
    Failure = "failure",
    Unknown = "unknown",
}

const timeFormatter = Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
});

const parseActionText = (text: string, payload: any) => {
    const regex = /#[a-zA-Z]+/g;
    const matches = Array.from(text.matchAll(regex)).map((exp) => exp[0]);
    console.log(matches, "matches");
    payload = JSON.parse(payload);
    console.log(payload, "payload");

    for (const match of matches) {
        text = text.replaceAll(match, payload[match.slice(1)]);
    }

    return text;
};

const getAgentString = (agent: string) => {
    let regex = /[^)]*/;
    const match = agent.match(regex);

    if (match) {
        if (match[0].includes("(") && match[0].includes(";")) {
            return match[0]?.split("(")[1].split(";")[0] ?? agent;
        }
    }
    return agent;
};

const chipDefaults: ChipProps = {
    size: "small",
};

const ResponseIndicator = ({ response }: { response: ActionResponse }) => {
    if (response === ActionResponse.Success) {
        return <Chip color="success" label="Success" {...chipDefaults} />;
    } else if (response === ActionResponse.Failure) {
        return <Chip color="error" label="Failed" {...chipDefaults} />;
    } else {
        return <Chip label="Unknown" {...chipDefaults} />;
    }
};

export const SingleLog = ({ log }: Props) => {
    return (
        <TableRow>
            <TableCell size="small">
                {timeFormatter.format(new Date(log?.createdAt))}
            </TableCell>

            <TableCell>{log?.operator?.id}</TableCell>
            <TableCell>{log?.name}</TableCell>

            <TableCell>
                {parseActionText(log?.actionText, log?.payload)}
            </TableCell>

            <TableCell>
                <ResponseIndicator response={log?.response} />
            </TableCell>
            <TableCell>{getAgentString(log?.agent)}</TableCell>

            <TableCell>{log?.ip}</TableCell>
        </TableRow>
    );
};
