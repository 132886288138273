import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    Pagination,
    TextField,
} from "@mui/material";
// import { useQuery } from "@tanstack/react-query";
import {
    Dispatch,
    Fragment,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { GET } from "../services/BaseService";
import { useGetAPI } from "../services/Hooks";
// import { GET } from "../../../Utilities/BaseService";

type Props = {
    getFullRecord?: boolean;
    params?: {};
    optionsPreprocessor?: (opt: any) => any;
    invalidate?: boolean;
    api: string;
    label: string;
    dep?: {};
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean | undefined;
    size?: "small" | "medium";
    defaultValue?: {} | {}[];
    InputProps?: any;
    _name?: string | number;
    searchKey?: string;
    InputLabelProps?: any;
};
const getData = (
    api: string,
    search: string,
    dep: any,
    otherOps: any,
    multiple: boolean | undefined,
    page: any,
    limit: any
) => {
    const params = multiple
        ? { page: page, limit: limit, search: search, otherOps: otherOps }
        : { page: page, limit: limit, search: search, ...dep };

    return GET(api, { ...params });
};

const SearchableInput = ({
    params,
    getFullRecord,
    optionsPreprocessor,
    _name,
    invalidate,
    api,
    label,
    filter,
    setFilter,
    size,
    dep,
    multiple,
    defaultValue,
    disabled,
    required = false,
    InputProps = {},
    searchKey,
}: Props) => {
    const [search, setSearch] = useState<string>("");
    const [value, setValue] = useState<any>(
        defaultValue ? defaultValue : multiple ? [] : null
    );
    const [options, setOptions] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    });

    /* const params = useMemo(() => {
    return multiple
      ? {
          search: search,
        }
      : dep
      ? {
          search: search,
          ...dep,
        }
      : { search: search };
  }, [search]); */

    const [data, count, status, message] = useGetAPI(
        api,
        pagination.page,
        pagination.limit,
        params
            ? {
                  [searchKey ? searchKey : "search"]: search
                      ? search
                      : undefined,
                  ...params,
              }
            : {
                  [searchKey ? searchKey : "search"]: search
                      ? search
                      : undefined,
              },
        [dep, search, pagination.page, pagination.limit, invalidate]
    );

    const ref = useRef(0);

    const handleSelectChange = (_val: any) => {
        setValue(_val);
        multiple
            ? setFilter({
                  ...filter,
                  [_name ? _name : label.toLocaleLowerCase()]: !getFullRecord
                      ? _val.map((v: any) => v.id)
                      : _val,
              })
            : setFilter({
                  ...filter,
                  [_name ? _name : label.toLocaleLowerCase()]: _val
                      ? !getFullRecord
                          ? _val.id
                          : _val
                      : "all",
              });
    };

    const handleInputChange = (ev: any, value: any, reason: any) => {
        if (reason === "reset") return;

        ref.current++;
        const newRef = ref.current;

        setTimeout(() => {
            if (newRef === ref.current) {
                setSearch(value);
                ref.current = 0;
            }
        }, 1000);
    };

    const handleSelectAll = () => {
        if (value.length !== options.length) {
            const modOpt = options.filter(
                (opt: any, idx: number) => !value.includes(opt)
            );

            const _val = [...value, ...modOpt];
            setValue(_val);

            setFilter({
                ...filter,
                [label.toLocaleLowerCase()]: _val.map((v: any) =>
                    !getFullRecord ? v.id : v
                ),
            });
        } else {
            setValue([]);
        }
    };

    useEffect(() => {
        if (data) {
            setOptions(() => data);
        }
    }, [data]);

    useEffect(() => {
        setOptions(() => data.map((opt: any) => optionsPreprocessor!(opt)));
    }, [optionsPreprocessor]);

    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            multiple={multiple}
            disableCloseOnSelect={multiple}
            renderOption={(props, options, { selected }) => (
                <Fragment key={options.id}>
                    <li {...props} style={{ paddingTop: 1, paddingBottom: 1 }}>
                        {options.name}
                    </li>
                    <Divider />
                </Fragment>
            )}
            size={size ? size : "small"}
            options={
                optionsPreprocessor
                    ? data.map((opt: any) => optionsPreprocessor!(opt))
                    : data
            }
            getOptionLabel={(option) =>
                multiple ? option.id + "" : option.name + ""
            }
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(option) =>
                option.map((value: any, idx) => (
                    <Chip
                        sx={{ m: 0.25 }}
                        key={idx}
                        size="small"
                        label={value.name}
                    />
                ))
            }
            onChange={(ev, val, reason) => handleSelectChange(val)}
            renderInput={(props) => (
                <TextField
                    {...props}
                    label={label}
                    required={required}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        ...props.InputProps,
                        ...InputProps,

                        startAdornment: (
                            <>
                                {InputProps?.startAdornment}
                                {props.InputProps?.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            <>
                                {status === "loading" ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}

                                {props.InputProps?.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onInputChange={(event, value, reason) =>
                handleInputChange(event, value, reason)
            }
            groupBy={(option) => ""}
            renderGroup={(params) => (
                <List key={params.key}>
                    {multiple && (
                        <ListItem
                            sx={{ paddingTop: 0, paddingBottom: 0, mb: 1 }}
                        >
                            <Button size="small" onClick={handleSelectAll}>
                                select all
                            </Button>
                        </ListItem>
                    )}
                    {params.group}
                    {params.children}
                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            size="small"
                            count={Math.ceil(count / pagination.limit)}
                            page={pagination.page}
                            onChange={(ev, page) =>
                                setPagination({ ...pagination, page })
                            }
                        />
                    </ListItem>
                </List>
            )}
            loading={status === "loading"}
            filterOptions={(options) => options}
        />
    );
};

export default SearchableInput;
