import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CertificateService } from "../../services/CertificateService";
import { AppContext } from "../AppContext";
import { Footer } from "./Certificates";
import { certificatesData } from "./CertificatesData";
import Menu from "./Controls/Menu";
import Academic from "./Variants/Academic";
import Achievement from "./Variants/Achievement";
type Props = { mode: "create" | "update" };

export type CertificateComponents = {
    header: {
        logo: boolean;
        profilePicture: boolean;
        position: CSSProperties["justifyContent"];
        baseUser: any;
    };

    title: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    subTitle: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    userName: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    body: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    additionalBody?: {
        text: string;
        placeholders: string[];
        color: string;
        fontSize: string | number;
    };

    footer?: { fields: Footer[]; position: CSSProperties["justifyContent"] };
};

export type Certificate = {
    id?: number;
    type: string;
    name: string;
    fields: CertificateComponents;
    thumbnail?: string;
};

const EditCertificate = ({ mode }: Props) => {
    const params = useParams<{ id: string }>();
    const [logoPosition, setLogoPosition] = useState({
        position: "center",
        swapped: false,
    });
    const [certificate, setCertificate] = useState<Certificate>(
        certificatesData as any
    );
    const history = useHistory<any>();
    const [fields, setFields] = useState({
        ...history?.location?.state?.fields,
        type: history?.location?.state?.type,
    });
    const { setSidebarOpen } = useContext(AppContext);

    const renderCertificate = () => {
        switch (history?.location?.state?.type) {
            case "academic":
                return <Academic fields={fields} logoPosition={logoPosition} />;

            case "achievement":
                return <Achievement fields={fields} />;

            default:
                break;
        }
    };

    useEffect(() => {
        setSidebarOpen(false);
    }, []);

    async function getCertificateById(certificateId: string) {
        const [data, err] = await CertificateService.getCertificates(1, 1, {
            id: certificateId,
        });

        if (err) {
            console.log(err);
        } else {
            console.log(data);

            const _certificate = data.rows[0];

            if (_certificate) {
                // const footer: Footer[] =
                //     JSON.parse(_certificate.content)?.footer?.fields ?? [];

                // if (footer.filter((item) => item.type === "image").length > 0) {
                //     getFooterImagesFromS3(
                //         footer.filter((item) => item.type === "image"),
                //         _certificate.id
                //     );
                // }

                setCertificate({
                    fields: JSON.parse(_certificate.content),
                    thumbnail: "",
                    type: _certificate.type,
                    id: _certificate.id,
                    name: _certificate.name,
                });
            }
        }
    }

    useEffect(() => {
        if (mode === "update" && params.id) {
            getCertificateById(params.id);
        }
    }, [mode]);

    return (
        <Grid container>
            <Grid
                item
                xs={8}
                overflow="hidden"
                position="relative"
                borderRadius={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <div
                    style={{
                        height: "calc(100vh - 82px)",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {renderCertificate()}
                </div>
            </Grid>

            {mode == "create" ? (
                <Menu fields={fields} setFields={setFields} />
            ) : (
                <Menu fields={certificate} setFields={setCertificate} />
            )}
        </Grid>
    );
};

export default EditCertificate;

{
    /*  <Typography>Logo</Typography>
        <Grid container justifyContent="space-between">
          <Grid item>
            <IconButton size="small" onClick={() => handleLogoPosition("left")}>
              <AlignHorizontalLeft fontSize="small" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              onClick={() => handleLogoPosition("center")}
            >
              <AlignHorizontalCenter fontSize="small" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              onClick={() => handleLogoPosition("right")}
            >
              <AlignHorizontalRight fontSize="small" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              size="small"
              onClick={() => handleLogoPosition("swap")}
              color={logoPosition.swapped ? "primary" : undefined}
            >
              <SwapHoriz fontSize="small" />
            </IconButton>
          </Grid>
        </Grid> */
}

{
    /* <Divider sx={{ mt: 2, mb: 3 }} /> */
}
