import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FeeCategoryService } from "../../services/FeeCategoryService";
import { AddViewContext } from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";

type Props = {
    updateMode: Boolean;
    _feeCategory: any;
    _open: any;
    _setOpen: any;
    triggerRowsRefetch?: () => void;
};

export const AddFeeCategory = ({
    updateMode,
    _feeCategory,
    _open,
    _setOpen,
    changes,
    setChanges,
}: any) => {
    const { feedback, setFeedback } = useContext(AppContext);
    const [feeCategory, setFeeCategory] = useState<any>({
        name: "",
        optional: false,
        editable: false,
        status: "",
    });

    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const getFeeCategoryById = async () => {
        const [data, err] = await FeeCategoryService.getFeeCategory(1, 0, {
            id: _feeCategory.id,
        });
        if (data) {
            if (updateMode) {
                console.log(data);
                setFeeCategory(() => ({
                    name: data.rows[0].name,
                    editable: data.rows[0].editable,
                    optional: data.rows[0].optional,
                    status: data.rows[0].status,
                }));
            }
        }
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setFeeCategory({
            ...feeCategory,
            [ev.target.name]: value,
        });
    };

    const handleCheckboxChange = (ev: any, checked: boolean) => {
        setFeeCategory({ ...feeCategory, [ev.target.name]: checked });
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (updateMode) {
            const [data, err] = await FeeCategoryService.editFeeCategory(
                _feeCategory.id,
                feeCategory
            );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });

                const timer = setTimeout(() => {
                    _setOpen(false);
                    triggerRowsRefetch();
                }, 3000);
                setChanges(changes + 1);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });

                setChanges(changes + 1);
            }
        } else {
            const [data, err] = await FeeCategoryService.createFeeCategory(
                feeCategory
            );
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 3000);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        getFeeCategoryById();
    }, [updateMode]);

    useEffect(() => {
        return () =>
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <FormGroup>
                <Grid container spacing={1.5}>
                    <Grid item xs={12}>
                        {feedback.show && (
                            <Alert severity={feedback.severity}>
                                {feedback.message}
                            </Alert>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Name"
                            name="name"
                            value={feeCategory?.name}
                            fullWidth
                            onChange={handleChange}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            key={feeCategory.name}
                            setOutput={(c) =>
                                setFeeCategory({
                                    ...feeCategory,
                                    status: c?.id || "",
                                })
                            }
                            label="Status"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            defaultOptions={[
                                { id: "active", name: "Active" },
                                { id: "inactive", name: "Inactive" },
                            ]}
                            defaultValue={
                                updateMode
                                    ? {
                                          id: feeCategory?.status,
                                          name: feeCategory?.status,
                                      }
                                    : undefined
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={feeCategory?.editable}
                                    checked={feeCategory?.editable}
                                    name="editable"
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Editable"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={feeCategory?.optional}
                                    checked={feeCategory?.optional}
                                    name="optional"
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Optional"
                        />
                    </Grid>

                    <Grid item xs={12} mt={1}>
                        <Button
                            variant="outlined"
                            type="submit"
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1em" />
                                ) : null
                            }
                        >
                            {!updateMode
                                ? feedback.loading
                                    ? "Adding Fee Category..."
                                    : "Add Fee Category"
                                : feedback.loading
                                ? "Updating Fee Category..."
                                : "Update Fee Category "}
                        </Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </form>
    );
};
