import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

type Props = {
    feeStructure: any;
    amounts: any;
    overrides: any;
    months: number[];
    discounts: any[];
    globalDiscount: any;
};

export const ShowFees = ({
    feeStructure,
    amounts,
    overrides,
    months,
    discounts,
    globalDiscount,
}: Props) => {
    const [categoryAmounts, setCategoryAmounts] = useState<any>(null);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [netAmount, setNetAmount] = useState<number>(0);
    const [discountAmount, setDiscountAmount] = useState<number>(0);

    const calculateAmounts = () => {
        const _categoryAmounts: any = {};

        for (const cat of feeStructure.categories) {
            const categoryAmount =
                amounts[cat.category.id] *
                (cat.category.optional
                    ? Object.values(overrides).filter(
                          (ovr: any) => ovr[cat.category.id] === true
                      ).length
                    : months.length);

            _categoryAmounts[cat.category.id] = categoryAmount;
        }
        setCategoryAmounts(_categoryAmounts);
        const totalAmount = Object.values(_categoryAmounts).reduce(
            (prev, current) => (prev as number) + (current as number),
            0
        );
        setTotalAmount(totalAmount as number);
    };

    const calculateDiscounts = () => {
        let _discountAmount = 0;

        for (const discount of discounts) {
            if (discount.amount) {
                _discountAmount += discount.amount;
            } else {
                const _amount = discount.pct
                    ? amounts[discount.category.id] * (discount.pct / 100)
                    : amounts[discount.category.id] - discount.amount;

                const _months = discount.category.optional
                    ? Object.values(overrides).filter(
                          (ovr: any) => ovr[discount.category.id] === true
                      ).length
                    : months.length;

                const amountForAllMonths = _amount * _months;

                _discountAmount += amountForAllMonths;
            }
        }
        _discountAmount = _discountAmount + (parseFloat(globalDiscount) || 0);
        setDiscountAmount(_discountAmount);
        setNetAmount(totalAmount - _discountAmount);
    };

    useEffect(() => {
        if (amounts && overrides && feeStructure && months && discounts) {
            calculateAmounts();
            calculateDiscounts();
        }
    }, [feeStructure, amounts, overrides, months, discounts]);

    useEffect(() => {
        if (amounts && overrides && feeStructure && months && discounts) {
            calculateDiscounts();
        }
    }, [totalAmount, globalDiscount]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {feeStructure.categories.map((cat: any) => (
                            <TableCell key={cat.id}>
                                {cat.category.name}
                            </TableCell>
                        ))}

                        <TableCell>Original amount</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Net amount</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow>
                        {feeStructure.categories.map((cat: any) => (
                            <TableCell key={cat.id}>
                                {categoryAmounts &&
                                    categoryAmounts[cat.category.id]}
                            </TableCell>
                        ))}
                        <TableCell>{totalAmount}</TableCell>
                        <TableCell>{discountAmount}</TableCell>
                        <TableCell>{netAmount}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
