import { Box, Typography } from "@mui/material";
import React from "react";
import { Field } from "../Certificates";

const Achievement = ({ fields }: any) => {
  return (
    <Box
      sx={{ aspectRatio: "11/8.5" }}
      p={4}
      bgcolor="white"
      color="black"
      borderRadius="12px"
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          border: "4px solid lightGray",
          borderRadius: "12px",
        }}
      >
        <div
          style={{
            flex: "1",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div>header</div>

          <div>
            <Typography
              textTransform="uppercase"
              mb={4}
              fontWeight="bold"
              variant={fields.title.variant}
            >
              {fields.title}
            </Typography>

            <Typography
              textTransform="uppercase"
              fontWeight="bold"
              variant={fields.subTitle.variant}
            >
              {fields.subTitle}
            </Typography>
          </div>

          <Typography variant={fields.userName.variant}>
            {fields.userName}
          </Typography>

          <Typography width="60%" variant={fields.body.variant}>
            {fields.body.text}
          </Typography>

          <div>asdf</div>
        </div>
      </div>
    </Box>
  );
};

export default Achievement;
