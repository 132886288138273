import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import {
    FeesPerCatPerMonth,
    useCalculateFeePerCategoryPerMonth,
    useGetSurcharge,
} from "./DetailsEntire";

type Props = {
    student: any;
    feeStructure: any;
    fees: any[];
    months: any[];
    discounts: any[];
    collections: any[];
};

export const DetailsPerMonth = ({
    fees,
    feeStructure,
    discounts,
    months,
    collections,
}: Props) => {
    const [feesPerCatPerMonth, totals] = useCalculateFeePerCategoryPerMonth(
        fees,
        feeStructure,
        discounts
    );
    const surcharge = useGetSurcharge();

    const fillInMonths = useCallback(() => {
        const _feesPerCatPerMonth: FeesPerCatPerMonth = {};

        for (const month of months) {
            if (
                !Object.keys(feesPerCatPerMonth).includes(
                    month.monthNumber + ""
                )
            ) {
                _feesPerCatPerMonth[month.monthNumber] =
                    feeStructure.categories.reduce(
                        (prev: any, cat: any) => ({
                            ...prev,
                            [cat.category.id]: { original: 0, discount: 0 },
                        }),
                        {}
                    );
            }
        }
        return { ...feesPerCatPerMonth, ..._feesPerCatPerMonth };
    }, [feesPerCatPerMonth, months]);

    const totalCollection = useMemo(
        () => collections.reduce((prev, curr) => prev + curr.collected, 0),
        [collections]
    );

    const totalSurchargeCollected = useMemo(
        () => collections.reduce((prev, curr) => prev + curr.lateFeeFine, 0),
        [collections]
    );

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow
                        sx={{
                            "& > *": { bgcolor: "action.disabledBackground" },
                        }}
                    >
                        <TableCell size="small">Category</TableCell>
                        {months.map((month) => (
                            <TableCell
                                size="small"
                                key={month.monthNumber}
                            >{`${month.month}, ${month.year}`}</TableCell>
                        ))}
                        <TableCell size="small" padding="none">
                            Amount per category
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {feeStructure &&
                        feesPerCatPerMonth &&
                        feeStructure.categories.map((cat: any) => (
                            <TableRow key={cat.category.id}>
                                <TableCell>{cat.category.name}</TableCell>
                                {Object.entries(fillInMonths()).map(
                                    ([month, feePerCat], idx) => (
                                        <TableCell key={idx}>
                                            {feePerCat[cat.category.id]
                                                .original || "-"}
                                        </TableCell>
                                    )
                                )}
                                <TableCell>
                                    {Object.values(feesPerCatPerMonth).reduce(
                                        (total, feePerCat) =>
                                            total +
                                            Object.entries(feePerCat)
                                                .filter(
                                                    ([_cat, _]) =>
                                                        _cat == cat.category.id
                                                )
                                                .map((item) => item[1].original)
                                                .reduce(
                                                    (acc, current) =>
                                                        acc + current,
                                                    0
                                                ),
                                        0
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    <TableRow
                        sx={{
                            "& > td": { bgcolor: "action.disabledBackground" },
                        }}
                    >
                        <TableCell>Total</TableCell>
                        {Object.values(fillInMonths()).map((feePerCat, idx) => (
                            <TableCell key={idx}>
                                {Object.values(feePerCat)
                                    .map((item) => item.original)
                                    .reduce(
                                        (total, current) => current + total,
                                        0
                                    ) || "-"}
                            </TableCell>
                        ))}
                        <TableCell sx={{ fontWeight: "bold" }}>
                            {totals.original + totals.discount}
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{ height: "20px" }}></TableRow>
                    <TableRow>
                        <TableCell colSpan={months.length + 1}>Total</TableCell>
                        <TableCell>
                            {totals.original + totals.discount}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={months.length + 1}>
                            Total Discount
                        </TableCell>
                        <TableCell>{totals.discount}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={months.length + 1}>
                            Payable Within Due Date
                        </TableCell>
                        <TableCell>
                            {totals.original - totalCollection}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={months.length + 1}>Paid</TableCell>
                        <TableCell>{totalCollection}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={months.length + 1}>
                            Surcharge
                        </TableCell>
                        <TableCell>{`${
                            (surcharge?.value || 0) * fees.length
                        } (${surcharge?.value || 0} x${
                            fees.length
                        })`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={months.length + 1}>
                            Payable After Due Date
                        </TableCell>
                        <TableCell>
                            {totals.original -
                                totalCollection +
                                (parseFloat(surcharge?.value) || 0) *
                                    fees.length}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
