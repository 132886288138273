import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

type Props = {
    fields: any;
    setFields: Dispatch<SetStateAction<any>>;
    count: number;
    pagination: any;
    setPagination: Dispatch<SetStateAction<any>>;
};

const Footer = ({
    fields,
    setFields,
    count,
    pagination,
    setPagination,
}: Props) => {
    const handlePageChange = (ev: any, value: number) => {
        console.log(count, pagination.campus.limit, value);

        setPagination(() => ({
            ...pagination,
            campus: { ...pagination.campus, page: value },
        }));
    };

    const handleCheckbox = async (index: number) => {
        const campusDir = awsDirStruct.campus.picture.split("/");
        const key = `${campusDir[0]}/${fields.footer[index].targetId}/${
            campusDir[1]
        }/${fields.footer[index].key.replaceAll(" ", "")}`;

        const [data, err] = await S3Service.getS3ByKey(key);

        let footer = [];

        if (data) {
            footer = fields.footer.map((item: any) =>
                item.id !== fields.footer[index].id
                    ? item
                    : { ...item, checked: !item.checked, imgUrl: data.url }
            );
        }
        if (err) {
            console.log("Error : ", err);

            footer = fields.footer.map((item: any) =>
                item.id !== index + 1
                    ? item
                    : { ...item, checked: !item.checked }
            );
        }

        setFields({
            ...fields,
            footer: footer,
        });
    };

    return (
        <Grid container flexDirection="column">
            {fields.footer?.map((field: any, id: number) => (
                <Grid item key={field.id} xs={12} lg={6}>
                    <FormControlLabel
                        label={field?.key}
                        control={
                            <Checkbox
                                checked={field?.checked}
                                onChange={() => handleCheckbox(id)}
                            />
                        }
                    />
                </Grid>
            ))}

            {/*  <Grid item display="flex" justifyContent="center">
        <Pagination
          onChange={handlePageChange}
          count={Math.trunc(count / pagination.campus.limit)}
          page={pagination.campus.page}
        />
      </Grid> */}
        </Grid>
    );
};

export default Footer;
