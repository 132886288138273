import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class FinanceService {
    static url = `${baseAPI}/org/fees/bank`;

    static async createBank(data: any) {
        try {
            const response = await axios.post(FinanceService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                FinanceService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getBanks(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(FinanceService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getBankTypes(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                FinanceService.url + "/account-types",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteBankSoft(id: string | number) {
        try {
            const response = await axios.delete(FinanceService.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteBankHard(id: string | number) {
        try {
            const response = await axios.delete(FinanceService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editBank(id: string, newFinance: any) {
        try {
            const response = await axios.patch(FinanceService.url, newFinance, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
