import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class PublisherService {
    static url = `${baseAPI}/org/academics/publisher`;

    static async createPublisher(data: any) {
        try {
            const response = await axios.post(PublisherService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                PublisherService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getPublisher(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(PublisherService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deletePublisherSoft(id: string | number) {
        try {
            const response = await axios.delete(
                PublisherService.url + "/soft",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deletePublisherHard(id: string | number) {
        try {
            const response = await axios.delete(
                PublisherService.url + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editPublisher(id: string, newPublisher: any) {
        try {
            const response = await axios.patch(
                PublisherService.url,
                newPublisher,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
