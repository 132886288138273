import { AddBoxOutlined, IndeterminateCheckBox } from "@mui/icons-material";
import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";

type Props = {
    regions: {
        [key: string]: {
            classrooms: { [key: string]: { sections: string[] } };
        };
    };
};

export const UserRegionsTree = ({ regions = {} }: Props) => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const theme = useTheme();

    return (
        <Box>
            {Object.entries(regions).map(([campName, campDomains], idx) => (
                <Box key={idx}>
                    <Typography
                        variant="h6"
                        // color={theme.palette.primary.dark}
                        display="flex"
                        alignItems="center"
                        gap={theme.spacing(1)}
                    >
                        <IconButton
                            size="small"
                            onClick={() =>
                                setOpen({
                                    ...open,
                                    [campName + idx]: !open[campName + idx],
                                })
                            }
                        >
                            {open[campName + idx] ? (
                                <IndeterminateCheckBox
                                    htmlColor={theme.palette.primary.main}
                                    fontSize="small"
                                />
                            ) : (
                                <AddBoxOutlined fontSize="small" />
                            )}
                        </IconButton>

                        {campName}

                        {open[campName + idx] && (
                            <Typography variant="caption">(Campus )</Typography>
                        )}
                    </Typography>

                    <Box
                        component={Collapse}
                        in={open[campName + idx]}
                        sx={{
                            borderLeft: `1px solid ${theme.palette.primary.main}`,
                            pl: theme.spacing(2),
                            ml: theme.spacing(1.75),
                        }}
                    >
                        {!Object.keys(campDomains.classrooms).length && (
                            <Typography variant="body2">
                                User has access to entire {campName}
                            </Typography>
                        )}

                        {Object.entries(campDomains.classrooms).map(
                            ([className, classDomains], key) => (
                                <Box key={key}>
                                    <Typography
                                        color={theme.palette.primary.main}
                                        fontWeight="medium"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                setOpen({
                                                    ...open,
                                                    [className + key]:
                                                        !open[className + key],
                                                })
                                            }
                                        >
                                            {open[className + key] ? (
                                                <IndeterminateCheckBox
                                                    htmlColor={
                                                        theme.palette.primary
                                                            .main
                                                    }
                                                    fontSize="small"
                                                />
                                            ) : (
                                                <AddBoxOutlined fontSize="small" />
                                            )}
                                        </IconButton>
                                        {className}
                                        {open[campName + idx] && (
                                            <Typography variant="caption">
                                                {" "}
                                                (Classroom)
                                            </Typography>
                                        )}
                                    </Typography>

                                    <Box
                                        component={Collapse}
                                        in={open[className + key]}
                                        sx={{
                                            borderLeft: `1px solid ${theme.palette.primary.main}`,
                                            pl: theme.spacing(4),
                                            ml: theme.spacing(2),
                                        }}
                                    >
                                        {classDomains.sections.length ? (
                                            classDomains.sections.map(
                                                (section, sectionId) => (
                                                    <Typography
                                                        key={sectionId}
                                                        // color={
                                                        //     theme.palette
                                                        //         .primary.light
                                                        // }
                                                    >
                                                        {section}{" "}
                                                        {open[
                                                            campName + idx
                                                        ] && (
                                                            <Typography variant="caption">
                                                                (Section)
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                )
                                            )
                                        ) : (
                                            <Typography variant="body2">
                                                User has access to entire{" "}
                                                {className}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
