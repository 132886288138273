import {
    ArrowDownward,
    ArrowUpward,
    ClassRounded,
    ClearAll,
    Person,
} from "@mui/icons-material";
import {
    Alert,
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import BaseAccordian from "../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { FeeDiscountService } from "../../services/FeeDiscountService";
import AddView from "../AddView";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { RUDTable } from "../Table/RUDTable";
import { currencyFormatter } from "../constants";
import AddFeeDiscount from "./AddFeeDiscount";
import TargetEntity from "./TargetEntity";

const FeeDiscount = () => {
    const [filter, setFilter] = useState<any>({
        discountType: "",
        category: "",
        classroom: "",
        student: "",
        apply: 0,
    });
    const { user } = useContext(AppContext);
    const [changes, setChanges] = useState(0);

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            discountType: "",
            category: "",
            classroom: "",
            student: "",
            apply: 0,
        });
    };

    const ChangeFeeDiscount = ({ row, setChangesCount }: any) => {
        const handleLevelChange = async (direction: string) => {
            const [data, err] = await FeeDiscountService.changeLevel(
                row.id,
                direction
            );

            if (data) {
                setChanges((c: number) => c + 1);
            }
        };

        return (
            <div>
                <IconButton
                    onClick={() => handleLevelChange("up")}
                    size="small"
                >
                    <Tooltip title="Move up">
                        <ArrowUpward fontSize="small" />
                    </Tooltip>
                </IconButton>

                <IconButton
                    onClick={() => handleLevelChange("down")}
                    size="small"
                >
                    <Tooltip title="Move down">
                        <ArrowDownward fontSize="small" />
                    </Tooltip>
                </IconButton>
            </div>
        );
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        sx={{ pl: 1.5 }}
                    >
                        Fee Discount
                    </Typography>
                </Grid>

                <Grid item>
                    <MultiPermissionAuthorize ops={["CREATE FEE_DISCOUNT"]}>
                        <AddView
                            label="add fee discount"
                            triggerRowsRefetch={() => setChanges(changes + 1)}
                        >
                            <AddFeeDiscount />
                        </AddView>
                    </MultiPermissionAuthorize>
                </Grid>
            </Grid>

            <BaseAccordian>
                <Grid container spacing={0.5}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/fees/discount/target-types"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    discountType: c?.id || "",
                                })
                            }
                            label="DiscountType"
                            labelKey="type"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            processor={(opt) => ({
                                id: opt.type,
                                type: opt.type,
                            })}
                        />
                    </Grid>

                    {filter.discountType == "student" && (
                        <Grid item xs={12} md={6} lg={3}>
                            <Autocomplete
                                api="/org/student"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        baseUser: c?.id || "",
                                    })
                                }
                                label="Student"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                processor={(opt) => ({
                                    ...opt,
                                    name: opt?.baseUser?.name,
                                    id: opt?.baseUser?.id,
                                })}
                            />
                        </Grid>
                    )}

                    {filter.discountType == "classroom" && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Autocomplete
                                api="/org/academics/classroom"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        classroom: c?.id || "",
                                    })
                                }
                                label="Class"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                apiParams={{ status: "active" }}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            api="/org/fees/category"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    category: c?.id || "",
                                })
                            }
                            label="Fee Category"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Apply Filter
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            {filter.discountType == "" || filter.classroom == "" ? (
                <Alert severity="info">
                    Must provide a discount type and to which the discount is
                    against
                </Alert>
            ) : (
                <RUDTable
                    getter={(page, limit) =>
                        FeeDiscountService.getFeeDiscount(page, limit, {
                            discountType: filter.discountType
                                ? filter.discountType
                                : undefined,

                            category: filter.category
                                ? filter.category
                                : undefined,
                            targetId: filter.classroom
                                ? filter.classroom
                                : filter.student
                                ? filter.student
                                : undefined,
                        })
                    }
                    editor={FeeDiscountService.editFeeDiscount}
                    deletor={
                        user?.baseUser?.ops.includes("DELETE FEE_DISCOUNT")
                            ? (id: string | number) =>
                                  FeeDiscountService.deleteFeeDiscountSoft(id)
                            : undefined
                    }
                    rowsPreprocessor={(row) => ({
                        ...row,
                        discountType: row.discountType,
                        type: (
                            <Chip
                                label={row.discountType}
                                sx={{ textTransform: "capitalize" }}
                                size="small"
                                icon={
                                    row.discountType === "classroom" ? (
                                        <ClassRounded />
                                    ) : (
                                        <Person />
                                    )
                                }
                            />
                        ),
                        deletionId: row.id,
                        categoryname: row?.category?.name,
                        pct: row.pct > 0 ? `${row.pct}%` : "-",
                        amount: row.amount || "-",
                        discount:
                            row.pct > 0
                                ? row.pct + "%"
                                : currencyFormatter(row.amount),
                    })}
                    readables={{
                        id: "ID",
                        type: "Discount Type",
                        // amount: "Discount Amount",
                        // pct: "Percentage Discount",
                        categoryname: "Category Name",
                        discount: "Discount",
                        "Edit Fee Discount": "Edit Fee Discount",
                        "Discount Target": "Discount Target",
                        Changesequence: "Change Sequence",
                    }}
                    customCols={[
                        {
                            header: "Changesequence",
                            content: (row) => (
                                <ChangeFeeDiscount
                                    row={row}
                                    setChangesCount={setChanges}
                                />
                            ),
                        },
                        {
                            header: "Discount Target",
                            content: (row) => <TargetEntity row={row} />,
                        },

                        {
                            header: user?.baseUser?.ops.includes(
                                "UPDATE FEE_DISCOUNT"
                            )
                                ? "Edit Fee Discount"
                                : "",
                            content: (row) => (
                                <MultiPermissionAuthorize
                                    ops={["UPDATE FEE_DISCOUNT"]}
                                >
                                    <AddView
                                        label="Edit Fee Discount"
                                        updateMode
                                        triggerRowsRefetch={() =>
                                            setChanges(changes + 1)
                                        }
                                    >
                                        <AddFeeDiscount row={row} updateMode />
                                    </AddView>
                                </MultiPermissionAuthorize>
                            ),
                        },
                    ]}
                    updatingAgents={[filter.apply, changes]}
                    ops={{
                        read: "READ FEE_DISCOUNT",
                        edit: "",
                        delete: "DELETE FEE_DISCOUNT",
                    }}
                    storageKey="fee Discount"
                />
            )}
        </>
    );
};

export default FeeDiscount;
