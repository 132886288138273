import { makeStyles, Theme } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { notConnectedMessage } from "../constants";

type Props = {
  row: any;
  editables: {
    name: string;
    type: "textfield" | "select";
    fieldProps?: any;
    label: string;
    permission?: string;
    options?: {
      value: any;
      label: string;
      valuesFetcher: (row: any) => Promise<any[]>;
    };
  }[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editor: (...args: any) => Promise<any[]>;
  setChangeCount: Dispatch<SetStateAction<number>>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  form: {
    margin: theme.spacing(2, 2, 4),
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
  },
  feedback: {
    margin: theme.spacing(1),
  },
}));

const initialFeedback = {
  hidden: true,
  message: "",
  severity: "success",
  loading: false,
};

export const EditInRUD = ({
  row,
  editables,
  open,
  setOpen,
  editor,
  setChangeCount,
}: Props) => {
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);
  const [feedback, setFeedback] = useState(initialFeedback);
  const initialObject = useMemo(() => {
    const obj: any = {};
    for (const editable of editables) {
      obj[editable.name] = {
        value: row[editable.name] || "",
        changed: false,
      };
    }
    return obj;
  }, [open]);

  const [values, setValues] = useState<any>({});

  const [newRow, setNewRow] = useState<any>(row);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (ev: ChangeEvent<any>) => {
    setNewRow({
      ...newRow,
      [ev.target.name]: { value: ev.target.value, changed: true },
    });
  };

  const handleEdit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setFeedback({ ...initialFeedback, loading: true });
    const finalRow: any = {};

    for (const [rowKey, rowValue] of Object.entries(newRow)) {
      if ((rowValue as any).changed) {
        finalRow[rowKey] = (rowValue as any).value;
      }
    }

    const [data, err] = await editor(row.id, finalRow);

    if (data) {
      setFeedback({
        message: data.message,
        severity: "success",
        loading: false,
        hidden: false,
      });
      setChangeCount((n: number) => n + 1);
    } else {
      setFeedback({
        message: err || notConnectedMessage,
        severity: "error",
        loading: false,
        hidden: false,
      });
    }
  };

  const fetchSelectOptions = async () => {
    for (const ed of editables.filter((e) => e.type === "select")) {
      const [data, err] = await ed.options!.valuesFetcher(row);
      if (data) {
        setValues((vals: any) => ({ ...vals, [ed.name]: data.rows }));
      }
    }
  };

  useEffect(() => {
    fetchSelectOptions();
  }, []);

  useEffect(() => {
    setNewRow(initialObject);
  }, [initialObject]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      className={classes.root}
    >
      <DialogTitle>Edit</DialogTitle>
      <div className={classes.feedback}>
        {feedback.loading ? <LinearProgress /> : null}
        <section hidden={feedback.hidden}>
          <Alert severity={feedback.severity as any}>{feedback.message}</Alert>
        </section>
      </div>

      <form className={classes.form} onSubmit={handleEdit} ref={formRef}>
        {newRow &&
          editables.map((editable, idx: number) => {
            switch (editable.type) {
              case "textfield":
                return (
                  // <Authorize
                  //     opName={editable.permission}
                  //     key={idx}
                  // >
                  <TextField
                    key={idx}
                    name={editable.name}
                    label={editable.label}
                    variant="outlined"
                    fullWidth
                    value={newRow[editable.name].value}
                    onChange={handleChange}
                    {...editable.fieldProps}
                  />
                  // </Authorize>
                );

              case "select":
                return (
                  // <Authorize
                  //     opName={editable.permission}
                  //     key={idx}
                  // >
                  <TextField
                    key={idx}
                    select
                    name={editable.name}
                    label={editable.label}
                    variant="filled"
                    fullWidth
                    value={newRow[editable.name].value}
                    onChange={handleChange}
                    {...editable.fieldProps}
                  >
                    {values[editable.name]?.map((opt: any) => (
                      <MenuItem
                        key={opt[editable.options!.value]}
                        value={opt[editable.options!.value]}
                      >
                        {opt[editable.options!.label]}
                      </MenuItem>
                    ))}
                  </TextField>
                  // </Authorize>
                );
              default:
                return (
                  // <Authorize
                  //     opName={editable.permission}
                  //     key={idx}
                  // >
                  <TextField />
                  // </Authorize>
                );
            }
          })}
      </form>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            formRef.current?.requestSubmit();
            {
              setTimeout(handleClose, 1000);
            }
          }}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
