import { Add, FormatColorText, Remove } from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    Chip,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
} from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Certificate } from "../Certificates";

type Props = {
    certificate: Certificate;
    setCertificate: Dispatch<SetStateAction<Certificate>>;
    variables: any[];
    userType: string;
};

type ActiveField =
    | "title"
    | "subTitle"
    | "userName"
    | "body"
    | "additionalBody";

// const useStyles = makeStyles((theme: Theme) => ({
//     scrollBar: {
//         "&::-webkit-scrollbar": {
//             height: "5px",
//             borderRadius: "1000px",
//             border: `1px solid ${theme.palette.divider}`,
//         },

//         "&::-webkit-scrollbar-thumb": {
//             background: theme.palette.background.default,
//             borderRadius: "2.5px",
//         },
//     },
// }));

const CertificateText = ({
    certificate,
    setCertificate,
    variables,
    userType,
}: Props) => {
    const [cursorPosition, setCursorPosition] = useState<any>(0);
    const [activeField, setActiveField] = useState<ActiveField>("title");
    const [activeFieldTextColor, setActiveFieldTextColor] = useState("");
    // const classes = useStyles();
    const theme = useTheme();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCursorPosition(e.target.selectionStart);

        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                [e.target.name]: {
                    ...c.fields[e.target.name as keyof Certificate["fields"]],
                    text: e.target.value,
                },
            },
        }));
    };

    const handleAddName = (e: any) => {
        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                [activeField!]: {
                    ...c.fields[activeField],
                    text:
                        c.fields[activeField]!.text.substring(
                            0,
                            cursorPosition
                        ) +
                        e.target.textContent +
                        " " +
                        c.fields[activeField]!.text.substring(
                            cursorPosition,
                            c.fields[activeField]!.text.length
                        ),

                    placeholders: [
                        ...c.fields[activeField]!.placeholders,
                        e.target.textContent,
                    ],
                },
            },
        }));
    };

    const handleActiveField = (e: any) => {
        setActiveField(() => e.target.name);
    };

    const handleFieldColor = (e: ChangeEvent<HTMLInputElement>) => {
        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                [activeField]: {
                    ...c.fields[activeField]!,
                    color: e.target.value,
                },
            },
        }));
    };

    const handleFontSize = (button: string) => {
        let fontSize = +certificate.fields[activeField]!.fontSize;
        if (button === "add") {
            fontSize += 1;
        } else if (button === "remove") {
            fontSize -= 1;
        } else return;

        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                [activeField]: {
                    ...c.fields[activeField]!,
                    fontSize: fontSize,
                },
            },
        }));
    };

    return (
        <Grid container style={{ flexDirection: "column" }} spacing={2}>
            {activeField && (
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                        <Tooltip title="Text Color" placement="top">
                            <IconButton>
                                <FormatColorText
                                    style={{ color: activeFieldTextColor }}
                                    fontSize="small"
                                />

                                <input
                                    type="color"
                                    name="textColor"
                                    onChange={handleFieldColor}
                                    style={{
                                        width: "60%",
                                        height: "60%",
                                        border: "none",
                                        outline: "none",
                                        opacity: "0",
                                        position: "absolute",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <ButtonGroup color="inherit">
                            <Button
                                size="small"
                                onClick={() => handleFontSize("remove")}
                            >
                                <Remove fontSize="small" />
                            </Button>

                            <Button>
                                {certificate.fields[activeField]!.fontSize}
                            </Button>

                            <Button
                                size="small"
                                onClick={() => handleFontSize("add")}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            )}
            <Grid
                item
                // className={classes.scrollBar}
                style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    gap: "8px",
                }}
            >
                {variables[0][userType]?.map((v: any, id: number) => (
                    <Chip
                        key={id}
                        label={`[${v.replaceAll(" ", "_")}]`}
                        component="div"
                        onClick={handleAddName}
                        size="small"
                    />
                ))}
            </Grid>

            <Grid item>
                <TextField
                    fullWidth
                    label="Certificate Name"
                    variant="outlined"
                    size="small"
                    value={certificate.name}
                    required
                    InputLabelProps={{ shrink: Boolean(certificate.name) }}
                    onChange={(event) => {
                        setCertificate((rest) => ({
                            ...rest,
                            name: event.target.value,
                        }));
                    }}
                />
            </Grid>

            <Grid item>
                <TextField
                    onFocus={handleActiveField}
                    // onBlur={() => setActiveField(undefined)}
                    label="Title"
                    value={certificate.fields.title.text}
                    name="title"
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: 1.5 }}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField
                    onFocus={handleActiveField}
                    // onBlur={() => setActiveField(undefined)}
                    label="Subtitle"
                    value={certificate.fields.subTitle.text}
                    name="subTitle"
                    multiline
                    size="small"
                    fullWidth
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: 1.5 }}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField
                    onFocus={handleActiveField}
                    // onBlur={() => setActiveField(undefined)}
                    label="Candiate Name"
                    value={certificate.fields.userName.text}
                    name="userName"
                    multiline
                    size="small"
                    fullWidth
                    // onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: 1.5 }}
                    variant="outlined"
                />
            </Grid>

            <Grid item>
                <TextField
                    onFocus={handleActiveField}
                    // onBlur={() => setActiveField(undefined)}
                    multiline
                    label="Body"
                    size="small"
                    fullWidth
                    name="body"
                    value={certificate.fields.body.text}
                    onChange={(e) => {
                        setCertificate((c) => ({
                            ...c,
                            fields: {
                                ...c.fields,
                                body: {
                                    ...c.fields.body,
                                    text: e.target.value,
                                },
                            },
                        }));

                        setCursorPosition(e.target.selectionStart);
                    }}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginBottom: 1.5 }}
                    variant="outlined"
                />
            </Grid>

            {certificate.fields.additionalBody && (
                <Grid item>
                    <TextField
                        onFocus={handleActiveField}
                        // onBlur={() => setActiveField(undefined)}
                        multiline
                        label="Additional Body"
                        size="small"
                        fullWidth
                        name="additionalBody"
                        value={certificate.fields.additionalBody.text}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default CertificateText;
