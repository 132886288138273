import { TextField } from "@mui/material";

export const LogFilter = ({ filter, setFilter }: any) => {
    return (
        <>
            <TextField
                size="small"
                name="date"
                type="date"
                label="Day"
                fullWidth
                value={filter.date}
                InputLabelProps={{ shrink: true }}
                onChange={(ev) =>
                    setFilter({ ...filter, date: ev.target.value })
                }
            />
        </>
    );
};
