import { Typography } from "@material-ui/core";
import { InfoOutlined, Upgrade } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CampusService } from "../../../services/CampusService";

import { useHistory } from "react-router-dom";
import {
    APIRequestStatus,
    useGetAPI,
    usePostAPI,
} from "../../../services/Hooks";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";

const studentCols = [
    "File no.",
    "Enroll no.",
    "Name",
    "Father name",
    "Classroom",
];

export const ChangeStudent = () => {
    const [feedback, setFeedback] = useState({
        message: "",
        hidden: false,
        severity: "",
        loading: false,
    });
    const { user } = useContext(AppContext);
    const history = useHistory();

    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    const [filterData, setFilterData] = useState<any>({
        _toSession: [],
    });
    const [campuses, setCampuses] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toCampus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
    });

    const getCampuses = async () => {
        const [data, err] = await CampusService.getCampus(1, 0);
        if (data) {
            setCampuses(data.rows);
        }
    };

    useEffect(() => {
        getCampuses();
    }, []);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSelected({ ...selected, [ev.target.name]: ev.target.value });
    };

    const [
        updateStudent,
        updateResponse,
        updateStudentStatus,
        updateStudentMessage,
    ] = usePostAPI(baseAPI + "/org/student/campus-bulk");

    const handleUpdateStudent = async () => {
        if (!selected.exclude || selected.exclude.length == 0) {
            setFeedback({
                loading: false,
                message: "You must checked a row in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else if (!selected.toSession) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a session  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else if (!selected.toClassroom) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a classroom  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else if (!selected.toSection) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a section  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else {
            updateStudent(
                {
                    campus: selected.toCampus,
                    session: selected.toSession,
                    classroom: selected.toClassroom,
                    section: selected.toSection,
                    ids: selected.exclude,
                },
                {}
            );
        }
    };

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        { classroom: selected.fromClassroom, section: selected.fromSection },
        [selected.fromClassroom, selected.fromSection],
        [selected.fromClassroom, selected.fromSection]
    );

    const handleCheckChange = (type: "single" | "all", id?: number) => {
        if (type === "single") {
            if (selected.exclude.includes(id)) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: selected.exclude.filter(
                        (_id: number) => _id !== id
                    ),
                }));
            } else {
                setSelected((s: any) => ({
                    ...s,
                    exclude: [...s.exclude, id],
                }));
            }
        } else {
            if (selected.exclude.length === 0) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: students.map((std: any) => std.id),
                }));
            } else {
                setSelected((s: any) => ({ ...s, exclude: [] }));
            }
        }
    };

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    console.log(updateStudentStatus);

    useEffect(() => {
        if (updateStudentStatus == "success") {
            const timer = setTimeout(() => {
                history.replace("/student");
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [updateStudentStatus, history]);
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {feedback.hidden && (
                    <Alert severity={feedback.severity as any}>
                        {feedback.message}
                    </Alert>
                )}
            </Grid>
            <Grid item xs={12}>
                {(updateStudentStatus === APIRequestStatus.success ||
                    updateStudentStatus === APIRequestStatus.error) && (
                    <Alert
                        severity={
                            updateResponse?.errors?.length ? "error" : "success"
                        }
                        action={
                            <IconButton
                                onClick={() => {
                                    setShowResponseDetails(
                                        !showResponseDetails
                                    );
                                }}
                            >
                                <InfoOutlined fontSize="small" />
                            </IconButton>
                        }
                    >
                        {updateStudentMessage}
                    </Alert>
                )}

                <Collapse in={showResponseDetails}>
                    {updateResponse &&
                        updateResponse.successes?.map(
                            (
                                suc: {
                                    message: string;
                                    id: number | string;
                                },
                                index: number
                            ) => (
                                <Box
                                    key={index}
                                    bgcolor={"#0c130d"}
                                    style={{
                                        display: "flex",
                                        gap: "2rem",
                                        paddingLeft: "2rem",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Typography variant="caption">
                                        {" "}
                                        {`ID (${suc.id}) - ${suc.message}`}
                                    </Typography>
                                </Box>
                            )
                        )}

                    {updateResponse &&
                        updateResponse.errors.map(
                            (
                                err: {
                                    message: string;
                                    id: number | string;
                                },
                                index: number
                            ) => (
                                <Box
                                    key={index}
                                    bgcolor={"#160b0b"}
                                    style={{
                                        display: "flex",
                                        gap: "2rem",
                                        paddingLeft: "2rem",
                                        paddingBottom: "10px",
                                    }}
                                >
                                    <Typography variant="caption">
                                        {`ID (${err.id}) - ${err.message}`}
                                    </Typography>
                                </Box>
                            )
                        )}
                </Collapse>
            </Grid>

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSession: c?.id || "",
                        })
                    }
                    label="Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromClassroom: c?.id || "",
                        })
                    }
                    label="Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4} sx={{ mb: "2rem" }}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSection: c?.id || "",
                        })
                    }
                    label="Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.fromClassroom,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ mb: "2rem" }}> SELECT </Divider>
            </Grid>

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                toCampus: c?.id || "",
                            })
                        }
                        label="To Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSession: c?.id || "",
                        })
                    }
                    label="To Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        campus: selected.campus,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toClassroom: c?.id || "",
                        })
                    }
                    label="To Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                    dependencies={{ ["fromClassroom"]: selected.fromClassroom }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSection: c?.id || "",
                        })
                    }
                    label="To Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.toClassroom,
                        status: "active",
                    }}
                    dependencies={{ ["fromSection"]: selected.fromSection }}
                />
            </Grid>

            {selected.campus == "" ||
            selected.fromSession == "" ||
            selected.fromClassroom == "" ||
            selected.fromSection == "" ? (
                <Alert
                    severity="info"
                    style={{ width: "100%", marginTop: "1rem" }}
                >
                    Please Select a campus,session,classroom and section
                </Alert>
            ) : (
                <>
                    {" "}
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{
                                            "&>*": { bgcolor: "action.hover" },
                                        }}
                                    >
                                        {studentStatus ===
                                            APIRequestStatus.loading && (
                                            <TableCell colSpan={6}>
                                                <LinearProgress />
                                            </TableCell>
                                        )}
                                        {studentStatus ===
                                            APIRequestStatus.error && (
                                            <TableCell colSpan={6}>
                                                <Alert severity="error">
                                                    {studentMessage}
                                                </Alert>
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    selected.exclude.length ===
                                                    students.length
                                                }
                                                onChange={() =>
                                                    handleCheckChange("all")
                                                }
                                                indeterminate={
                                                    selected.exclude.length >
                                                        0 &&
                                                    selected.exclude.length <
                                                        studentCount
                                                }
                                            />
                                        </TableCell>
                                        {studentCols.map((col) => (
                                            <TableCell key={col}>
                                                {col}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        studentCount === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={6}>
                                                    <Alert severity="info">
                                                        No students in classroom
                                                    </Alert>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    {studentStatus ===
                                        APIRequestStatus.success &&
                                        students.map((student: any) => (
                                            <TableRow key={student.id}>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={selected.exclude.includes(
                                                            student.id
                                                        )}
                                                        onChange={() =>
                                                            handleCheckChange(
                                                                "single",
                                                                student.id
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {student.fileNo}
                                                </TableCell>
                                                <TableCell>
                                                    {student.enrollmentNo}
                                                </TableCell>
                                                <TableCell>
                                                    {student.baseUser.name}
                                                </TableCell>
                                                <TableCell>
                                                    {student.fatherName}
                                                </TableCell>
                                                <TableCell>{`${student.classroom.name} (${student.section.name}), ${student.session.name}`}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    type={"submit"}
                    onClick={handleUpdateStudent}
                    sx={{ height: "100%" }}
                    disabled={updateStudentStatus === APIRequestStatus.loading}
                    endIcon={
                        updateStudentStatus === APIRequestStatus.loading ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <Upgrade />
                        )
                    }
                >
                    {updateStudentStatus === APIRequestStatus.loading
                        ? "Updating Student..."
                        : "Update Student"}
                </Button>
            </Grid>
        </Grid>
    );
};
