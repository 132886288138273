import { Image } from "@mui/icons-material";
import { Grow, IconButton, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

export const ShowBookImage = ({ row }: any) => {
    const [src, setSrc] = useState("");
    const [fetchImage, setFectch] = useState(false);
    const [open, setOpen] = useState(false);
    const [imageError, setImageError] = useState(false); // Track broken images

    const getImage = async () => {
        const key = `${awsDirStruct.library.bookPictures}/${row.id}`;

        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setSrc(data.url);
            setImageError(false);
        }
    };

    useEffect(() => {
        if (fetchImage) {
            getImage();
        }
    }, [fetchImage]);

    const renderContent = () => {
        if (imageError || !src) {
            return <div>No image uploaded</div>;
        }

        return (
            <img
                src={src}
                alt="Uploaded"
                style={{
                    objectFit: "cover",
                    height: "90%",
                    margin: "auto 0",
                }}
                onError={() => setImageError(true)} // Handle image load errors
            />
        );
    };

    return (
        <>
            {!open && (
                <IconButton
                    onClick={() => {
                        setOpen(true);
                        setFectch(true);
                    }}
                    size="small"
                >
                    <Image fontSize="small" />
                </IconButton>
            )}

            {open && (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backdropFilter: "blur(4px)",
                    }}
                >
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grow in={open}>{renderContent()}</Grow>
                    </div>
                </Modal>
            )}
        </>
    );
};
