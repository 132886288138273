import { Grid, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { MutableRefObject, useRef } from "react";

export const ThemeItem = ({ item, theme, setTheme }: any) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const displayRef = useRef<HTMLDivElement | null>(null);

    const handleChange = (ev: any, ref: MutableRefObject<any>) => {
        setTheme((theme: any) => ({ ...theme, [item.name]: ev.target.value }));
        if (ref.current) {
            ref.current.style.backgroundColor = ev.target.value;
        }
    };

    const handleClick = (ref: MutableRefObject<any>) => {
        if (ref.current) {
            ref.current.click();
        }
    };
    return (
        <Grid item xs={12} sm={6} lg={3}>
            <ListItem sx={{ width: "max-content" }}>
                <input
                    value={theme[item.name]}
                    type="color"
                    name="primary"
                    style={{ visibility: "hidden", position: "absolute" }}
                    ref={inputRef}
                    onChange={(ev) => handleChange(ev, displayRef)}
                />
                <ListItemAvatar>
                    <div
                        ref={displayRef}
                        style={{
                            backgroundColor: theme[item.name],
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            cursor: "pointer",
                            marginRight: "1rem",
                            border: "1px solid grey",
                        }}
                        onClick={() => handleClick(inputRef)}
                    ></div>
                </ListItemAvatar>

                <ListItemText
                    primary={item.label}
                    secondary={theme[item.name]}
                />
            </ListItem>
        </Grid>
    );
};
