import { makeStyles } from "@material-ui/core";
import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Theme,
} from "@mui/material";
import { FormEvent, useContext, useEffect, useState } from "react";
import { SectionServices } from "../../../services/SectionServices";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";

const useStyles = makeStyles((theme: Theme) => ({
    breadcrum: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
    },
}));

type Props = {
    row?: any;
    updateMode?: boolean;
};

export function EditSection({ row, updateMode = false }: Props) {
    const { feedback, setFeedback, user } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const classes = useStyles();
    const [section, setSection] = useState<any>({
        name: "",
        classroom: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        status: "",
    });
    const [selectedCampus, setSelectedCampus] = useState("");

    const [getClassroom, setClassroom] = useState([]);
    const [campuses, setCampuses] = useState([]);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setSection({
            ...section,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = async (ev: FormEvent) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const [data, err] = await SectionServices.editSection(row.id, {
            ...section,
            campus: undefined,
        });
        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });
            const timer = setTimeout(() => {
                setOpen(false);
                triggerRowsRefetch();
            }, 3000);
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
        };
    }, []);

    useEffect(() => {
        const { campus, ...otherData } = row?.classroom;
        console.log(otherData);

        if (updateMode) {
            setSection({
                name: row.name,
                classroom: otherData,
                campus: campus.id,
            });
        }

        console.log(row);
    }, [updateMode]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid item container spacing={1.5}>
                <Grid item xs={12}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        size="small"
                        variant="outlined"
                        label="Section"
                        value={section.name}
                        name="name"
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    {user.baseUser.userRegions.length > 1 && (
                        <Grid item xs={12}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c) =>
                                    setSection({
                                        ...section,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                defaultValue={
                                    updateMode && row.classroom.campus
                                }
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid item xs={12} md={12}>
                    <Autocomplete
                        setOutput={(c) =>
                            setSection({
                                ...section,
                                status: c?.id || "",
                            })
                        }
                        label="Status"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                        defaultOptions={[
                            { id: "active", name: "Active" },
                            { id: "inactive", name: "Inactive" },
                        ]}
                        defaultValue={
                            updateMode
                                ? {
                                      id: row?.status,
                                      name: row?.status,
                                  }
                                : undefined
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Autocomplete
                        api="/org/academics/classroom"
                        setOutput={(c) =>
                            setSection({
                                ...section,
                                classroom: c?.id || "",
                            })
                        }
                        label="Classroom"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        apiParams={{
                            campus: section.campus
                                ? section.campus
                                : row.classroom.campus.id,
                            status: "active",
                        }}
                        defaultValue={updateMode && row.classroom}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding section..."
                                : "Add section"
                            : feedback.loading
                            ? "updating section..."
                            : "update section"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
