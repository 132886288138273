import { Certificate } from "./Certificates";

export type CertificateTemplateVariant = "academic";

export const certificatesData: {
    [key in CertificateTemplateVariant]: Certificate;
} = {
    academic: {
        type: "academic",
        name: "zamaschool",
        fields: {
            header: {
                logo: false,
                profilePicture: false,
                position: "space-evenly",
                baseUser: { id: "", name: "" },
            },

            title: {
                text: "[certificate title]",
                placeholders: [],
                color: "",
                fontSize: 48,
            },
            subTitle: {
                text: "this certifies that",
                placeholders: [],
                color: "",
                fontSize: 16,
            },
            userName: {
                text: "[Name]",
                placeholders: ["[Name]"],
                color: "",
                fontSize: 34,
            },
            body: {
                text: "Has completed the elementary course of study at Ridgebow Preparatory School and its therefore entitled to this",
                placeholders: [],
                color: "",
                fontSize: 16,
            },
            additionalBody: {
                text: " Given at the Ridgebow Auditorium this 25th day of February, year of 2022",
                placeholders: [],
                color: "",
                fontSize: 16,
            },
            footer: {
                position: "left",
                fields: [
                    {
                        id: 1,
                        label: "CEO Sign",
                        checked: false,
                        type: "text",
                    },

                    {
                        id: 2,
                        label: "Principal Sign",
                        checked: false,
                        type: "text",
                    },
                ],
            },
        },
        thumbnail: "./templates/certificates/academic.jpg",
    },
};

// [
//     {
//         type: "academic",
//         fields: {
//             header: {
//                 logo: false,
//                 profilePicture: false,
//                 position: "space-evenly",
//             },
//             title: {
//                 text: "[certificate title]",
//                 placeholders: [],
//                 color: "",
//                 fontSize: 48,
//             },
//             subTitle: {
//                 text: "this certifies that",
//                 placeholders: [],
//                 color: "",
//                 fontSize: 16,
//             },
//             userName: {
//                 text: "[Name]",
//                 placeholders: ["[Name]"],
//                 color: "",
//                 fontSize: 34,
//             },
//             body: {
//                 text: "Has completed the elementary course of study at Ridgebow Preparatory School and its therefore entitled to this",
//                 placeholders: [],
//                 color: "",
//                 fontSize: 16,
//             },
//             additionalBody: {
//                 text: " Given at the Ridgebow Auditorium this 25th day of February, year of 2022",
//                 placeholders: [],
//                 color: "",
//                 fontSize: 16,
//             },
//             footer: [
//                 {
//                     id: 1,
//                     label: "CEO Sign",
//                     checked: false,
//                     type: "text",
//                 },

//                 {
//                     id: 2,
//                     label: "Principal Sign",
//                     checked: false,
//                     type: "text",
//                 },
//             ],
//         },
//         variant: Academic,
//         thumbnail: "./templates/certificates/academic.jpg",
//     },
// ];
