import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class TestService {
    static url = `${baseAPI}/org/test-result`;

    static async getTestResult(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(TestService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getTest(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(TestService.url + "/test", {
                params: { page, limit, ...filter },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    // Soft Deleting of the session
    static async deleteTestResult(id: string | number) {
        try {
            const response = await axios.delete(
                TestService.url + "/delete-test-result",
                {
                    params: { id },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteTest(id: string | number) {
        try {
            const response = await axios.delete(
                TestService.url + "/delete-test",
                {
                    params: { id },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async deleteTestResultHard(id: string | number) {
        try {
            const response = await axios.delete(TestService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editTestResult(id: string, newData: any) {
        try {
            const response = await axios.patch(TestService.url, newData, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editTest(id: string, newData: any) {
        try {
            const response = await axios.patch(
                TestService.url + "/test",
                newData,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static updateTestResultBulk = async (data: any) => {
        try {
            const response = await axios.post(TestService.url + "/bulk", data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.error(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    };
}
