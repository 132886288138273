import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Collapse,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Typography,
    useTheme,
} from "@mui/material";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useEffect,
    useState,
} from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { GET } from "../../services/BaseService";
import ThemeToggle from "../ThemeToggle";
import CustomFields from "./CustomFields/CustomFields";
import { Surcharge } from "./Fee/Surcharge";
import { Header } from "./Header/Header";
import { StudentSortKey } from "./Other/StudentSortKey";
import { ClientTheme } from "./Theme/ClientTheme";

type SettingCtxType = {
    settings: any;
    setSettings: Dispatch<SetStateAction<any>>;
};

export const SettingsCtx = createContext({} as SettingCtxType);

const getSettings = async () => {
    const [data, err] = await GET("/settings");

    if (data) {
        return { rows: data.rows, error: null };
    } else return { rows: null, error: err };
};

const Settings = () => {
    const theme = useTheme();
    const { path } = useRouteMatch();
    const [customFieldsTemplates, setCustomFieldsTemplate] = useState([]);
    const [settings, setSettings] = useState({
        "late-fee-fine": {},
        "student-sort-key": {},
    });

    const getCustomFieldsTemplates = async () => {
        const [data, err] = await GET("/customfield/template");

        if (data) {
            setCustomFieldsTemplate(data.rows);
        }
    };

    useEffect(() => {
        getCustomFieldsTemplates();
        getSettings().then(({ rows, error }) =>
            setSettings(
                rows.reduce(
                    (prev: any, curr: any) => ({ ...prev, [curr.key]: curr }),
                    {}
                )
            )
        );
    }, []);

    return (
        <Grid container gap={2}>
            <Grid
                item
                width="240px"
                borderRight={`1px solid ${theme.palette.divider}`}
            >
                <MultiPermissionAuthorize ops={["READ CUSTOM_FIELDS"]}>
                    <Module
                        label="Custom Fields"
                        subFields={customFieldsTemplates.map((field: any) => ({
                            id: field.id,
                            label: field.name,
                            url: `${path}/custom-fields/${field.name}?template=${field.id}`,
                        }))}
                    />
                </MultiPermissionAuthorize>

                <Module
                    label="Personalization"
                    subFields={[
                        {
                            id: "1",
                            label: "Theme",
                            url: `${path}/theme`,
                        },

                        {
                            id: "2",
                            label: "Header",
                            url: `${path}/header`,
                        },

                        {
                            id: "3",
                            label: "Footer",
                            url: `${path}/footer`,
                        },
                    ]}
                />

                <Module
                    label="Student"
                    subFields={[
                        {
                            id: "1",
                            label: "Sorting",
                            url: `${path}/student/sort-by`,
                        },
                    ]}
                />

                <Module label="Fee" url={`${path}/fee`} />
            </Grid>

            <Grid
                flex="1"
                pt={1}
                pr={1}
                height="calc(100vh - 82px)"
                overflow="auto"
            >
                <Route path={`${path}/theme`}>
                    <Paper elevation={0} sx={{ p: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography variant="body1">
                                Switch Theme
                            </Typography>
                            <ThemeToggle />
                        </div>

                        <Divider sx={{ my: "1rem" }} />

                        <ClientTheme />
                    </Paper>
                </Route>

                <Route path={`${path}/header`}>
                    <Header updateMode />
                </Route>

                {/* <Route path={`${path}/footer`}>
                    <Footer />
                </Route> */}

                <Route path={`${path}/custom-fields/campus`}>
                    <CustomFields name="campus" />
                </Route>

                <Route path={`${path}/custom-fields/student`}>
                    <CustomFields name="student" />
                </Route>

                <Route path={`${path}/student/sort-by`}>
                    <StudentSortKey setting={settings["student-sort-key"]} />
                </Route>

                <Route path={`${path}/fee`}>
                    <Surcharge setting={settings["late-fee-fine"]} />
                </Route>
            </Grid>
        </Grid>
    );
};

export default Settings;

type SubModule = { id: string; label: string; url: string };

type ModuleProps = {
    label: string;
    url?: string;
    subFields?: SubModule[];
};

const Module = ({ label, subFields, url }: ModuleProps) => {
    const [collapse, setCollapse] = useState<any>({});
    const theme = useTheme();

    return (
        <>
            <List
                sx={{ mr: 1 }}
                disablePadding
                onClick={() =>
                    setCollapse({ ...collapse, [label]: !collapse[label] })
                }
            >
                <ListItemButton>
                    {url ? (
                        <Link
                            to={url}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                                textTransform: "capitalize",
                                width: "100%",
                            }}
                        >
                            <ListItemText>
                                <Typography variant="body2">{label}</Typography>
                                {/* {label} */}
                            </ListItemText>
                        </Link>
                    ) : (
                        <ListItemText>
                            <Typography variant="body2">{label}</Typography>
                            {/* {label} */}
                        </ListItemText>
                    )}

                    {subFields ? (
                        subFields.length > 0 && collapse[label] ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )
                    ) : null}
                </ListItemButton>
            </List>

            {subFields && subFields.length > 0 && (
                <Collapse
                    in={collapse[label]}
                    // sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                >
                    <List disablePadding sx={{ mr: 1 }}>
                        {subFields.map((subModule: any) => (
                            <Link
                                key={subModule.id}
                                to={subModule.url}
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    textTransform: "capitalize",
                                }}
                            >
                                <ListItemButton sx={{ pl: 4, py: 0 }}>
                                    <ListItemText>
                                        <Typography variant="body2">
                                            {subModule.label}
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </Link>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};
