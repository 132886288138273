import { useContext } from "react";
import { AppContext } from "./components/AppContext";

interface Props {
    children: any;
    ops: string[];
    fallback?: JSX.Element | null;
    strategy?: "some" | "all";
}

export function MultiPermissionAuthorize({
    children,
    ops,
    fallback = null,
    strategy = "all",
}: Props) {
    const appCtx = useContext(AppContext);

    const userops = appCtx?.user?.baseUser?.ops?.map((item: any) => {
        return item;
    });

    if (ops.length === 0) {
        return children;
    } else if (
        userops
            ? strategy === "all"
                ? ops.every((op) => userops.includes(op))
                : ops.some((op) => userops.includes(op))
            : true
    ) {
        return children;
    } else {
        return fallback;
    }
}

export function useMultipermissionAuthorize(
    ops: Props["ops"],
    strategy: Props["strategy"] = "all"
) {
    const appCtx = useContext(AppContext);

    const userops = appCtx?.user?.baseUser?.ops?.map((item: any) => {
        return item;
    });

    if (
        userops
            ? strategy === "all"
                ? ops.every((op) => userops.includes(op))
                : ops.some((op) => userops.includes(op))
            : true
    ) {
        return true;
    } else {
        return false;
    }
}
