import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export const GET = async (url: string, params: any = {}) => {
  try {
    const response = await axios.get(baseAPI + url, {
      withCredentials: true,
      params,
    });
    return [response.data, null];
  } catch (err: any) {
    return [null, err.response?.data?.message || notConnectedMessage];
  }
};

export const POST = async (url: string, data: any = null, params: any = {}) => {
  try {
    const response = await axios.post(baseAPI + url, data, {
      withCredentials: true,
      params,
    });
    return [response.data, null];
  } catch (err: any) {
    return [null, err.response?.data?.message || notConnectedMessage];
  }
};

export const PATCH = async (
  url: string,
  data: any = null,
  params: any = {}
) => {
  try {
    const response = await axios.patch(baseAPI + url, data, {
      withCredentials: true,
      params,
    });
    return [response.data, null];
  } catch (err: any) {
    return [null, err.response?.data?.message || notConnectedMessage];
  }
};

export const DELETE = async (url: string, id: string | number) => {
  try {
    const response = await axios.delete(baseAPI + url + "/soft", {
      withCredentials: true,
      params: { id },
    });
    return [response.data, null];
  } catch (err: any) {
    return [null, err.response?.data?.message || notConnectedMessage];
  }
};

export const DELETE_HARD = async (url: string, id: string | number) => {
  try {
    const response = await axios.delete(baseAPI + url + "/hard", {
      withCredentials: true,
      params: { id },
    });
    return [response.data, null];
  } catch (err: any) {
    return [null, err.response?.data?.message || notConnectedMessage];
  }
};
