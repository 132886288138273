import { ClearAll, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FeeDiscountService } from "../../services/FeeDiscountService";
import { FeeService } from "../../services/FeeService";
import { FeeStructureService } from "../../services/FeeStructureService";
import { StudentService } from "../../services/StudentService";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { ApplyFeeFeedback } from "./ApplyFeeFeedback";
import { ShowFees } from "./ShowFees";

export const yearMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const deriveMonthsFromTimeRange = (start: Date, end: Date): any[] => {
    let months;
    months = (end.getFullYear() - start.getFullYear()) * 12;
    months -= start.getMonth();
    months += end.getMonth();

    if (months <= 0) {
        return [];
    }

    months = Array(months)
        .fill(1)
        .map((_, idx) => idx + 1);
    const monthsWithYear = [];
    let year = start.getFullYear();

    for (let i = 0; i < months.length; i++) {
        if (i % 12 === 0 && i > 0) {
            year++;
        }

        monthsWithYear.push({
            month: yearMonths[i % 12],
            year,
            monthNumber: i + 1,
        });
    }

    return monthsWithYear;
};

type Props = {
    variant: "classroom" | "student";
};

const ApplyFees = ({ variant }: Props) => {
    const theme = useTheme();
    const history = useHistory();
    const { user, feedback, setFeedback } = useContext(AppContext);
    const [feeStructure, setFeeStructure] = useState<any>(null);
    const [fees, setFees] = useState<any>(
        variant === "classroom"
            ? {
                  campus:
                      user?.baseUser?.userRegions?.length > 1
                          ? ""
                          : user?.baseUser?.userRegions[0]?.campus?.id,
                  classroom: "",
                  session: "",
                  discount: 0,
                  status: "",
                  dueDate: "",
                  months: [],
                  apply: 0,
              }
            : {
                  student: "",
                  dueDate: "",
                  discount: 0,
                  months: [],
                  status: "",
                  classroom: "",
                  apply: 0,
              }
    );
    const [overrides, setOverrides] = useState<any>(null);
    const [discounts, setDiscounts] = useState<any>(null);

    const [amounts, setAmounts] = useState<any>(null);
    const [months, setMonths] = useState<any[]>([]);
    const location = useLocation();

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFees({
            campus: "",
            classroom: "",
            session: "",
        });
    };

    const [feedbackData, setFeedbackData] = useState({
        successes: [],
        errors: [],
        partialSuccesses: [],
    });

    const getFeeStructure = async (classroom: string, session: string) => {
        const [data, err] = await FeeStructureService.getFeeStructure(1, 5, {
            classroom,
            session,
        });

        if (data) {
            if (data.rows.length > 0) {
                const _months = deriveMonthsFromTimeRange(
                    new Date(data.rows[0].session.start),
                    new Date(data.rows[0].session.end)
                );
                const _overrides: any = {};

                for (let month = 1; month < _months.length + 1; month++) {
                    _overrides[month] = {};

                    for (const cat of data.rows[0].categories) {
                        if (!cat.category.optional) {
                            _overrides[month][cat.category.id] = true;
                        } else {
                            _overrides[month][cat.category.id] = false;
                        }
                    }
                }
                const _amounts: any = {};
                for (const cat of data.rows[0].categories.filter(
                    (cat: any) => cat.editable
                )) {
                    _amounts[cat.category.id] = cat.amount;
                }
                setAmounts(
                    data.rows[0].categories.reduce(
                        (prevValue: any, cat: any) => ({
                            ...prevValue,
                            [cat.category.id]: cat.amount,
                        }),
                        {}
                    )
                );
                // setFees({ ...fees, session });
                setOverrides(_overrides);
                setFeeStructure(data.rows[0]);
                setMonths(_months);
            }
        }
    };

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.name === "campus") {
            setFees({
                ...fees,
                [ev.target.name]: ev.target.value,
                classroom: "",
                session: "",
            });
        } else if (ev.target.name === "discount") {
            const maxValue = calculateNetAmount();
            const value = Math.min(parseFloat(ev.target.value), maxValue);
            setFees({ ...fees, [ev.target.name]: value });
        } else {
            setFees({ ...fees, [ev.target.name]: ev.target.value });
        }
    };

    const handleOverrideChange = (
        month: string | number,
        id: string | number,
        checked: boolean
    ) => {
        setOverrides({
            ...overrides,
            [month]: { ...overrides[month], [id]: checked },
        });
    };

    const getClassroomDiscounts = async () => {
        const [data, err] = await FeeDiscountService.getFeeDiscount(1, 100, {
            discountType: "classroom",
            targetId: fees.classroom,
        });

        if (data) {
            setDiscounts(data.rows);
        }
    };

    const handleMonthsChange = (month: number) => {
        if (fees.months.includes(month)) {
            setFees({
                ...fees,
                months: fees.months.filter((m: number) => m !== month),
            });
        } else {
            setFees({ ...fees, months: [...fees.months, month] });
        }
    };

    const composeOverrides = () => {
        const _overrides = [];

        for (const month of Object.keys(overrides).filter((m: any) =>
            fees.months.includes(parseInt(m))
        )) {
            for (const categoryId of Object.keys(overrides[month])) {
                const cat = feeStructure.categories.find(
                    (cat: any) => cat.category.id == categoryId
                );

                const _newOverride = {
                    month,
                    checked: overrides[month][categoryId],
                    amount: amounts[categoryId],
                    category: categoryId,
                };

                if (
                    cat.amount !== _newOverride.amount ||
                    cat.category.optional
                ) {
                    _overrides.push(_newOverride);
                }
            }
        }

        return _overrides;
    };

    const handleApplyFees = async () => {
        if (
            variant === "classroom" &&
            (!fees.classroom || !fees.session || !fees.dueDate)
        ) {
            setFeedback({
                ...feedback,
                severity: "warning",
                message:
                    "Select a classroom, session to apply the fees to, and a due date",
                show: true,
            });
            return;
        }

        if (variant === "student" && !fees.dueDate) {
            setFeedback({
                ...feedback,
                severity: "warning",
                message: "Select a due date",
                show: true,
            });
            return;
        }

        setFeedback({ ...feedback, loading: true });

        const _overrides = composeOverrides();

        let data, err;

        if (variant === "classroom") {
            [data, err] = await FeeService.applyFeesByClassroom({
                ...fees,
                overrides: _overrides,
                discount: parseFloat(fees.discount),
            });
        } else {
            [data, err] = await FeeService.applyFeesByStudent({
                ...fees,
                overrides: _overrides,
                discount: parseFloat(fees.discount),
                session: feeStructure.session.id,
                classroom: feeStructure.classroom.id,
            });
        }

        setFeedbackData({
            successes: data.successes,
            errors: data.errors,
            partialSuccesses: data.partialSuccesses,
        });

        if (data) {
            setFeedback({
                severity: "success",
                message: data.message,
                show: true,
                loading: false,
            });

            const timer = setTimeout(() => {
                history.replace("/fee");
            }, 3000);
        } else {
            setFeedback({
                severity: "error",
                message: err,
                show: true,
                loading: false,
            });
        }
    };

    const getStudent = async (id: string) => {
        const [data, err] = await StudentService.getStudent(1, 1, { id });

        if (data && data.count > 0) {
            const targetStudent = data.rows[0];
            await getFeeStructure(
                targetStudent.classroom.id,
                targetStudent.session.id
            );
        }
    };

    const calculateNetAmount = () => {
        let _discountAmount = 0;

        for (const discount of discounts) {
            if (discount.amount) {
                _discountAmount += discount.amount;
            } else {
                const _amount = discount.pct
                    ? amounts[discount.category.id] * (discount.pct / 100)
                    : amounts[discount.category.id] - discount.amount;

                const _months = discount.category.optional
                    ? Object.values(overrides).filter(
                          (ovr: any) => ovr[discount.category.id] === true
                      ).length
                    : months.length;

                const amountForAllMonths = _amount * _months;

                _discountAmount += amountForAllMonths;
            }
        }

        const _categoryAmounts: any = {};

        for (const cat of feeStructure.categories) {
            const categoryAmount =
                amounts[cat.category.id] *
                (cat.category.optional
                    ? Object.values(overrides).filter(
                          (ovr: any) => ovr[cat.category.id] === true
                      ).length
                    : fees.months.length);

            _categoryAmounts[cat.category.id] = categoryAmount;
        }

        const totalAmount = (
            Object.values(_categoryAmounts) as number[]
        ).reduce((prev, current) => (prev as number) + (current as number), 0);

        return totalAmount - _discountAmount;
    };

    useEffect(() => {
        if (location && variant === "student") {
            const query = new URLSearchParams(location.search);
            const student = query.get("student");
            if (student) {
                setFees({ ...fees, student });
                getStudent(student);
            }
        }
    }, [location, variant]);

    useEffect(() => {
        if (fees.classroom && fees.session && variant === "classroom") {
            getFeeStructure(fees.classroom, fees.session);
        } else {
            setAmounts(null);
            setOverrides(null);
            setDiscounts([]);
            setFeeStructure(null);
            setMonths([]);
        }
    }, [fees.apply, variant]);

    useEffect(() => {
        if (fees.classroom && feeStructure && variant === "classroom") {
            getClassroomDiscounts();
        }

        return () => {
            setFeedback({
                severity: "success",
                message: "",
                show: false,
                loading: false,
            });
        };
    }, [fees.classroom, feeStructure, variant]);

    useEffect(() => {
        return () => {
            setFeedback({ ...feedback, show: false });
        };
    }, []);

    return (
        <Grid container spacing={2}>
            {variant === "classroom" && (
                <Grid item xs={12}>
                    <ApplyFeeFeedback
                        feedback={feedback}
                        feedbackData={feedbackData}
                    />
                </Grid>
            )}
            {variant === "student" && feedback.show && (
                <Grid item xs={12}>
                    <Alert severity={feedback.severity}>
                        {feedback.message}
                    </Alert>
                </Grid>
            )}

            {variant === "classroom" && (
                <>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setFees({
                                        ...fees,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/session"
                            setOutput={(s: any) =>
                                setFees({
                                    ...fees,
                                    session: s?.id || "",
                                })
                            }
                            label="Session"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                campus: fees.campus,
                                status: "active",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            api="/org/academics/classroom"
                            setOutput={(s: any) =>
                                setFees({
                                    ...fees,
                                    classroom: s?.id || "",
                                })
                            }
                            label="Class"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            apiParams={{
                                campus: fees.campus,
                                status: "active",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    name="discount"
                    label="Discount"
                    value={fees.discount}
                    onChange={handleChange}
                    type="number"
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    name="status"
                    label="Status"
                    value={fees.status}
                    onChange={handleChange}
                    size="small"
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    name="dueDate"
                    label="Due date"
                    value={fees.dueDate}
                    onChange={handleChange}
                    size="small"
                    type="datetime-local"
                    InputLabelProps={{ shrink: true }}
                    error={
                        feedback.severity === "warning" && fees.dueDate === ""
                    }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Button
                    color="primary"
                    fullWidth
                    variant="contained"
                    onClick={() =>
                        setFees({
                            ...fees,
                            apply: fees.apply + 1,
                        })
                    }
                    style={{ height: "100%" }}
                    size="small"
                >
                    Apply Filter
                </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Tooltip title="Clear Filters">
                    <IconButton onClick={handleFilterClear} color="primary">
                        <ClearAll />
                    </IconButton>
                </Tooltip>
            </Grid>
            {feeStructure && overrides && amounts && (
                <Grid item xs={12} container>
                    {months?.map((month, idx) => (
                        <Grid item xs={4} md={3} key={idx}>
                            <FormControlLabel
                                label={`${month.month} ${month.year}`}
                                control={
                                    <Checkbox
                                        checked={fees?.months?.includes(
                                            idx + 1
                                        )}
                                        onChange={() =>
                                            handleMonthsChange(idx + 1)
                                        }
                                    />
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            <Grid item container xs={12}>
                {feeStructure && overrides && amounts ? (
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Select months</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {feeStructure.categories.map((cat: any) => (
                                    <TableRow key={cat.id}>
                                        <TableCell size="small">
                                            {cat.category?.name}
                                        </TableCell>
                                        <TableCell size="small">
                                            <TextField
                                                type="number"
                                                disabled={
                                                    !cat.category.editable
                                                }
                                                size="small"
                                                value={amounts[cat.category.id]}
                                                onChange={
                                                    cat.category.editable
                                                        ? (ev) =>
                                                              setAmounts({
                                                                  ...amounts,
                                                                  [cat.category
                                                                      .id]:
                                                                      parseFloat(
                                                                          ev
                                                                              .target
                                                                              .value
                                                                      ) || 0,
                                                              })
                                                        : undefined
                                                }
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                >
                                                    Select months
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container>
                                                        {months.map(
                                                            (month, idx) => (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={4}
                                                                    key={idx}
                                                                >
                                                                    <FormControlLabel
                                                                        key={
                                                                            idx
                                                                        }
                                                                        label={`${month.month} ${month.year}`}
                                                                        control={
                                                                            <Checkbox
                                                                                onChange={(
                                                                                    ev,
                                                                                    checked
                                                                                ) =>
                                                                                    handleOverrideChange(
                                                                                        idx +
                                                                                            1,
                                                                                        cat
                                                                                            .category
                                                                                            .id,

                                                                                        checked
                                                                                    )
                                                                                }
                                                                                checked={
                                                                                    cat
                                                                                        .category
                                                                                        .optional
                                                                                        ? overrides[
                                                                                              idx +
                                                                                                  1
                                                                                          ][
                                                                                              cat
                                                                                                  .category
                                                                                                  .id
                                                                                          ]
                                                                                              ?.checked
                                                                                        : fees?.months?.includes(
                                                                                              idx +
                                                                                                  1
                                                                                          )
                                                                                }
                                                                                disabled={
                                                                                    !cat
                                                                                        .category
                                                                                        .optional ||
                                                                                    !fees.months.includes(
                                                                                        idx +
                                                                                            1
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <>
                        {fees.apply || variant == "student" ? (
                            <Alert severity="warning" sx={{ width: "100%" }}>
                                <AlertTitle>
                                    Fee structure doesn't exist
                                </AlertTitle>
                                In order to apply fees, you must first{" "}
                                <Link
                                    style={{
                                        // textDecoration: "none",
                                        color: theme.palette.warning.light,
                                    }}
                                    to="/add-fee-structure"
                                >
                                    create a fee structure
                                </Link>
                            </Alert>
                        ) : null}
                    </>
                )}
            </Grid>

            <Grid item container xs={12}>
                {feeStructure && overrides && amounts && (
                    <ShowFees
                        feeStructure={feeStructure}
                        overrides={overrides}
                        amounts={amounts}
                        months={fees.months}
                        discounts={discounts}
                        globalDiscount={fees.discount}
                    />
                )}
            </Grid>

            {!fees.apply ? (
                <Alert severity="info" sx={{ width: "100%" }}>
                    <AlertTitle>
                        Please select "campus", "session" and "classroom" in
                        order to apply fee.
                    </AlertTitle>
                </Alert>
            ) : (
                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                        variant="outlined"
                        onClick={handleApplyFees}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {feedback.loading ? "Aplying Fees..." : "Apply Fees"}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export { ApplyFees as default };
