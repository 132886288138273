import { ClearAll } from "@mui/icons-material";
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import BaseAccordian from "../../../BaseAccordian";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { CertificateService } from "../../../services/CertificateService";
import AddView from "../../AddView";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { RUDTable } from "../../Table/RUDTable";
import AssignCertificate from "../AssignCertificate";
import { ShowUploadProfilePicture } from "../ShowUploadProfilePicture";
import PreviewCertificate from "./PreviewCertificate";

const initialFilter = {
    apply: 0,
    search: "",
    operator: "",
    baseUser: "",
    certificate: "",
};

const CertificateHistory = () => {
    const [filter, setFilter] = useState(initialFilter);
    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            apply: 0,
            search: "",
            operator: "",
            baseUser: "",
            certificate: "",
        });
    };

    const { user } = useContext(AppContext);
    const [changes, setChanges] = useState<any>();

    useEffect(() => {
        if (user && user.baseUser.type === "student") {
            setFilter((f) => ({
                ...f,
                baseUser: user.baseUser.id,
                apply: f.apply + 1,
            }));
        }
    }, [user?.id]);

    return (
        <Box p={2}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" color="primary" gutterBottom>
                    Certificate History
                </Typography>

                <MultiPermissionAuthorize ops={["CREATE CERTIFICATE_HISTORY"]}>
                    <AddView
                        label="Assign Certifcate"
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                    >
                        <AssignCertificate
                            onSuccess={() => {
                                setFilter((f) => ({
                                    ...f,
                                    apply: f.apply + 1,
                                }));
                            }}
                        />
                    </AddView>
                </MultiPermissionAuthorize>
            </div>

            <BaseAccordian>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/user"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    operator: c?.id || "",
                                })
                            }
                            label="Operator"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            processor={(opt) => ({
                                ...opt,
                                name: opt?.baseUser?.name,
                                id: opt?.baseUser?.id,
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/org/student"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    baseUser: c?.id || "",
                                })
                            }
                            label="Student"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                            processor={(opt) => ({
                                ...opt,
                                name: opt?.baseUser?.name,
                                id: opt?.baseUser?.id,
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Autocomplete
                            api="/certificate"
                            setOutput={(c: any) =>
                                setFilter({
                                    ...filter,
                                    certificate: c?.id || "",
                                })
                            }
                            label="Certifcate"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                setFilter({
                                    ...filter,
                                    apply: filter.apply + 1,
                                })
                            }
                            style={{ height: "100%" }}
                            size="small"
                        >
                            {" "}
                            Apply Filter
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                        <Tooltip title="Clear Filters">
                            <IconButton
                                onClick={handleFilterClear}
                                color="primary"
                            >
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </BaseAccordian>

            <RUDTable
                getter={(page, limit) =>
                    CertificateService.getCertificatesHistory(page, limit, {
                        search: filter.search || undefined,
                        baseUser: filter.baseUser || undefined,
                        certificate: filter.certificate || undefined,
                        operator: filter.operator || undefined,
                    })
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    issueDate: new Date(row.issueDate).toDateString(),
                    issuedTo:
                        row.baseUser.type === "faculty"
                            ? row.baseUser.name
                            : row.baseUser.name,
                    name: row.certificate.name,
                })}
                customCols={[
                    {
                        header: "Type",
                        content: (row) => (
                            <Chip
                                style={{ textTransform: "capitalize" }}
                                label={row.certificateStatus}
                                variant="outlined"
                                color={
                                    row.certificateStatus === "orignal"
                                        ? "primary"
                                        : "secondary"
                                }
                            />
                        ),
                    },
                    {
                        header: "Preview",
                        content: (row) => (
                            <PreviewCertificate data={row} key={row.id} />
                        ),
                    },

                    {
                        header: "Upload Image",
                        content: (row) => (
                            <ShowUploadProfilePicture row={row} />
                        ),
                    },
                ]}
                ops={{
                    read: "READ CERTIFICATE_HISTORY",
                }}
                readables={{
                    id: "ID",
                    name: "Cerificate Name",
                    issueDate: "Issuing Date",
                    operatorName: "Issued By",
                    issuedTo: "Issue To",
                    Type: "Type",
                    Preview: "Preview Certificate",
                }}
                updatingAgents={[filter.search, filter.apply]}
                storageKey="certificateHistory"
            />
        </Box>
    );
};

export default CertificateHistory;

// export const useChipStyles = makeStyles((theme) => ({
//     success: {
//         borderColor: theme.palette.success.main,
//         color: theme.palette.success.main,
//     },

//     warning: {
//         borderColor: theme.palette.warning.main,
//         color: theme.palette.warning.main,
//     },

//     primary: {
//         borderColor: theme.palette.primary.main,
//         color: theme.palette.primary.main,
//     },

//     error: {
//         borderColor: theme.palette.error.main,
//         color: theme.palette.error.main,
//     },
// }));
