import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GET } from "../../services/BaseService";

export const ShowSingleRole = ({ activeRoleId, setActiveRoleId }: any) => {
    const [status, setStatus] = useState({ status: "idle" });
    const [role, setRole] = useState<any>(null);
    const [search, setSearch] = useState("");
    const theme = useTheme();

    const getCompleteRole = async () => {
        setStatus({ status: "loading" });
        const [data, err] = await GET("/rolesystem/role", {
            id: activeRoleId,
        });

        if (data) {
            setStatus({ status: "success" });
            setRole(data.rows[0] ?? []);
        } else {
            setStatus({ status: "error" });
        }
    };

    useEffect(() => {
        if (activeRoleId) {
            getCompleteRole();
        }
    }, [activeRoleId]);

    return (
        <Dialog
            open={Boolean(activeRoleId)}
            onClose={() => setActiveRoleId(null)}
            fullWidth
        >
            <DialogTitle>{role?.name}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: ".5rem" }}>
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            label="Search through role ops"
                            onChange={(ev) => setSearch(ev.target.value)}
                        />
                    </Grid>

                    {status.status === "loading" && (
                        <Box
                            sx={{
                                width: "100%",
                                minHeight: "4rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <CircularProgress size="2rem" />
                        </Box>
                    )}

                    {status.status === "error" && (
                        <Box
                            sx={{
                                width: "100%",
                                minHeight: "4rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Alert severity="error" style={{ width: "100%" }}>
                                Failed to load the role
                            </Alert>
                        </Box>
                    )}

                    {role?.permissions
                        .map((p: any) => p.op)
                        .filter((op: any) =>
                            op.name.includes(search.toUpperCase())
                        )
                        .map((op: any, idx: number) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={idx}
                                sx={{
                                    cursor: "pointer",
                                    padding: "4px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        padding: theme.spacing(0.5),
                                        // justifyContent: "space-between",
                                        alignItems: "center",
                                        border: "1px solid",
                                        borderRadius: "4px",
                                        borderColor:
                                            theme.palette.action.disabled,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.action.selected,
                                            borderColor:
                                                theme.palette.action.selected,
                                        },
                                    }}
                                >
                                    <Typography variant="caption">
                                        {op.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setActiveRoleId(null)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
