import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class StudentService {
    static url = `${baseAPI}/org/student`;

    static async createStudent(data: any) {
        try {
            const response = await axios.post(StudentService.url, data, {
                withCredentials: true,
            });
            // console.log(data);

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudent(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(StudentService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentCategoryCount(page = 1, limit = 1000, filter = {}) {
        try {
            const response = await axios.get(
                StudentService.url + "/get-count-educationalCategories",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentByBaseuser(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                StudentService.url + "/student-by-baseUser",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentRecord(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(StudentService.url + "/record", {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentByUsername(username: string) {
        try {
            const response = await axios.get(
                StudentService.url + "/by-username",
                {
                    params: { username },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    // Soft Deleting of the Student
    static async deleteStudentSoft(id: string | number) {
        try {
            const response = await axios.delete(StudentService.url + "/soft", {
                params: { id },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async deleteStudentHard(id: string | number) {
        try {
            const response = await axios.delete(StudentService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editStudent(id: string, newStudent: any) {
        try {
            const response = await axios.patch(StudentService.url, newStudent, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getAddmissionStatus(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                StudentService.url + "/admission-statuses",
                {
                    withCredentials: true,
                    params: { page, limit, ...filter },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStatus(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(StudentService.url + "/statuses", {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async uploadImage(student: string | number) {
        try {
            const response = await axios.post(
                StudentService.url + "/image",
                { student },
                {
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentImages(student: string | number) {
        try {
            const response = await axios.get(StudentService.url + "/images", {
                withCredentials: true,
                params: { student },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async WithDrawStudent(data: any) {
        try {
            const response = await axios.post(
                StudentService.url + "/withdrawl",
                data,
                {
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeCampus(id: string, newCampus: any) {
        try {
            const response = await axios.patch(
                StudentService.url + "/campus",
                newCampus,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeCampusBulk(ids: any, newCampus: any) {
        try {
            const response = await axios.patch(
                StudentService.url + "/campus-bulk",
                newCampus,
                {
                    withCredentials: true,
                    params: { ids },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static studentCreationOptionalColumns = [
        "fileNo",
        "enrollmentNo",
        "slcNo",
        "studentCNIC",
        "fatherCNIC",
        "username",
        "password",
        "fatherMobile",
        "fatherWhatsapp",
        "currentAddress",
        "permanentAddress",
        "prePrimaryNo",
        "primaryNo",
        "middleNo",
        "highNo",
        "admissionStatus",
        "status",
        "educationalCategory",
        "reason",
        "remarks",
        "leavingDate",
        "coCarriculumActivities",
        "duesCleared",
        "securityRefunded",
        "slcTaken",
        "type",
    ];

    static studentCreationRequiredColumns = [
        "name",
        "fatherName",
        "dob",
        "gender",
        "admissionNumber",
        "admissionDate",
        "classroom",
        "section",
        "prePrimaryNo",
        "primaryNo",
        "middleNo",
        "highNo",
        "currentAddress",
        "fatherMobile",
    ];

    static async createBulkStudents(students: any[]) {
        const cleanedStudents = [];

        for (const student of students) {
            console.log(student, "student");
            const clean: any = {};

            for (const col of this.studentCreationRequiredColumns) {
                if (col === "admissionNumber") {
                    clean["fileNo"] = parseInt(student[col]);
                    clean["enrollmentNo"] = parseInt(student[col]);
                } else if (col === "admissionDate" || col === "dob") {
                    clean[col] = new Date(student[col]) || undefined;
                } else {
                    clean[col] = student[col];
                }
            }

            for (const col of this.studentCreationOptionalColumns) {
                clean[col] = clean[col] || student[col];
            }

            const studentFields = [];
            let index = 0;

            for (const col of student.customFields) {
                studentFields.push({
                    key: col,
                    type: "text",
                    value: student[col],
                });
                clean.customFields = studentFields;
                console.log(clean);

                index++;
            }

            clean["status"] = clean["status"] || "active";
            clean["admissionStatus"] = clean["admissionStatus"] || "confirmed";
            clean["username"] =
                student["username"] ||
                student["admissionNumber"] ||
                student["enrollmentNo"] ||
                student["fileNo"] ||
                Math.trunc(Math.random() * 1e9).toString();
            clean["password"] =
                student["password"] ||
                student["admissionNumber"] ||
                student["enrollmentNo"] ||
                student["fileNo"] ||
                Math.trunc(Math.random() * 1e9).toString();
            clean["campus"] = student["campus"];
            clean["session"] = student["session"];

            cleanedStudents.push(clean);
        }

        try {
            console.log("Clean data", cleanedStudents);
            const response = await axios.post(
                StudentService.url + "/bulk",
                cleanedStudents,
                {
                    withCredentials: true,
                    params: { variant: "student" },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log("Error creating bulk students", err);
            return [null, err.response?.data];
        }
    }

    static async getCountReport(campus: string, session: string) {
        try {
            const response = await axios.get(
                StudentService.url + "/reports/count-report",
                {
                    withCredentials: true,
                    params: { campus, session },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getWithdrawlRegister(filter: any) {
        try {
            const response = await axios.get(
                StudentService.url + "/withdrawl-register",
                {
                    withCredentials: true,
                    params: Object.fromEntries(
                        Object.entries(filter).filter(
                            ([key, value]: any[]) => value !== ""
                        )
                    ),
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data.message || notConnectedMessage];
        }
    }

    static async restoreStudent(student: string | number) {
        try {
            const response = await axios.delete(
                StudentService.url + "/withdrawl",
                {
                    params: { student },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
