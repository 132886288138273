import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { formatNumber } from "../../services/Hooks";

export const Fees = ({ feeData }: any) => {
    console.log(feeData);
    return (
        feeData && (
            <Grid item container xs={12} spacing={2} alignItems="center">
                <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card elevation={0}>
                            <CardHeader subheader="Applied Fees" />
                            <CardContent>
                                <Typography variant="h4" color="primary">
                                    {formatNumber(feeData.fees.applied)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card elevation={0}>
                            <CardHeader subheader="Collected Fees" />
                            <CardContent>
                                <Typography variant="h4" color="primary">
                                    {formatNumber(feeData.fees.collected)}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Card elevation={0}>
                            <CardHeader subheader="Dues" />
                            <CardContent>
                                <Typography variant="h4" color="primary">
                                    {formatNumber(
                                        feeData.fees.applied -
                                            feeData.fees.collected
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        )
    );
};
