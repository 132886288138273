import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class FeeStructureService {
    static url = `${baseAPI}/org/fees/structure`;

    static async createFeeStructure(data: any) {
        try {
            const response = await axios.post(FeeStructureService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                FeeStructureService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getFeeStructure(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(FeeStructureService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteFeeStructureSoft(id: string | number) {
        try {
            const response = await axios.delete(
                FeeStructureService.url + "/soft",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteFeeStructureHard(id: string | number) {
        try {
            const response = await axios.delete(
                FeeStructureService.url + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editFeeStructure(id: string, newFeeStructure: any) {
        try {
            const response = await axios.patch(
                FeeStructureService.url,
                newFeeStructure,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
