import { Add, Remove } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    TextField,
} from "@mui/material";
import {
    ChangeEvent,
    FormEvent,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import { POST } from "../../../services/BaseService";
import { AddViewContext } from "../../AddView";
import { AppContext } from "../../AppContext";

type Fields = {
    [id: number]: {
        key: string;
        type: "text" | "image";
    };
};

type Props = {
    name: "campus" | "student";
};
const AddCustomFields = ({ name }: Props) => {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);
    const { feedback, setFeedback } = useContext(AppContext);
    const [component, setComponent] = useState({ name: "" });
    const [filter, setFilter] = useState({ campus: "" });
    const [fields, setFields] = useState<Fields>({
        0: {
            key: "",
            type: "text",
        },
    });

    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        id: number
    ) => {
        setFields({
            ...fields,
            [id]: { ...fields[id], [e.target.name]: e.target.value },
        });
    };

    const handleRow = (variant: string, rowId = 0) => {
        if (variant === "add") {
            const newEntryId =
                parseInt(
                    Object.keys(fields)[Object.entries(fields).length - 1]
                ) + 1;

            setFields({ ...fields, [newEntryId]: { key: "", type: "text" } });
        } else if (variant === "remove") {
            const updatedStock = Object.fromEntries(
                Object.entries(fields).filter(
                    ([id, stock]) => parseInt(id) !== rowId
                )
            );

            setFields(updatedStock);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const customFieldsComponent = {
            name,
            field: Object.values(fields),
        };

        const [data, err] = await POST(
            "/customfield/template-component",
            customFieldsComponent
        );

        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                show: true,
                severity: "success",
            });
            const timer = setTimeout(() => {
                setOpen(false);
                triggerRowsRefetch();
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            setFeedback({
                loading: false,
                message: err,
                show: true,
                severity: "error",
            });
            const timer = setTimeout(() => {
                setOpen(false);
                triggerRowsRefetch();
            }, 1000);

            return () => clearTimeout(timer);
        }
    };

    useEffect(() => {
        return () => {
            setFeedback({
                message: "",
                loading: false,
                severity: "warning",
                show: false,
            });
        };
    }, []);

    return (
        <Box>
            <Grid
                container
                spacing={1.5}
                mt={1}
                component="form"
                onSubmit={handleSubmit}
            >
                {feedback.show && (
                    <Grid item xs={12} mb={1.5}>
                        <Alert security={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    </Grid>
                )}

                {Object.entries(fields).map(
                    ([id, field]: any, count: number) => (
                        <Fragment key={id}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label="Label"
                                    fullWidth
                                    required
                                    size="small"
                                    name="key"
                                    onChange={(e) => handleChange(e, id)}
                                />
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <TextField
                                    label="Type"
                                    required
                                    name="type"
                                    select
                                    value={field.type}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    size="small"
                                    onChange={(e) => handleChange(e, id)}
                                >
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="image">Image</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={1}>
                                {parseInt(id) === 0 ? (
                                    <IconButton
                                        onClick={() => handleRow("add")}
                                        disabled={
                                            Object.values(fields).length >= 9
                                        }
                                    >
                                        <Add />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            handleRow("remove", parseInt(id))
                                        }
                                    >
                                        <Remove />
                                    </IconButton>
                                )}
                            </Grid>
                        </Fragment>
                    )
                )}

                <Grid item mt={1}>
                    <Button
                        variant="outlined"
                        type="submit"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1rem" />
                            ) : null
                        }
                    >
                        {feedback.loading ? "adding fields..." : "add fields"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddCustomFields;
