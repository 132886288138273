import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class LogService {
    static url = `${baseAPI}/logs`;

    static async getLog(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(LogService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
