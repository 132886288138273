// import {
//     Alert,
//     createTheme,
//     Divider,
//     Grid,
//     Paper,
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableRow,
//     ThemeProvider,
//     Typography,
// } from "@mui/material";
// import { useContext, useEffect, useRef, useState } from "react";
// import { FeeDiscountService } from "../../../services/FeeDiscountService";
// import { FeeService } from "../../../services/FeeService";
// import { FeeStructureService } from "../../../services/FeeStructureService";
// import { S3Service } from "../../../services/S3Service";
// import { StudentService } from "../../../services/StudentService";
// import { lightThemeOpts } from "../../../theme/Light";
// import { AppContext } from "../../AppContext";
// import { awsDirStruct, basePath } from "../../constants";
// import PrintTable from "../../Export/PrintTable";
// import { Header } from "../../Settings/Header/Header";
// import { DetailsEntire } from "./DetailsEntire";
// import { DetailsPerMonth } from "./DetailsPerMonth";

// type ReceiptCopy = {
//     name: string;
// };

// type Props = {
//     student: any;
//     session: any;
//     classroom: any;
//     includeHeader?: boolean;
//     copies: ReceiptCopy[];
//     months: any[];
//     recordPerMonth?: boolean;
//     enablePrint?: boolean;
// };

// const studentInfo = [
//     { label: "Name", property: "fullName" },
//     { label: "Father name", property: "fatherName" },
//     { label: "File no.", property: "fileNo" },
//     { label: "Enroll no.", property: "enrollmentNo" },
//     { label: "Classroom", property: "fullClassroom" },
// ];

// export const Receipt = ({
//     student,
//     session,
//     classroom,
//     includeHeader = false,
//     enablePrint = false,
//     ...props
// }: Props) => {
//     const [studentImageSrc, setStudentImageSrc] = useState("");
//     const printRef = useRef<any>();
//     const [printMode, setPrintMode] = useState(false);
//     const [discounts, setDiscounts] = useState<any[]>([]);
//     const [collections, setCollections] = useState<any[]>([]);
//     const [fees, setFees] = useState<any[]>([]);
//     const [feeStructure, setFeeStructure] = useState<any | null>(null);
//     const [feedback, setFeedback] = useState({
//         loading: false,
//         show: false,
//         severity: "success",
//         message: "",
//     });
//     const { theme } = useContext(AppContext);

//     const getStudentFeeStructure = async () => {
//         if (student) {
//             const [feeStructureData, feeStructureError] =
//                 await FeeStructureService.getFeeStructure(1, 1, {
//                     classroom: classroom.id,
//                     session: session.id,
//                 });

//             if (feeStructureData && feeStructureData.rows.length > 0) {
//                 setFeeStructure(feeStructureData.rows[0]);
//             } else {
//                 setFeedback({
//                     ...feedback,
//                     severity: "error",
//                     show: true,
//                     message: "The student's classroom has no fee strucutre",
//                 });
//             }
//         } else {
//             setFeedback({
//                 ...feedback,
//                 severity: "error",
//                 message: "Cannot find the student provided",
//                 show: true,
//             });
//         }
//     };

//     const getStudentDiscounts = async () => {
//         let _discounts: any[] = [];

//         const [classroomDiscountsData, _] =
//             await FeeDiscountService.getFeeDiscount(1, 100, {
//                 discountType: "classroom",
//                 targetId: student.classroom.id,
//             });

//         if (classroomDiscountsData) {
//             _discounts = [..._discounts, ...classroomDiscountsData.rows];
//         }

//         const [studentDiscountsData, __] =
//             await FeeDiscountService.getFeeDiscount(1, 100, {
//                 discountType: "student",
//                 targetId: student.id,
//             });

//         if (studentDiscountsData) {
//             _discounts = [..._discounts, ...studentDiscountsData.rows];
//         }
//         setDiscounts(_discounts);
//     };

//     const getStudentFees = async () => {
//         const otherOps = JSON.stringify([
//             {
//                 op: "in",
//                 isDate: false,
//                 col: "month",
//                 operands: [props.months.map((m: any) => m.monthNumber)],
//             },
//         ]);
//         const [data, err] = await FeeService.getFees(1, 0, {
//             student: student!.id,
//             session: session.id,
//             classroom: classroom.id,
//             months: props.months.map((m: any) => m.monthNumber),
//         });

//         if (data) {
//             setFees(data.rows);
//         }
//     };

//     const getCollections = async () => {
//         const otherOps = JSON.stringify([
//             {
//                 op: "in",
//                 isDate: false,
//                 col: "fee",
//                 operands: [fees.map((fee: any) => fee.id)],
//             },
//         ]);
//         const [data, err] = await FeeService.getCollections(1, 0, { otherOps });

//         if (data) {
//             setCollections(data.rows);
//         }
//     };

//     const getStudentImage = async () => {
//         const studentImagesSrcs = [];
//         const [studentImagesData, studentImagesErr] =
//             await StudentService.getStudentImages(student.id);

//         if (studentImagesData) {
//             for (const image of studentImagesData.images) {
//                 const key = `${awsDirStruct.profile.picture}/${image.id}`;
//                 const [data, err] = await S3Service.getS3ByKey(key);

//                 if (data) {
//                     studentImagesSrcs.push(data.url);
//                 }
//             }

//             if (studentImagesSrcs.length > 0) {
//                 setStudentImageSrc(studentImagesSrcs.slice(-1)[0]);
//             }
//         }
//     };

//     useEffect(() => {
//         getStudentFeeStructure();
//     }, []);

//     useEffect(() => {
//         if (student && feeStructure) {
//             getStudentFees();
//             getStudentDiscounts();
//             getStudentImage();
//         }
//     }, [student, feeStructure, props.months]);

//     useEffect(() => {
//         if (fees.length > 0) {
//             getCollections();
//         }
//     }, [fees]);

//     return student && feeStructure && fees && discounts ? (
//         <ThemeProvider
//             theme={
//                 // printMode
//                 createTheme({
//                     ...lightThemeOpts,
//                     palette: {
//                         ...lightThemeOpts.palette,
//                         background: {
//                             ...lightThemeOpts.palette?.background,
//                             default: "#fff",
//                         },
//                     },
//                 })
//                 // : theme
//             }
//         >
//             <Paper
//                 elevation={0}
//                 sx={{ bgcolor: "background.default" }}
//                 ref={printRef}
//                 square
//             >
//                 <Grid container>
//                     {enablePrint && (
//                         <Grid item xs={12}>
//                             <PrintTable
//                                 componentRef={printRef}
//                                 setPrintMode={setPrintMode}
//                                 sx={{
//                                     visibility: printMode
//                                         ? "hidden"
//                                         : "visible",
//                                 }}
//                             />
//                         </Grid>
//                     )}

//                     {props.copies.map((copy: any, idx) => (
//                         <>
//                             <Grid
//                                 container
//                                 item
//                                 xs={12 / props.copies.length}
//                                 sx={{
//                                     overflow: "visible",
//                                     borderLeft: idx === 0 ? "" : "1px dashed",
//                                     borderTop: "1px dashed",
//                                     // p: 0,
//                                     p: 2,
//                                     borderColor: "contrastText",
//                                 }}
//                                 key={idx}
//                             >
//                                 <Grid item xs={12} mb={3}>
//                                     <Typography
//                                         gutterBottom
//                                         variant="body2"
//                                         pl={2}
//                                     >
//                                         {copy.name}
//                                     </Typography>
//                                     <Divider />
//                                 </Grid>

//                                 <Grid item xs={12}>
//                                     <Header
//                                         _campus={student.campus.id}
//                                         leftImage={`${basePath}/images/icon.png`}
//                                         rightImage={studentImageSrc}
//                                     />
//                                 </Grid>

//                                 <Grid item xs={12}>
//                                     <TableContainer>
//                                         <Table size="small">
//                                             <TableBody>
//                                                 {studentInfo.map(
//                                                     (info, idx) => (
//                                                         <TableRow key={idx}>
//                                                             <TableCell width="35%">
//                                                                 {info.label}
//                                                             </TableCell>
//                                                             <TableCell>
//                                                                 {
//                                                                     student[
//                                                                         info
//                                                                             .property
//                                                                     ]
//                                                                 }
//                                                             </TableCell>
//                                                         </TableRow>
//                                                     )
//                                                 )}
//                                                 <TableRow>
//                                                     <TableCell>
//                                                         Due Date
//                                                     </TableCell>
//                                                     <TableCell>
//                                                         {fees.length > 0
//                                                             ? fees
//                                                                   .map(
//                                                                       (fee) =>
//                                                                           new Date(
//                                                                               fee.dueDate
//                                                                           )
//                                                                   )
//                                                                   .sort(
//                                                                       (a, b) =>
//                                                                           a.getTime() -
//                                                                           b.getTime()
//                                                                   )[0]
//                                                                   .toISOString()
//                                                                   .split("T")[0]
//                                                             : "-"}
//                                                     </TableCell>
//                                                 </TableRow>
//                                                 <TableRow>
//                                                     <TableCell>
//                                                         Months
//                                                     </TableCell>
//                                                     <TableCell>
//                                                         {props.months
//                                                             .sort(
//                                                                 (a, b) =>
//                                                                     a.monthNumber -
//                                                                     b.monthNumber
//                                                             )
//                                                             .map(
//                                                                 (month) =>
//                                                                     month.month
//                                                             )

//                                                             .join(", ")}
//                                                     </TableCell>
//                                                 </TableRow>
//                                             </TableBody>
//                                         </Table>
//                                     </TableContainer>
//                                 </Grid>

//                                 <Grid item display="flex" xs={12} m={2}></Grid>

//                                 <Grid item xs={12}>
//                                     {props.recordPerMonth ? (
//                                         <DetailsPerMonth
//                                             student={student}
//                                             feeStructure={feeStructure}
//                                             months={props.months}
//                                             fees={fees}
//                                             discounts={discounts}
//                                             collections={collections}
//                                         />
//                                     ) : (
//                                         <DetailsEntire
//                                             student={student}
//                                             feeStructure={feeStructure}
//                                             months={props.months}
//                                             fees={fees}
//                                             discounts={discounts}
//                                             collections={collections}
//                                         />
//                                     )}
//                                 </Grid>

//                                 <Grid
//                                     item
//                                     xs={12}
//                                     display="flex"
//                                     justifyContent="space-between"
//                                     mt={10}
//                                 >
//                                     <Typography
//                                         borderColor="contrastText"
//                                         borderTop="1px solid"
//                                         variant="body2"
//                                         width="20ch"
//                                         pt={0.5}
//                                     >
//                                         Date
//                                     </Typography>
//                                     <Typography
//                                         borderColor="contrastText"
//                                         borderTop="1px solid"
//                                         variant="body2"
//                                         width="20ch"
//                                         pt={0.5}
//                                     >
//                                         Accountant
//                                     </Typography>
//                                 </Grid>
//                             </Grid>
//                         </>
//                     ))}
//                 </Grid>
//             </Paper>
//         </ThemeProvider>
//     ) : (
//         <Alert sx={{ m: 2 }} severity="info">
//             {`No fees to show against ${student.baseUser.name} in ${classroom.name}, ${session.name}`}
//         </Alert>
//     );
// };

import {
    Alert,
    createTheme,
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { FeeDiscountService } from "../../../services/FeeDiscountService";
import { FeeService } from "../../../services/FeeService";
import { FeeStructureService } from "../../../services/FeeStructureService";
import { S3Service } from "../../../services/S3Service";
import { StudentService } from "../../../services/StudentService";
import { lightThemeOpts } from "../../../theme/Light";
import { AppContext } from "../../AppContext";
import { awsDirStruct, basePath } from "../../constants";
import PrintTable from "../../Export/PrintTable";
import { Header } from "../../Settings/Header/Header";
import { DetailsEntire } from "./DetailsEntire";
import { DetailsPerMonth } from "./DetailsPerMonth";

type ReceiptCopy = {
    name: string;
};

type Props = {
    student: any;
    session: any;
    classroom: any;
    includeHeader?: boolean;
    copies: ReceiptCopy[];
    months: any[];
    recordPerMonth?: boolean;
    enablePrint?: boolean;
};

const studentInfo = [
    { label: "Name", property: "fullName" },
    { label: "Father name", property: "fatherName" },
    { label: "File no.", property: "fileNo" },
    { label: "Enroll no.", property: "enrollmentNo" },
    { label: "Classroom", property: "fullClassroom" },
];

export const Receipt = ({
    student,
    session,
    classroom,
    includeHeader = false,
    enablePrint = false,
    ...props
}: Props) => {
    const [studentImageSrc, setStudentImageSrc] = useState("");
    const printRef = useRef<any>();
    const [printMode, setPrintMode] = useState(false);
    const [discounts, setDiscounts] = useState<any[]>([]);
    const [collections, setCollections] = useState<any[]>([]);
    const [fees, setFees] = useState<any[]>([]);
    const [feeStructure, setFeeStructure] = useState<any | null>(null);
    const [feedback, setFeedback] = useState({
        loading: false,
        show: false,
        severity: "success",
        message: "",
    });
    const { theme } = useContext(AppContext);

    const getStudentFeeStructure = async () => {
        if (student) {
            const [feeStructureData, feeStructureError] =
                await FeeStructureService.getFeeStructure(1, 1, {
                    classroom: classroom.id,
                    session: session.id,
                });

            if (feeStructureData && feeStructureData.rows.length > 0) {
                setFeeStructure(feeStructureData.rows[0]);
            } else {
                setFeedback({
                    ...feedback,
                    severity: "error",
                    show: true,
                    message: "The student's classroom has no fee strucutre",
                });
            }
        } else {
            setFeedback({
                ...feedback,
                severity: "error",
                message: "Cannot find the student provided",
                show: true,
            });
        }
    };

    const getStudentDiscounts = async () => {
        let _discounts: any[] = [];

        const [classroomDiscountsData, _] =
            await FeeDiscountService.getFeeDiscount(1, 100, {
                discountType: "classroom",
                targetId: student.classroom.id,
            });

        if (classroomDiscountsData) {
            _discounts = [..._discounts, ...classroomDiscountsData.rows];
        }

        const [studentDiscountsData, __] =
            await FeeDiscountService.getFeeDiscount(1, 100, {
                discountType: "student",
                targetId: student.id,
            });

        if (studentDiscountsData) {
            _discounts = [..._discounts, ...studentDiscountsData.rows];
        }
        setDiscounts(_discounts);
    };

    const getStudentFees = async () => {
        const otherOps = JSON.stringify([
            {
                op: "in",
                isDate: false,
                col: "month",
                operands: [props.months.map((m: any) => m.monthNumber)],
            },
        ]);
        const [data, err] = await FeeService.getFees(1, 0, {
            student: student!.id,
            session: session.id,
            classroom: classroom.id,
            months: props.months.map((m: any) => m.monthNumber),
        });

        if (data) {
            setFees(data.rows);
        }
    };

    const getCollections = async () => {
        const otherOps = JSON.stringify([
            {
                op: "in",
                isDate: false,
                col: "fee",
                operands: [fees.map((fee: any) => fee.id)],
            },
        ]);
        const [data, err] = await FeeService.getCollections(1, 0, { otherOps });

        if (data) {
            setCollections(data.rows);
        }
    };

    const getStudentImage = async () => {
        const studentImagesSrcs = [];
        const [studentImagesData, studentImagesErr] =
            await StudentService.getStudentImages(student.id);

        if (studentImagesData) {
            for (const image of studentImagesData.images) {
                const key = `${awsDirStruct.profile.picture}/${image.id}`;
                const [data, err] = await S3Service.getS3ByKey(key);

                if (data) {
                    studentImagesSrcs.push(data.url);
                }
            }

            if (studentImagesSrcs.length > 0) {
                setStudentImageSrc(studentImagesSrcs.slice(-1)[0]);
            }
        }
    };

    useEffect(() => {
        getStudentFeeStructure();
    }, []);

    useEffect(() => {
        if (student && feeStructure) {
            getStudentFees();
            getStudentDiscounts();
            getStudentImage();
        }
    }, [student, feeStructure, props.months]);

    useEffect(() => {
        if (fees.length > 0) {
            getCollections();
        }
    }, [fees]);

    return student && feeStructure && fees && discounts ? (
        <ThemeProvider
            theme={
                // printMode
                createTheme({
                    ...lightThemeOpts,
                    palette: {
                        ...lightThemeOpts.palette,
                        background: {
                            ...lightThemeOpts.palette?.background,
                            default: "#fff",
                        },
                    },
                })
                // : theme
            }
        >
            <Paper
                elevation={0}
                sx={{ bgcolor: "background.default" }}
                ref={printRef}
                square
            >
                <Grid container>
                    {enablePrint && (
                        <Grid item xs={12}>
                            <PrintTable
                                componentRef={printRef}
                                setPrintMode={setPrintMode}
                                sx={{
                                    visibility: printMode
                                        ? "hidden"
                                        : "visible",
                                }}
                            />
                        </Grid>
                    )}

                    {props.copies.map((copy: any, idx) => (
                        <>
                            <Grid
                                container
                                item
                                xs={12 / props.copies.length}
                                sx={{
                                    overflow: "visible",
                                    borderLeft: idx === 0 ? "" : "1px dashed",
                                    borderTop: "1px dashed",
                                    // p: 0,
                                    p: 2,
                                    borderColor: "contrastText",
                                }}
                                key={idx}
                            >
                                <Grid item xs={12} mb={3}>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        pl={2}
                                    >
                                        {copy.name}
                                    </Typography>
                                    <Divider />
                                </Grid>

                                <Grid item xs={12}>
                                    <Header
                                        _campus={student.campus.id}
                                        leftImage={`${basePath}/images/icon.png`}
                                        rightImage={studentImageSrc}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {studentInfo.map(
                                                    (info, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell width="35%">
                                                                {info.label}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    student[
                                                                        info
                                                                            .property
                                                                    ]
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                                <TableRow>
                                                    <TableCell>
                                                        Due Date
                                                    </TableCell>
                                                    <TableCell>
                                                        {fees.length > 0
                                                            ? fees
                                                                  .map(
                                                                      (fee) =>
                                                                          new Date(
                                                                              fee.dueDate
                                                                          )
                                                                  )
                                                                  .sort(
                                                                      (a, b) =>
                                                                          a.getTime() -
                                                                          b.getTime()
                                                                  )[0]
                                                                  .toISOString()
                                                                  .split("T")[0]
                                                            : "-"}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        Months
                                                    </TableCell>
                                                    <TableCell>
                                                        {props.months
                                                            .sort(
                                                                (a, b) =>
                                                                    a.monthNumber -
                                                                    b.monthNumber
                                                            )
                                                            .map(
                                                                (month) =>
                                                                    month.month
                                                            )

                                                            .join(", ")}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item display="flex" xs={12} m={2}></Grid>

                                <Grid item xs={12}>
                                    {props.recordPerMonth ? (
                                        <DetailsPerMonth
                                            student={student}
                                            feeStructure={feeStructure}
                                            months={props.months}
                                            fees={fees}
                                            discounts={discounts}
                                            collections={collections}
                                        />
                                    ) : (
                                        <DetailsEntire
                                            student={student}
                                            feeStructure={feeStructure}
                                            months={props.months}
                                            fees={fees}
                                            discounts={discounts}
                                            collections={collections}
                                        />
                                    )}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="space-between"
                                    mt={10}
                                >
                                    <Typography
                                        borderColor="contrastText"
                                        borderTop="1px solid"
                                        variant="body2"
                                        width="20ch"
                                        pt={0.5}
                                    >
                                        Date
                                    </Typography>
                                    <Typography
                                        borderColor="contrastText"
                                        borderTop="1px solid"
                                        variant="body2"
                                        width="20ch"
                                        pt={0.5}
                                    >
                                        Accountant
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Paper>
        </ThemeProvider>
    ) : (
        <Alert sx={{ m: 2 }} severity="info">
            {`No fees to show against ${student.baseUser.name} in ${classroom.name}, ${session.name}`}
        </Alert>
    );
};
