import { createTheme, ThemeOptions } from "@mui/material";
import { grey } from "@mui/material/colors";

export const lightThemeOpts: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#ffc226",
        },
        background: {
            paper: "#fff",
            default: grey["100"],
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlinedPrimary: {
                    border: "2px solid",
                    "&:hover": {
                        border: "2px solid",
                    },
                },
            },
        },
    },
};

export const LightTheme = createTheme(lightThemeOpts);
