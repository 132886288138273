import { Add } from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    Box,
    Container,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

type FormTemplatesProps = {
    creationViewLink: string;
    buttonLabel: string;
    locationState?: any;
};

export const CreationViewBtn = ({
    creationViewLink: url,
    buttonLabel,
    locationState = {},
}: FormTemplatesProps) => {
    const theme = useTheme();

    return (
        <Accordion>
            <AccordionSummary>
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid item md={6} lg={2} style={{ flex: 1 }}>
                            <Link
                                to={{ pathname: url, state: locationState }}
                                // className={classes["add-form-btn"]}
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    flex: 1,
                                    display: "inline-flex",
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: theme.spacing(20),
                                    borderRadius: theme.shape.borderRadius,
                                    textDecoration: "none",
                                    color: "inherit",
                                    textAlign: "center",
                                }}
                            >
                                <Box>
                                    <IconButton>
                                        <Add fontSize="large" />
                                    </IconButton>
                                    <Typography>{buttonLabel}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </AccordionSummary>
        </Accordion>
    );
};

// const useStyles = makeStyles((theme) => ({
//     "add-form-btn": {
//         border: `1px solid ${theme.palette.divider}`,
//         flex: 1,
//         display: "inline-flex",
//         width: "100%",
//         height: "100%",
//         justifyContent: "center",
//         alignItems: "center",
//         minHeight: theme.spacing(20),
//         borderRadius: theme.shape.borderRadius,
//         textDecoration: "none",
//         color: "inherit",
//         textAlign: "center",
//     },
// }));
