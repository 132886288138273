import { MenuBook } from "@mui/icons-material";
import { IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";

export const ShowBookPdf = ({ row }: any) => {
    const [src, setSrc] = useState("");
    const [open, setOpen] = useState(false);
    const [fetchImage, setFetch] = useState(false);
    const [imageError, setImageError] = useState(false);

    const getImage = async () => {
        const key = `${awsDirStruct.library.bookPdfs}/${row.id}`;
        const [data, err] = await S3Service.getS3ByKey(key);

        if (data) {
            setSrc(data.url);
            setImageError(false); // Reset error if the image is found
        } else {
            setImageError(true); // Set error if fetching fails
        }
    };

    useEffect(() => {
        if (fetchImage) {
            getImage();
        }
    }, [fetchImage]);

    return (
        <>
            <IconButton
                onClick={() => {
                    setOpen(true);
                    setFetch(true);
                }}
                size="small"
            >
                <MenuBook fontSize="small" />
            </IconButton>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                sx={{ height: "90vh", width: "90%", margin: "auto" }}
            >
                {imageError || !src ? (
                    <Typography
                        variant="h6"
                        sx={{ textAlign: "center", paddingTop: "40vh" }}
                    >
                        No PDF found for this book.
                    </Typography>
                ) : (
                    <iframe
                        src={src}
                        style={{ width: "100%", height: "100%", border: 0 }}
                        onError={() => setImageError(true)} // Handle image load errors
                    ></iframe>
                )}
            </Modal>
        </>
    );
};
