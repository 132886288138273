import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FinanceService } from "../../services/BankService";
import { AddViewContext } from "../AddView";
import { AppContext } from "../AppContext";
import { baseAPI } from "../constants";
import SearchableInput from "../SearchableInput";
type Props = {
    row?: any;
    updateMode?: boolean;
};

const AddBank = ({ row, updateMode }: Props) => {
    const { user } = useContext(AppContext);
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const [bank, setBank] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
        name: "",
        branchName: "",
        type: "",
        branchCode: "",
        iban: "",
    });
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus.id,
    });
    const { feedback, setFeedback } = useContext(AppContext);
    const [accountType, setAccountType] = useState([]);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setBank({ ...bank, [ev.target.name]: value });
    };

    const getAccountTypes = async () => {
        const [data, err] = await FinanceService.getBankTypes();
        setAccountType(data.rows);
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        if (!updateMode) {
            const [data, err] = await FinanceService.createBank({
                ...bank,
                campus: filter.campus,
            });
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 3000);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        } else {
            const [data, err] = await FinanceService.editBank(row.id, {
                ...bank,
                campus: filter.campus,
            });

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 3000);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        getAccountTypes();

        return () =>
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
    }, []);

    useEffect(() => {
        if (updateMode) {
            setBank(() => ({
                campus: row?.campusId,
                name: row.name,
                branchName: row.branchName,
                type: row.type,
                branchCode: row?.branchCode,
                iban: row?.iban,
            }));

            setFilter({ campus: row?.campusId });
        }
    }, [updateMode]);

    useEffect(() => {
        if (bank.type === "other") {
            setBank({ ...bank, iban: "-", branchName: "-", branchCode: "-" });
        }
    }, [bank.type]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} lg={12}>
                        {feedback.show && (
                            <Alert severity={feedback.severity}>
                                {feedback.message}
                            </Alert>
                        )}
                    </Grid>
                    {user?.baseUser?.userRegions?.length > 1 && (
                        <Grid item xs={12}>
                            <SearchableInput
                                api={`${baseAPI}/org/campus`}
                                label="Campus"
                                _name="campus"
                                filter={filter}
                                setFilter={setFilter}
                                defaultValue={
                                    updateMode ? row?.campus : undefined
                                }
                                required
                            />
                        </Grid>
                    )}{" "}
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            select
                            fullWidth
                            label="Bank type"
                            name="type"
                            value={bank.type}
                            onChange={handleChange}
                        >
                            {accountType.map((at: any, idx) => (
                                <MenuItem key={at.type} value={at.type}>
                                    {at.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Bank Name"
                            name="name"
                            value={bank.name}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    {bank.type === "bank" && (
                        <>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Branch Name"
                                    name="branchName"
                                    value={bank.branchName}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Branch Code"
                                    name="branchCode"
                                    value={bank.branchCode}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="IBAN"
                                    name="iban"
                                    value={bank.iban}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} mt={1}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1em" />
                                ) : null
                            }
                        >
                            {!updateMode
                                ? feedback.loading
                                    ? "Adding bank..."
                                    : "Add bank"
                                : feedback.loading
                                ? "updating bank..."
                                : "update bank"}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default AddBank;
