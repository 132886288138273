import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SettingsService {
    static url = `${baseAPI}/settings`;

    static async getSetting(key: string): Promise<[null | any, null | string]> {
        try {
            const response = await axios.get(SettingsService.url, {
                withCredentials: true,
                params: { key },
            });
            return [response.data, null];
        } catch (err: any) {
            return [
                null,
                err?.response?.data?.message?.message || notConnectedMessage,
            ];
        }
    }

    static async getFeeSettings(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(SettingsService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async updateSettings(updatedSettings: any) {
        try {
            const response = await axios.patch(
                SettingsService.url + "/bulk",
                updatedSettings,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
