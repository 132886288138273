import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SettingsService } from "../../../services/SettingsService";

type Props = {
    student: any;
    feeStructure: any;
    fees: any[];
    months: any[];
    discounts: any[];
    collections: any[];
};

export type FeesPerCatPerMonth = {
    [month: number]: {
        [category: string]: { original: number; discount: number };
    };
};

export const useCalculateFeePerCategoryPerMonth = (
    fees: any[],
    feeStructure: any,
    discounts: any[]
): [FeesPerCatPerMonth, { original: number; discount: number }] => {
    const [feesPerCatPerMonth, setFeesPerCatPerMonth] =
        useState<FeesPerCatPerMonth>({});
    const [totals, setTotals] = useState({ original: 0, discount: 0 });

    useEffect(() => {
        if (feeStructure && fees && discounts) {
            let _total = 0,
                _discount = 0,
                _feesPerCatPerMonth: any = {};

            for (const singleMonthFee of fees) {
                const _feesPerCat: any = {};
                _discount += singleMonthFee.discount;

                for (const cat of feeStructure.categories) {
                    let amount = 0;
                    let discountAmount = 0;

                    const feeOverride = singleMonthFee.overrides.find(
                        (ovr: any) => ovr.category.id === cat.category.id
                    );

                    if (feeOverride) {
                        if (feeOverride.checked) {
                            amount = feeOverride.amount;
                        } else {
                            amount = 0;
                        }
                    } else {
                        amount = cat.amount;
                    }

                    const catDiscounts = discounts.filter(
                        (discount: any) =>
                            discount.category.id === cat.category.id
                    );

                    for (const catDiscount of catDiscounts) {
                        if (catDiscount.pct) {
                            discountAmount +=
                                cat.amount * (catDiscount.pct / 100);
                        } else {
                            discountAmount += catDiscount.amount;
                        }
                    }

                    _feesPerCat[cat.category.id] = {
                        original: amount,
                        discount: discountAmount,
                    };
                    _discount += discountAmount;
                }

                _feesPerCatPerMonth = {
                    ..._feesPerCatPerMonth,
                    [singleMonthFee.month]: _feesPerCat,
                };
                _total += singleMonthFee.totalAmount;
            }

            setFeesPerCatPerMonth(_feesPerCatPerMonth);
            setTotals({ original: _total, discount: _discount });
        }
    }, [feeStructure, fees, discounts]);

    return [feesPerCatPerMonth, totals];
};

const useReduceFeesToPerCat = (feesPerCatPerMonth: FeesPerCatPerMonth) => {
    const [feesPerCat, setFeesPerCat] = useState<{
        [category: string]: { original: number; discount: number };
    }>({});

    useEffect(() => {
        const _feesPerCat: any = {};

        if (feesPerCatPerMonth) {
            for (const [month, feesPerCat] of Object.entries(
                feesPerCatPerMonth
            )) {
                for (const [cat, { original, discount }] of Object.entries(
                    feesPerCat
                )) {
                    if (Object.keys(_feesPerCat).includes(cat)) {
                        _feesPerCat[cat].original += original;
                        _feesPerCat[cat].discount += discount;
                    } else {
                        _feesPerCat[cat] = { original, discount };
                    }
                }
            }

            setFeesPerCat(_feesPerCat);
        }
    }, [feesPerCatPerMonth]);

    return feesPerCat;
};

export const useGetSurcharge = () => {
    const [surcharge, setSurcharge] = useState<any>(null);
    const getSurchage = async () => {
        const [data, err] = await SettingsService.getSetting("late-fee-fine");

        if (data && data.rows.length > 0) {
            setSurcharge(data.rows[0]);
        }
    };

    useEffect(() => {
        getSurchage();
    }, []);

    return surcharge;
};

export const DetailsEntire = ({
    student,
    feeStructure,
    fees,
    months,
    discounts,
    collections,
}: Props) => {
    const [feesPerCatPerMonth, totals] = useCalculateFeePerCategoryPerMonth(
        fees,
        feeStructure,
        discounts
    );
    const feesPerCat = useReduceFeesToPerCat(feesPerCatPerMonth);
    const surcharge = useGetSurcharge();

    const totalCollection = useMemo(
        () =>
            collections.reduce(
                (prev, curr) => prev + curr.collected + curr.lateFeeFine,
                0
            ),
        [collections]
    );

    return (
        <TableContainer sx={{ mt: 2 }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>S No.</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Net</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {feesPerCat &&
                        Object.entries(feesPerCat).map((item, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell>
                                    {
                                        feeStructure.categories.find(
                                            (cat: any) =>
                                                cat.category.id == item[0]
                                        )?.category?.name
                                    }
                                </TableCell>
                                <TableCell>{item[1].original}</TableCell>
                                <TableCell>{item[1].discount}</TableCell>
                                <TableCell>
                                    {item[1].original - item[1].discount}
                                </TableCell>
                            </TableRow>
                        ))}
                    {feesPerCat && (
                        <>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    {totals.original + totals.discount}
                                </TableCell>
                                <TableCell>{totals.discount}</TableCell>
                                <TableCell>{totals.original}</TableCell>
                            </TableRow>
                            <TableRow sx={{ height: "20px" }}></TableRow>
                            <TableRow sx={{ height: "20px" }}></TableRow>
                            <TableRow>
                                <TableCell colSpan={4}>Total</TableCell>
                                {/* <TableCell>{totals.original}</TableCell> */}
                                {/* <TableCell>{totals.discount}</TableCell> */}
                                <TableCell>{totals.original}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}>Paid</TableCell>
                                <TableCell>{totalCollection}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    Payable within due date
                                </TableCell>
                                <TableCell>
                                    {totals.original - totalCollection}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={4}>Surcharge</TableCell>
                                <TableCell>{`${
                                    (surcharge?.value || 0) * fees.length
                                } (${surcharge?.value || 0} x${
                                    fees.length
                                })`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    Payable after due date
                                </TableCell>
                                <TableCell>
                                    {totals.original -
                                        totalCollection +
                                        (parseFloat(surcharge?.value) || 0) *
                                            fees.length}
                                </TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
