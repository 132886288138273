import Add from "@mui/icons-material/Add";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { SearchNFilter } from "../components/Academics/Section/SearchNFilter";
import { AppContext } from "../components/AppContext";
import { Search } from "../components/Filters/Search";
import { RUDTable } from "../components/Table/RUDTable";
import { ClientService } from "../services/ClientService";

const Clients = () => {
    const { userType } = useContext(AppContext);
    const [filter, setFilter] = useState<any>({});
    const [rows, setRows] = useState({
        status: [],
        packageType: [],
        type: [],
    });

    const handleChange = (ev: ChangeEvent<any>, external: boolean) => {
        if (!external) {
            setFilter({ ...filter, [ev.target.name]: ev.target.value });
        }
    };

    const getStatus = async () => {
        const [data, err] = await ClientService.getClientStatuses(1, 100, {});

        if (data) {
            setRows((rows) => ({ ...rows, status: data.rows }));
        }
    };

    const getPackages = async () => {
        const [data, err] = await ClientService.getClientPackages(1, 100, {});
        if (data) {
            setRows((rows) => ({ ...rows, packageType: data.rows }));
        }
    };

    const getClientTypes = async () => {
        const [data, err] = await ClientService.getClientTypes(1, 100, {});
        if (data) {
            setRows((rows) => ({ ...rows, type: data.rows }));
        }
    };
    useEffect(() => {
        getStatus();
        getPackages();
        getClientTypes();
    }, []);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" gutterBottom color="primary">
                        Clients
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<Add />}
                        variant="outlined"
                        href={`/add-client`}
                    >
                        Add client
                    </Button>
                </Grid>
            </Grid>

            <SearchNFilter>
                <TextField
                    select
                    fullWidth
                    size="small"
                    name="status"
                    label="Status"
                    value={filter.status}
                    onChange={(ev) => handleChange(ev, false)}
                >
                    {rows.status.map((s: any) => (
                        <MenuItem key={s.id} value={s.status}>
                            {s.status}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    fullWidth
                    size="small"
                    name="packageType"
                    label="Packages"
                    value={filter.package}
                    onChange={(ev) => handleChange(ev, false)}
                >
                    {rows.packageType.map((s: any) => (
                        <MenuItem key={s.id} value={s.package}>
                            {s.package}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    fullWidth
                    size="small"
                    name="type"
                    label="Type"
                    value={filter.type}
                    onChange={(ev) => handleChange(ev, false)}
                >
                    {rows.type.map((s: any) => (
                        <MenuItem key={s.id} value={s.type}>
                            {s.type}
                        </MenuItem>
                    ))}
                </TextField>

                <Search filter={filter} setFilter={setFilter} />
            </SearchNFilter>
            <RUDTable
                getter={(page, limit) =>
                    ClientService.getClient(page, limit, filter)
                }
                editor={ClientService.editClient}
                deletor={(id: string | number) =>
                    ClientService.deleteClientSoft(id)
                }
                rowsPreprocessor={(row) => ({
                    ...row,
                    //in here we will map nested values
                    deletionId: row.id,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    address: "Address",
                    type: "Type",
                    packageType: "Package",
                    email: "email",
                    phone: "phone",
                    mobile: "mobile",
                    status: "Status",
                    description: "Description",
                    expiry: "expiry",
                }}
                editables={[
                    {
                        name: "name",
                        label: "Name",
                        type: "textfield",
                    },
                    {
                        name: "address",
                        type: "textfield",
                        label: "Client Address",
                    },

                    {
                        name: "email",
                        type: "textfield",
                        label: "Email",
                    },
                    {
                        name: "packageType",
                        label: "Package Type",
                        type: "select",
                        options: {
                            label: "package",
                            value: "package",
                            valuesFetcher: ClientService.getClientPackages,
                        },
                    },

                    {
                        name: "type",
                        label: "Client Type",
                        type: "select",
                        options: {
                            label: "type",
                            value: "type",
                            valuesFetcher: ClientService.getClientTypes,
                        },
                    },
                    {
                        name: "status",
                        label: "Status",
                        type: "select",
                        options: {
                            label: "status",
                            value: "status",
                            valuesFetcher: ClientService.getClientStatuses,
                        },
                    },
                ]}
                updatingAgents={[filter]}
                ops={{
                    read: "CREATE CLIENT",
                    edit: "UPDATE CLIENT",
                    delete: "DELETE CLIENT",
                }}
                storageKey="clients"
            />
        </div>
    );
};

export default Clients;
