import {
    Checkbox,
    FormControl,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";

export const TestResultRow = ({
    singleStudent,
    testResult,
    setTestResult,
    checkAll,
    limit,
}: any) => {
    const [isChecked, setIsChecked] = useState(checkAll);
    const { feedback: _globalFeedBack } = useContext(AppContext);
    const [feedback, setFeedback] = useState(_globalFeedBack);
    function handleCheck(
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setIsChecked(checked);

        setTestResult((curr: any) => {
            const withCurrChecked = {
                ...curr,
                [singleStudent.baseUser.id]: {
                    ...testResult[singleStudent.baseUser.id],
                    checked,
                },
            };

            return withCurrChecked;
        });
    }

    // const handleChange = (ev: ChangeEvent<any>, id: number) => {
    //     setIsChecked(true);
    //     setTestResult((curr: any) => ({
    //         ...curr,
    //         [id]: {
    //             ...curr[id],
    //             [ev.target.name]: ev.target.value,
    //             checked: true,
    //         },
    //     }));
    // };
    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        setIsChecked(true);
        const value = ev.target.value;
        const updated = {
            ...testResult,
            [id]: {
                ...testResult[id],
                [ev.target.name]: isNaN(Number(value)) ? "" : value,
                checked: true,
            },
        };
        setFeedback({
            loading: false,
            message: "Must provide  obtain  marks for the student",
            show: isNaN(Number(value)),
            severity: isNaN(Number(value)) ? "error" : "success",
        });

        setTestResult(updated);
    };

    useEffect(() => {
        setIsChecked(testResult[singleStudent?.baseUser?.id]?.checked ?? false);
    }, [testResult[singleStudent?.baseUser?.id]?.checked]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox checked={isChecked} onChange={handleCheck} />
                </FormControl>
            </TableCell>

            <TableCell>{singleStudent.id}</TableCell>

            <TableCell style={{ whiteSpace: "nowrap" }}>
                {singleStudent.baseUser.name}
            </TableCell>

            <TableCell>{singleStudent.fileNo}</TableCell>
            <TableCell>{singleStudent.enrollmentNo}</TableCell>
            <TableCell>
                <TextField
                    style={{ minWidth: "220px" }}
                    multiline
                    size="small"
                    name="marks"
                    label="Obtained marks"
                    value={testResult[singleStudent.baseUser.id]?.marks}
                    onChange={(ev) =>
                        handleChange(ev, singleStudent.baseUser.id)
                    }
                    variant="outlined"
                    key={singleStudent.baseUser.id}
                    type="number"
                    inputProps={{ min: 0 }}
                    error={feedback.show}
                    helperText={feedback.show && feedback.message}
                />
            </TableCell>
        </TableRow>
    );
};
