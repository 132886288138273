import { makeStyles, Theme as ThemeType } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppContext } from "./components/AppContext";
import { Layout } from "./components/Layout";
import { ClientService } from "./services/ClientService";
import { UserService } from "./services/UserService";
import { DarkTheme } from "./theme/Dark";
import { LightTheme } from "./theme/Light";
import { FeedbackType, Mode, UserType } from "./types";

const initialFeedback: FeedbackType = {
    loading: false,
    show: false,
    severity: "success",
    message: "",
};

const useStyles = makeStyles((theme: ThemeType) => ({
    root: {
        "-webkit-box-shadow:": "0 0 0 30px white inset !important",
        "&:active": {
            "-webkit-box-shadow:": "0 0 0 30px white inset !important",
        },
        "&:hover": {
            "-webkit-box-shadow:": "0 0 0 30px white inset !important",
        },
        "&:focus": {
            "-webkit-box-shadow:": "0 0 0 30px white inset !important",
        },
    },
}));

function App() {
    const [mode, setMode] = useState<Mode>("dark");
    const [user, setUser] = useState(null);
    const [store, setStore] = useState<any>({ logo: "/images/icon.png" });
    const [userType, setUserType] = useState<UserType>(null);
    const [feedback, setFeedback] = useState<FeedbackType>(initialFeedback);
    const classes = useStyles();
    const [theme, setTheme] = useState(DarkTheme);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const getTheme = async () => {
        if (mode === "light") {
            setTheme(LightTheme);
        } else if (mode === "dark") {
            setTheme(DarkTheme);
        } else {
            let _theme;
            const customThemInLocalStorage =
                localStorage.getItem("custom-theme");

            if (customThemInLocalStorage) {
                _theme = customThemInLocalStorage;
            } else {
                const [data, err] = await ClientService.getClientTheme();

                if (data.client.theme) {
                    _theme = data.client.theme;
                } else {
                    _theme = "default";
                }
            }

            if (_theme === "default") {
                setTheme(DarkTheme);
            } else {
                localStorage.setItem("custom-theme", _theme);
                _theme = createTheme(JSON.parse(_theme));
                setTheme(_theme);
            }
        }
    };

    const loginUser = async () => {
        const [data, err] = await UserService.loginUser({});

        if (data) {
            setUser(data.user);
            setUserType(data.user.baseUser.type);
        }
    };

    useEffect(() => {
        loginUser();

        const modeInLocalStorage = localStorage.getItem("theme");

        if (modeInLocalStorage) {
            setMode(modeInLocalStorage as any);
        } else {
            setMode("dark");
        }
    }, []);

    useEffect(() => {
        getTheme();
    }, [mode]);

    return (
        <AppContext.Provider
            value={{
                mode,
                setMode,
                user,
                setUser,
                userType,
                setUserType,
                feedback,
                setFeedback,
                theme,
                sidebarOpen,
                setSidebarOpen,
                store,
                setStore,
            }}
        >
            <ThemeProvider theme={theme as any}>
                <BrowserRouter>
                    <Layout
                        className={classes.root}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />
                </BrowserRouter>
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default App;
