import { ShowChart } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { CampusService } from "../../../services/CampusService";
import { FeeService } from "../../../services/FeeService";
import { APIRequestStatus, useGetAPI } from "../../../services/Hooks";
import { SessionService } from "../../../services/SessionService";
import { PrintTheme } from "../../../theme/PrintTheme";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import PrintTable from "../../Export/PrintTable";
import { FromNTo } from "../../Filters/FromNTo";

type ReportPerStudent = {
    [id: number]: {
        collectedNow: number;
        adjustment: number;
        [key: string | number]: any;
    };
};

const useCollectedPerStudent = (collections: any[]) => {
    const [reportPerStudent, setReportPerStudent] =
        useState<ReportPerStudent | null>(null);

    const calculateReportPerStudent = () => {
        let _reportPerStudent: any = {};

        for (const collection of collections) {
            if (_reportPerStudent[collection.fee.student.id]) {
                _reportPerStudent[collection.fee.student.id].collectedNow +=
                    collection.collected + collection.lateFeeFine;
                _reportPerStudent[collection.fee.student.id].adjustment +=
                    collection.adjustment;

                _reportPerStudent[collection.fee.student.id].lateFeeFine +=
                    collection.lateFeeFine;
            } else {
                _reportPerStudent[collection.fee.student.id] = {
                    collectedNow: collection.collected + collection.lateFeeFine,
                    adjustment: collection.adjustment,
                    lateFeeFine: collection.lateFeeFine,
                    ...collection.fee.student,
                };
            }
        }

        setReportPerStudent(_reportPerStudent);
    };

    useEffect(() => {
        calculateReportPerStudent();
    }, [collections]);

    return reportPerStudent;
};

export const DailyCollection = () => {
    const { user } = useContext(AppContext);
    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        otherOps: { createdAt: "", collectionDate: "" },
    });
    const [campuses, setCampuses] = useState<any[]>([]);

    const [reportStatus, setReportStatus] = useState<APIRequestStatus>(
        APIRequestStatus.idle
    );
    const [collections, setCollections] = useState<any[]>([]);
    const reportPerStudent = useCollectedPerStudent(collections);
    const printRef = useRef<null | any>(null);
    const [printMode, setPrintMode] = useState(false);
    const theme = useTheme();

    const handleChange = (ev: ChangeEvent<any>) => {
        setSelected({ ...selected, [ev.target.name]: ev.target.value });
    };

    const getCampuses = async () => {
        if (user.campuses?.length) {
            setCampuses(user.campuses);
        } else {
            const [data, err] = await CampusService.getCampus(1, 0);
            if (data) {
                setCampuses(data.rows);
            }
        }
    };

    const [sessions, sc, sessionStatus, sm] = useGetAPI(
        SessionService.url,
        1,
        0,
        { campus: selected.campus },
        [selected.campus],
        [selected.campus]
    );

    const getDailyCollectionReport = async () => {
        const _selected = {
            ...selected,
            otherOps: [
                selected.otherOps.createdAt,
                selected.otherOps.collectionDate,
            ],
        };
        if (
            !(
                selected.campus &&
                selected.session &&
                _selected.otherOps.length === 2
            )
        ) {
            return;
        }
        setReportStatus(APIRequestStatus.loading);

        const [data, err] = await FeeService.getCollections(1, 0, {
            otherOps: JSON.stringify(_selected.otherOps),
        });

        console.log("Request complete", Boolean(data));

        if (data) {
            setCollections(data.rows);
            setReportStatus(APIRequestStatus.success);
        } else {
            setReportStatus(APIRequestStatus.error);
        }
    };

    useEffect(() => {
        if (user) {
            getCampuses();
        }
    }, [user]);

    return (
        <Grid container spacing={1} columns={60}>
            <Grid item xs={60} lg={31}>
                <FromNTo
                    filter={selected}
                    setFilter={setSelected}
                    column="createdAt"
                    label="Entry Date"
                />
            </Grid>

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={60} lg={15}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c: any) =>
                            setSelected({
                                ...selected,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{
                            variant: "outlined",
                            size: "small",
                        }}
                    />
                </Grid>
            )}

            <Grid item xs={60} lg={14}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c: any) =>
                        setSelected({
                            ...selected,
                            session: c?.id || "",
                        })
                    }
                    label="Session"
                    labelKey="name"
                    textFieldProps={{
                        variant: "outlined",
                        size: "small",
                    }}
                    apiParams={{ status: "active", campus: selected.campus }}
                />
            </Grid>

            <Grid item xs={60} lg={31}>
                <FromNTo
                    filter={selected}
                    setFilter={setSelected}
                    column="collectionDate"
                    label="Collection Date"
                />
            </Grid>

            <Grid item xs={60} lg={29}>
                <Button
                    fullWidth
                    sx={{ height: "100%" }}
                    variant="outlined"
                    onClick={getDailyCollectionReport}
                    endIcon={
                        reportStatus === APIRequestStatus.loading ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <ShowChart />
                        )
                    }
                >
                    Generate Report
                </Button>
            </Grid>

            <Grid item xs={60} display="flex" justifyContent="flex-end">
                <PrintTable
                    setPrintMode={setPrintMode}
                    componentRef={printRef}
                />
            </Grid>

            <Grid item xs={60}>
                <ThemeProvider theme={printMode ? PrintTheme : theme}>
                    <TableContainer component={Paper} ref={printRef}>
                        <Table size="small">
                            <TableHead>
                                <TableRow
                                    sx={{ "&>*": { bgcolor: "action.hover" } }}
                                >
                                    <TableCell>Student Name</TableCell>
                                    <TableCell>Father Name</TableCell>
                                    <TableCell>Classroom</TableCell>
                                    <TableCell>Enroll no.</TableCell>
                                    <TableCell>File no.</TableCell>
                                    <TableCell>Collected now</TableCell>
                                    <TableCell>Adjustment</TableCell>
                                    <TableCell>Surcharge</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportPerStudent ? (
                                    Object.values(reportPerStudent).map(
                                        (studentReport) => (
                                            <TableRow key={studentReport.id}>
                                                <TableCell>
                                                    {
                                                        studentReport.baseUser
                                                            .name
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.fatherName}
                                                </TableCell>
                                                <TableCell>
                                                    {`${studentReport.classroom.name} (${studentReport.section.name})`}
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.enrollmentNo}
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.fileNo}
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.collectedNow}
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.adjustment}
                                                </TableCell>
                                                <TableCell>
                                                    {studentReport.lateFeeFine}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            <Alert severity="info">
                                                <AlertTitle>
                                                    No collections
                                                </AlertTitle>
                                                No collections with the given
                                                constraints
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </Grid>
        </Grid>
    );
};
