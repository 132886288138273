import { MenuItem, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { baseAPI } from "../../constants";
import SearchableInput from "../../SearchableInput";

type Props = {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
};

const UserType = ({ filter, setFilter }: Props) => {
  return (
    <TextField
      label="User Type"
      size="small"
      fullWidth
      value={filter.type}
      select
      onChange={(e) => setFilter({ ...filter, type: e.target.value })}
    >
      <MenuItem value="employee">Employee</MenuItem>
      <MenuItem value="student">Student</MenuItem>
      <MenuItem value="faculty">Faculty</MenuItem>
    </TextField>
  );
};

export default UserType;
