import { useTheme } from "@material-ui/core";
import { Alert } from "@mui/material";

export const AuthorizeFallback = ({ message }: { message?: string }) => {
    const theme = useTheme();
    return (
        <Alert severity="error" style={{ margin: theme.spacing(1) }}>
            {message || "You're not authorized!"}
        </Alert>
    );
};
