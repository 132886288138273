import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class FamilyService {
    static url = `${baseAPI}/org/family`;

    static async getFamilyByID(id: string | number) {
        try {
            const response = await axios.get(FamilyService.url, {
                withCredentials: true,
                params: { id, page: 1, limit: 1 },
            });
            return [response.data.rows[0], null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async editFamily(id: number | string, data: any) {
        try {
            const response = await axios.patch(FamilyService.url, data, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async addFamilyMember(member: any) {
        try {
            const response = await axios.patch(
                FamilyService.url + "/add-member",
                member,
                {
                    withCredentials: true,
                }
            );
            return [response.data.rows[0], null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async createFromMembers(data: any) {
        try {
            const response = await axios.post(FamilyService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSoft(id: number) {
        try {
            const response = await axios.delete(FamilyService.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getReceipt(data: any) {
        try {
            const response = await axios.get(FamilyService.url + "/receipt", {
                withCredentials: true,
                params: { ...data, months: data.months.join(",") },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }
}
