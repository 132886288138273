import { Grid } from "@mui/material";
import React from "react";

export const Error404 = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="85vh"
            alignContent="center"
        >
            <Grid item textAlign={"center"}>
                <img src="images/404.png" style={{ width: "50%" }} alt="404" />
            </Grid>
        </Grid>
    );
};
