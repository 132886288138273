import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StudentService } from "../../services/StudentService";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";

const ChangeCampus = ({
    row,
    setSelectedComponent,
    changes,
    setChanges,
}: any) => {
    const { feedback, setFeedback } = useContext(AppContext);

    const [changeCampus, setChangeCampus] = useState({
        campus: row?.campus?.id ? row?.campus?.id : "",
        session: row?.session?.id ? row?.session?.id : "",
        classroom: row?.classroom?.id ? row?.classroom?.id : "",
        section: row?.section?.id ? row?.section?.id : "",
    });

    const handleChangCampus = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });
        const [data, err] = await StudentService.changeCampus(
            row.id,
            changeCampus
        );
        if (data) {
            setFeedback({
                loading: false,
                message: data.message,
                severity: "success",
                show: true,
            });
        } else {
            setFeedback({
                loading: false,
                message: err,
                severity: "error",
                show: true,
            });
        }
    };

    useEffect(() => {
        setChanges(0);
    });
    return (
        <Dialog
            open
            onClose={() => {
                setSelectedComponent(null);
                setChanges(changes + 1);
            }}
            fullWidth
        >
            <DialogContent>
                <form onSubmit={handleChangCampus}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            {feedback.show && (
                                <Alert severity={feedback.severity}>
                                    {feedback.message}
                                </Alert>
                            )}
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <Autocomplete
                                api="/org/campus"
                                setOutput={(c: any) =>
                                    setChangeCampus({
                                        ...changeCampus,
                                        campus: c?.id || "",
                                    })
                                }
                                label="Campus"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                defaultValue={row.campus}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Autocomplete
                                api="/org/session"
                                setOutput={(c: any) =>
                                    setChangeCampus({
                                        ...changeCampus,
                                        session: c?.id || "",
                                    })
                                }
                                label="Session"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                apiParams={{
                                    campus: changeCampus.campus
                                        ? changeCampus.campus
                                        : row.campus.id,
                                }}
                                defaultValue={row.session}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Autocomplete
                                api="/org/academics/classroom"
                                setOutput={(c) =>
                                    setChangeCampus({
                                        ...changeCampus,
                                        classroom: c?.id || "",
                                    })
                                }
                                label="Class"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                apiParams={{
                                    campus: changeCampus.campus
                                        ? changeCampus.campus
                                        : row.campus.id,
                                }}
                                defaultValue={row.classroom}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Autocomplete
                                api="/org/academics/section"
                                setOutput={(c) =>
                                    setChangeCampus({
                                        ...changeCampus,
                                        section: c?.id || "",
                                    })
                                }
                                label="Section"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                apiParams={{
                                    classroom: changeCampus.classroom
                                        ? changeCampus.classroom
                                        : row.classroom.id,
                                }}
                                defaultValue={row.section}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                type="submit"
                                size="small"
                                disabled={feedback.loading}
                                endIcon={
                                    feedback.loading ? (
                                        <CircularProgress size="1em" />
                                    ) : null
                                }
                            >
                                {feedback.loading
                                    ? "Changing Campus..."
                                    : "Change Campus"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ChangeCampus;
