import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class ClassroomService {
    static url = `${baseAPI}/org/academics/classroom`;

    static async createClassroom(data: any) {
        try {
            const response = await axios.post(ClassroomService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getClassroom(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(ClassroomService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteClassroomSoft(id: string | number) {
        try {
            const response = await axios.delete(
                ClassroomService.url + "/soft",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteClassroomHard(id: string | number) {
        try {
            const response = await axios.delete(
                ClassroomService.url + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editClassroom(id: string, newClassroom: any) {
        try {
            const response = await axios.patch(
                ClassroomService.url,
                newClassroom,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getNextLevel(campus: string | number) {
        try {
            const response = await axios.get(
                ClassroomService.url + "/next-level",
                {
                    withCredentials: true,
                    params: { campus },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async getEducationCategories() {
        try {
            const response = await axios.get(
                ClassroomService.url + "/educational-categories",
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                ClassroomService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async promoteStudent(data: any) {
        try {
            const response = await axios.post(
                ClassroomService.url + "/by-section",
                data,
                {
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
