import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";
import {
    Dispatch,
    FormEvent,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { PATCH } from "../../services/BaseService";
import { FeeStructureService } from "../../services/FeeStructureService";

type Fee = {
    classroom: {
        id: number;
        name: string;
        campus: { id: number; name: string };
    };
    complete: boolean;
    discount: number;
    dueDate: string;
    id: number;
    month: number;
    session: { id: number; name: string };
    totalAmount: number;
    status: string;
    overrides: { checked: boolean; amount: number; category: { id: number } }[];
    student: { id: number };
};

type SingleEditProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    fee: Fee;
};

type FeeCategory = {
    id: number;
    amount: number | "";
    category: {
        id: number;
        name: string;
        editable: boolean;
        optional: boolean;
    };
    included: boolean;
};

const initialFeedback = {
    loading: false,
    show: false,
    message: "",
    severity: "",
};

export const SingleFeeEdit = ({ open, setOpen, fee }: SingleEditProps) => {
    const [feedback, setFeedback] = useState(initialFeedback);
    const [updatedFee, setUpdatedFee] = useState({
        discount: 0,
        dueDate: "",
        status: "",
    });
    const [feeCategories, setFeeCategories] = useState<{
        [key: number | string]: FeeCategory;
    }>({});

    const getFeeStructure = async () => {
        const [data, err] = await FeeStructureService.getFeeStructure(1, 1, {
            classroom: fee.classroom.id,
            session: fee.session.id,
        });

        if (data.rows.length) {
            setUpdatedFee({
                discount: fee.discount,
                dueDate: fee.dueDate.split("T")[0],
                status: fee.status,
            });

            const overrides: {
                [key: number | string]: { checked: boolean; amount: number };
            } = fee.overrides.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.category.id]: {
                        checked: curr.checked,
                        amount: curr.amount,
                    },
                }),
                {}
            );

            setFeeCategories(
                data.rows[0].categories.reduce(
                    (prev: any, curr: FeeCategory) => ({
                        ...prev,
                        [curr.id]: {
                            ...curr,
                            amount: overrides[curr.category.id].amount,
                            included: curr.category.optional
                                ? overrides[curr.category.id].checked ?? false
                                : true,
                        },
                    }),
                    {}
                )
            );
        }
    };

    const handleUpdateFee = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFeedback((f) => ({ ...f, loading: true }));

        const payload = {
            [fee.student.id]: {
                ...updatedFee,
                overrides: Object.values(feeCategories).map((cat) => ({
                    amount: cat.amount,
                    checked: cat.included,
                    category: cat.category.id,
                })),
            },
        };

        const [data, err] = await PATCH("/org/fees/multiple", payload, {
            month: fee.month,
        });

        if (data) {
            setFeedback({
                loading: false,
                show: true,
                message: data.message,
                severity: "success",
            });
        } else {
            setFeedback({
                loading: false,
                show: true,
                message: err,
                severity: "error",
            });
        }
    };

    useEffect(() => {
        if (open && fee.id) {
            getFeeStructure();
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="xs"
        >
            <DialogContent>
                <Grid
                    container
                    flexDirection="column"
                    spacing={1}
                    onSubmit={handleUpdateFee}
                    component="form"
                >
                    {feedback.show && (
                        <Grid item>
                            <Alert severity={feedback.severity as any}>
                                {feedback.message}
                            </Alert>
                        </Grid>
                    )}

                    {Object.entries(feeCategories).map(([key, category]) => (
                        <Grid item key={key}>
                            <TextField
                                fullWidth
                                size="small"
                                label={category.category.name}
                                value={category.amount}
                                type="number"
                                inputProps={{
                                    min: category.category.editable
                                        ? 0
                                        : category.amount,
                                }}
                                disabled={
                                    !category.category.editable ||
                                    !category.included
                                }
                                onChange={(e) =>
                                    setFeeCategories({
                                        ...feeCategories,
                                        [category.id]: {
                                            ...feeCategories[category.id],
                                            amount: e.target.value
                                                ? parseInt(e.target.value)
                                                : "",
                                        },
                                    })
                                }
                                InputProps={{
                                    startAdornment: (
                                        <FormControl
                                            size="small"
                                            disabled={
                                                !category.category.optional
                                            }
                                        >
                                            <Checkbox
                                                size="small"
                                                checked={category.included}
                                                onChange={(e, checked) =>
                                                    setFeeCategories({
                                                        ...feeCategories,
                                                        [category.id]: {
                                                            ...feeCategories[
                                                                category.id
                                                            ],
                                                            included: checked,
                                                        },
                                                    })
                                                }
                                            />
                                        </FormControl>
                                    ),
                                }}
                            />
                        </Grid>
                    ))}

                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            label="Due Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={updatedFee.dueDate}
                            onChange={(e) =>
                                setUpdatedFee({
                                    ...updatedFee,
                                    dueDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            label="Discount"
                            value={updatedFee.discount}
                            onChange={(e) =>
                                setUpdatedFee({
                                    ...updatedFee,
                                    discount: parseInt(e.target.value),
                                })
                            }
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            size="small"
                            label="Status"
                            value={updatedFee.status}
                            onChange={(e) =>
                                setUpdatedFee({
                                    ...updatedFee,
                                    status: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            variant="outlined"
                            type="submit"
                            fullWidth
                            disabled={feedback.loading}
                            startIcon={
                                feedback.loading ? (
                                    <CircularProgress size="1rem" />
                                ) : undefined
                            }
                        >
                            {feedback.loading
                                ? "updating fee..."
                                : "update fee"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
