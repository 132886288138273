import { Done } from "@mui/icons-material";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";

export const FromNTo = ({ filter, setFilter, column, label, sx }: any) => {
    const [range, setRange] = useState({ start: "", end: "" });
    const [status, setStatus] = useState<"set" | "unset" | "processing">(
        "unset"
    );

    const handleChange = (ev: ChangeEvent<any>) => {
        setRange({ ...range, [ev.target.name]: ev.target.value });
    };

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (!range.start || !range.end) {
            return;
        }

        setStatus("processing");

        setFilter({
            ...filter,
            otherOps: {
                ...filter.otherOps,
                [column]: {
                    col: column,
                    operands: Object.values(range).filter((val) =>
                        Boolean(val)
                    ),
                    isDate: true,
                    op: "between",
                },
            },
        });

        setStatus("set");
    };

    return (
        <Stack
            component="form"
            onSubmit={handleSubmit}
            direction={{ xs: "column", md: "row" }}
            sx={{ ...sx }}
            spacing={1}
        >
            <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                onChange={handleChange}
                label={`From ${label}`}
                name="start"
                value={range.start}
                size="small"
            />
            <TextField
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
                onChange={handleChange}
                label={`To ${label}`}
                name="end"
                value={range.end}
                size="small"
            />
            <Button
                variant="outlined"
                size="small"
                type="submit"
                endIcon={
                    status === "processing" ? (
                        <CircularProgress size="1em" />
                    ) : status === "set" ? (
                        <Done />
                    ) : null
                }
            >
                Apply Date range
            </Button>
        </Stack>
    );
};
