import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class BookService {
  static url = `${baseAPI}/library/book`;

  static async createBook(data: any) {
    try {
      const response = await axios.post(BookService.url, data, {
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getBook(page = 1, limit = 20, filter = {}) {
    try {
      const response = await axios.get(BookService.url, {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteBookSoft(id: string | number) {
    try {
      const response = await axios.delete(BookService.url + "/soft", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteGenreHard(id: string | number) {
    try {
      const response = await axios.delete(BookService.url + "/hard", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async editBook(id: string, newAuthor: any) {
    try {
      const response = await axios.patch(BookService.url, newAuthor, {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      console.log(err.response);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }
}
