import { Add } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { BookService } from "../../../services/BookService";
import { StockService } from "../../../services/StockService";
import { AppContext } from "../../AppContext";
import { RUDTable } from "../../Table/RUDTable";
import EditStock from "./EditStock";
import Instances from "./Instances";

const Stock = () => {
    const [filter, setFilter] = useState<any>({});
    const { user } = useContext(AppContext);

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Stock
                    </Typography>
                </Grid>

                <MultiPermissionAuthorize ops={["CREATE BOOK_INSTANCE"]}>
                    <Grid>
                        <Link
                            to="/library/stock/add"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                startIcon={<Add />}
                                variant="outlined"
                                color="primary"
                                style={{ height: "100%" }}
                            >
                                Add Stock
                            </Button>
                        </Link>
                    </Grid>
                </MultiPermissionAuthorize>
            </Grid>

            <RUDTable
                getter={(page, limit) =>
                    BookService.getBook(page, limit, filter)
                }
                editor={StockService.editStock}
                rowsPreprocessor={(row) => ({
                    ...row,
                    deletionId: row.id,
                })}
                readables={{
                    id: "ID",
                    name: "Name",
                    Stock: "Stock",
                    Edit: "Edit",
                }}
                customCols={[
                    {
                        header: "Stock",
                        content: (row) => <Instances row={row} />,
                    },

                    {
                        header: user?.baseUser?.ops.includes(
                            "UPDATE BOOK_INSTANCE"
                        )
                            ? "Edit"
                            : "",
                        content: (row) => (
                            <MultiPermissionAuthorize
                                ops={["UPDATE BOOK_INSTANCE"]}
                            >
                                <EditStock row={row} />
                            </MultiPermissionAuthorize>
                        ),
                    },
                ]}
                updatingAgents={[filter]}
                ops={{
                    read: "READ BOOK_INSTANCE",
                    edit: "",
                    delete: "DELETE BOOK_INSTANCE",
                }}
                storageKey="stock"
            />
        </div>
    );
};

export default Stock;
