import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    useTheme,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { sidebarData } from "../../SidebarData";
import { AppContext } from "../AppContext";

export default function BaseCollapse() {
    const [activeModule, setActiveModule] = useState(null);
    const [activeLink, setActiveLink] = useState(null);
    const { userType } = useContext(AppContext);
    const location = useLocation();

    const theme = useTheme();

    return (
        userType && (
            <>
                {sidebarData[userType].map((item, idx) => (
                    <Fragment key={idx}>
                        <MultiPermissionAuthorize
                            ops={item.subfields
                                .map((field) => field.permissions)
                                .flat()}
                            strategy="some"
                        >
                            {
                                <ListItemButton
                                    disablePadding
                                    onClick={
                                        item.subfields.length > 1
                                            ? () =>
                                                  setActiveModule((id) =>
                                                      id === item.id
                                                          ? null
                                                          : item.id
                                                  )
                                            : () => setActiveLink(item.id)
                                    }
                                    sx={{
                                        width: "100%",
                                        maxWidth: 360,
                                        textTransform: "capitalize",
                                    }}
                                    component="nav"
                                    selected={activeLink === item.id}
                                >
                                    {item.subfields.length === 1 ? (
                                        <Link
                                            to={item.subfields[0].href}
                                            style={{
                                                textDecoration: "none",
                                                width: "100%",
                                                color: "inherit",
                                            }}
                                        >
                                            <ListItemText primary={item.name} />
                                        </Link>
                                    ) : (
                                        <>
                                            <ListItemText
                                                primary={item.name}
                                                primaryTypographyProps={{
                                                    color:
                                                        activeModule ===
                                                            item.id &&
                                                        "primary",
                                                }}
                                            />
                                            {item.subfields.length > 1 &&
                                                (activeModule === item.id ? (
                                                    <ExpandLess
                                                        htmlColor={
                                                            activeModule ===
                                                            item.id
                                                                ? theme.palette
                                                                      .primary
                                                                      .main
                                                                : undefined
                                                        }
                                                        fontSize="small"
                                                    />
                                                ) : (
                                                    <ExpandMore
                                                        htmlColor={
                                                            activeModule ===
                                                            item.id
                                                                ? theme.palette
                                                                      .primary
                                                                      .main
                                                                : undefined
                                                        }
                                                    />
                                                ))}
                                        </>
                                    )}
                                </ListItemButton>
                            }
                        </MultiPermissionAuthorize>
                        {item.subfields.length > 1 && (
                            <Collapse
                                in={activeModule === item.id}
                                timeout="auto"
                                unmountOnExit
                                sx={{
                                    textTransform: "capitalize",
                                    bgcolor: theme.palette.background.paper,
                                }}
                            >
                                <List component="div" disablePadding dense>
                                    {item.subfields.map((btn, idx) => (
                                        <MultiPermissionAuthorize
                                            key={idx}
                                            ops={btn.permissions || []}
                                            strategy="some"
                                        >
                                            <ListItemButton
                                                disablePadding
                                                to={btn.href}
                                                button
                                                component={NavLink}
                                                selected={
                                                    location.pathname ===
                                                    btn.href
                                                }
                                                onClick={() =>
                                                    setActiveLink(
                                                        `${item.id}-${idx}`
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    secondary={btn.name}
                                                    style={{
                                                        paddingLeft: "2rem",
                                                    }}
                                                />
                                            </ListItemButton>
                                        </MultiPermissionAuthorize>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </Fragment>
                ))}
            </>
        )
    );

    // return (
    //   <>
    //     {userType &&
    //       sidebarData[userType].map((item, idx) => (
    //         <Fragment key={idx}>
    //           <MultiPermissionAuthorize
    //             ops={item.subfields.map((field) => field.permissions).flat()}
    //             strategy="some"
    //             key={`permission-${idx}`}
    //           >
    //             <ListItem
    //               key={`listItem-${idx}`}
    //               disablePadding
    //               sx={{
    //                 width: "100%",
    //                 maxWidth: 360,
    //                 bgcolor: "background.background",
    //               }}
    //               component="nav"
    //               // selected={open[item.id]}
    //             >
    //               <ListItemButton onClick={() => handleClick(item.id)}>
    //                 <ListItemText primary={item.name} />
    //                 {open[item.id] ? <ExpandLess /> : <ExpandMore />}
    //               </ListItemButton>
    //             </ListItem>
    //           </MultiPermissionAuthorize>
    //           <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
    //             <List component="div" disablePadding>
    //               {item?.subfields?.map((btn, idx) => (
    //                 // <Link to="/clients" >
    //                 <MultiPermissionAuthorize
    //                   ops={btn?.permissions?.flat()}
    //                   strategy="every"
    //                   key={`subField-${idx}`}
    //                 >
    //                   <ListItem
    //                     key={idx}
    //                     sx={{
    //                       paddingLeft: 4,
    //                       bgcolor:
    //                         location && location.pathname === btn.href
    //                           ? "action.hover"
    //                           : undefined,
    //                     }}
    //                     component={NavLink}
    //                     to={btn.href}
    //                     button
    //                     disablePadding
    //                   >
    //                     <ListItemText
    //                       primary={btn.name}
    //                       primaryTypographyProps={{
    //                         variant: "body1",
    //                       }}
    //                     />
    //                   </ListItem>
    //                 </MultiPermissionAuthorize>
    //                 // </Link>
    //               ))}
    //             </List>
    //           </Collapse>
    //         </Fragment>
    //       ))}
    //   </>
    // );
}
