import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    IconButton,
    Modal,
} from "@material-ui/core";
import UploadIcon from "@mui/icons-material/Upload";
import { Alert, AlertTitle } from "@mui/material";
import {
    ChangeEvent,
    MouseEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { APIRequestStatus } from "../../services/Hooks";
import { S3Service } from "../../services/S3Service";
import { AppContext } from "../AppContext";
import { awsDirStruct } from "../constants";

export const ShowUploadProfilePicture = ({ row }: any) => {
    const [open, setOpen] = useState(false);
    const { user, store, setStore } = useContext(AppContext);
    const [pic, setPic] = useState("");
    const inputRef = useRef<null | HTMLInputElement>(null);
    const [uploadStatus, setUploadStatus] = useState(APIRequestStatus.idle);

    const handleInitiateUpload = async (ev: MouseEvent<any>) => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleUploadFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        const file =
            ev.target.files && ev.target.files.length > 0 && ev.target.files[0];

        if (file) {
            const url = URL.createObjectURL(file);
            setPic(url);

            setUploadStatus(APIRequestStatus.loading);

            const profilePicKey = `${awsDirStruct.profile.picture}/${row.baseUser.id}`;

            const [data, err] = await S3Service.uploadToS3(profilePicKey, file);

            if (data) {
                setUploadStatus(APIRequestStatus.success);
                setStore((_s: any) => ({ ..._s, profilePic: file }));
            } else {
                setUploadStatus(APIRequestStatus.error);
            }
        }
    };

    const getProfilePic = async () => {
        if (store.profilePic) {
            setPic(URL.createObjectURL(store.profilePic));
        } else {
            const profilePicKey = `${awsDirStruct.profile.picture}/${row.baseUser.id}`;
            const [data, err] = await S3Service.getS3ByKey(profilePicKey);

            if (data.url) {
                try {
                    setPic(data.url);
                } catch (error) {}
            }
        }
    };

    const toggleOpen = () => setOpen(!open);

    useEffect(() => {
        getProfilePic();
    }, []);

    return (
        <Grid item xs={12} md={6}>
            <Box>
                <IconButton onClick={toggleOpen}>
                    <UploadIcon />
                </IconButton>

                <Modal
                    open={open}
                    onClose={toggleOpen}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Card>
                        <CardHeader title="Profile picture" />
                        <CardContent
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "8px",
                            }}
                        >
                            <Avatar
                                src={pic}
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    cursor: "pointer",
                                }}
                                onClick={handleInitiateUpload}
                            ></Avatar>
                            <input
                                hidden
                                ref={inputRef}
                                type="file"
                                onChange={handleUploadFile}
                            />

                            <Alert
                                style={{ width: "100%" }}
                                severity={
                                    uploadStatus === APIRequestStatus.idle ||
                                    uploadStatus === APIRequestStatus.loading
                                        ? "info"
                                        : (uploadStatus as any)
                                }
                            >
                                <AlertTitle>Image upload</AlertTitle>
                                {uploadStatus === APIRequestStatus.loading && (
                                    <div>
                                        Uploading...{" "}
                                        <CircularProgress size="1em" />
                                    </div>
                                )}

                                {uploadStatus === APIRequestStatus.success &&
                                    "Upload successful"}
                                {uploadStatus === APIRequestStatus.error &&
                                    "Upload failed"}
                                {uploadStatus === APIRequestStatus.idle &&
                                    "Click on the avatar to upload an image"}
                            </Alert>
                        </CardContent>
                    </Card>
                </Modal>
            </Box>
        </Grid>
    );
};
