import { Edit } from "@mui/icons-material";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { usePostAPI } from "../../services/Hooks";
import { StudentService } from "../../services/StudentService";
import { TestService } from "../../services/TestResultService";
import { AppContext } from "../AppContext";
import { Autocomplete } from "../AutoComplete";
import { baseAPI } from "../constants";
import { TestResultRow } from "./TestResultRow";

const UpdateTestResultBulk = ({ row, changes, setChanges }: any) => {
    const [open, setOpen] = useState(false);
    const { user } = useContext(AppContext);
    const theme = useTheme();
    const [feedback, setFeedback] = useState({
        message: "",
        hidden: false,
        severity: "",
        loading: false,
    });
    const [overAllResponses, setoverAllResponses] = useState([]);
    const [testResultData, setTestResultData] = useState<any>();
    const [students, setStudents] = useState<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [filter, setFilter] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: row._session,
        classroom: "",
        section: row._section,
        status: "",
        admissionStatus: "",
        apply: 0,
    });

    const [updateResponse, setUpdateResponse] = useState<any>();
    const [testResultInfo, setTestResultInfo] = useState({
        name: row.name,
        date: row.date.split("T")[0],
        subject: row.subject.id,
        employee: row.employee.id,
        campus: row.campus.id,
        totalMarks: row.totalMarks ?? 0,
        classroom: row.classroom.id,
    });
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    const [showColumns, setShowColumns] = useState(false);
    const [testResults, setTestResults] = useState<{
        [key: number]: any;
    }>({});

    const isAllChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked = Object.values(testResults)
            .slice(start, end)
            .every((x) => x.checked);

        return checked;
    }, [testResults]);

    const isSomeChecked = useMemo(() => {
        const { page, limit } = pagination;

        const start = page * limit;
        const end = (page + 1) * limit - 1;

        const checked =
            !isAllChecked &&
            Object.values(testResults)
                .slice(start, end)
                .some((x) => x.checked);

        console.log("isAllChecked", checked);

        return checked;
    }, [testResults]);

    function handleAllCheck(
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) {
        setTestResults((curr) => {
            const allChecked = Object.fromEntries(
                Object.entries(curr).map(([k, v], index) => {
                    const { page, limit } = pagination;

                    const start = page * limit;
                    const end = (page + 1) * limit;

                    console.log(start, end);

                    if (index >= start && index < end) {
                        return [
                            k,
                            {
                                ...v,
                                checked,
                            },
                        ];
                    }

                    return [k, v];
                })
            );

            return allChecked;
        });
    }

    const [clearFilters, setClearFilters] = useState(false);

    const handleFilterClear = () => {
        setClearFilters(!clearFilters);
        setFilter({
            campus: "",
            session: "",
            classroom: "",
            section: "",
            status: "",
            admissionStatus: "",
            apply: 0,
        });
    };

    const getTestResult = async () => {
        const [data, err] = await TestService.getTestResult(1, 1000, {
            test: row.id,
        });

        if (data?.rows?.length) {
            setTestResultData(
                data.rows.reduce((prev: any, curr: any) => {
                    return { ...prev, [curr.student.baseUser.id]: curr };
                }, {})
            );
        }
    };

    const getStudent = async () => {
        const [data, err] = await StudentService.getStudent(
            pagination.page + 1,
            pagination.limit,
            {
                campus: testResultInfo.campus ? testResultInfo.campus : null,
                session: filter.session.id ? filter.session.id : null,
                classroom: testResultInfo.classroom
                    ? testResultInfo.classroom
                    : null,
                section: filter.section.id ? filter.section.id : null,
            }
        );

        if (data?.rows?.length) {
            setStudents(data.rows);
            setPagination({
                ...pagination,
                count: data?.count ?? 0,
            });
        } else {
            setStudents([]);
            setTestResults([]);
        }
    };

    const [createTestResult, response, status, message] = usePostAPI(
        `${baseAPI}/org/test-result/create-test-result-submission`
    );

    const [
        updateTestResult,
        testResultupdateresponse,
        updateTestResultstatus,
        updateTestResultmessage,
    ] = usePostAPI(`${baseAPI}/org/test-result/update-bulk`);

    useEffect(() => {
        if (testResultupdateresponse) {
            setUpdateResponse(testResultupdateresponse);
        }
    }, [testResultupdateresponse]);

    useEffect(() => {
        if (!open) return;
        setTestResultInfo({
            name: row.name,
            date: row.date.split("T")[0],
            subject: row.subject.id,
            employee: row.employee.id,
            campus: row.campus.id,
            totalMarks: row.totalMarks ?? 0,
            classroom: row.classroom.id,
        });
    }, [open]);

    useEffect(() => {
        let timer: any;
        if (updateResponse) {
            timer = setTimeout(() => {
                setOpen(false);
                setUpdateResponse(null);
                setFeedback({
                    message: "",
                    hidden: false,
                    severity: "",
                    loading: false,
                });
                setChanges(changes + 1);
            }, 1000);
        }
        // Clear timeout if updateResponse changes or component unmounts
        return () => clearTimeout(timer);
    }, [updateResponse]);

    async function handleSubmit(ev: any) {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const updateResults = Object.values(testResults)
            .filter((payment) => payment.checked && payment.id != "")
            .map((payment) => {
                return {
                    ...payment,
                    marks: parseInt(payment.marks, 10),
                    test: row.id,
                    id: payment.id,
                };
            });

        const TestResultWithNoPaymentId = Object.values(testResults)
            .filter((payment) => payment.checked && payment.id == "")
            .map((payment) => {
                return {
                    ...payment,
                    marks: parseInt(payment.marks, 10),
                    test: row.id,
                    totalMarks: testResultInfo.totalMarks,
                };
            });
        if (
            (!updateResults || updateResults.length === 0) &&
            (!TestResultWithNoPaymentId ||
                TestResultWithNoPaymentId.length === 0)
        ) {
            setFeedback({
                loading: false,
                message: "You must checked a row in order to create a test",
                hidden: true,
                severity: "error",
            });
        } else if (
            updateResults?.some((x) => !Boolean(x?.marks)) ||
            TestResultWithNoPaymentId?.some((x) => !Boolean(x?.marks))
        ) {
            setFeedback({
                loading: false,
                message: "Must provide  obtained  marks for the student",
                hidden: true,
                severity: "error",
            });

            return;
        } else if (
            updateResults?.some((x) => x?.marks > testResultInfo.totalMarks) ||
            TestResultWithNoPaymentId?.some(
                (x) => x?.marks > testResultInfo.totalMarks
            )
        ) {
            setFeedback({
                loading: false,
                message:
                    "Obtained marks cannot be greater than the total marks",
                hidden: true,
                severity: "error",
            });

            return;
        } else {
            updateTestResult(
                {
                    results: updateResults,
                },
                {}
            );

            createTestResult(
                {
                    submissions: TestResultWithNoPaymentId,
                },
                {}
            );

            const [data, err] = await TestService.editTest(row.id, {
                ...testResultInfo,
                additionalData: JSON.stringify({
                    session: {
                        id: filter.session.id,
                        name: filter.session.name,
                    },
                    section: {
                        id: filter.section.id,
                        name: filter.section.name,
                    },
                }),
            });

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    hidden: true,
                });
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    hidden: true,
                });
            }
        }
    }

    useEffect(() => {
        if (
            testResultInfo.campus &&
            filter.session &&
            testResultInfo.classroom
        ) {
            getStudent();
        }
    }, [
        filter.search,
        pagination.count,
        pagination.page,
        pagination.limit,
        filter.campus,
        filter.session,
        testResultInfo.classroom,
        filter.section,
    ]);

    useEffect(() => {
        if (row.id) {
            getTestResult();
        }
    }, []);

    useEffect(() => {
        if (!students?.length) {
            return;
        }
        const studentData = students.reduce((prev: any, curr: any) => {
            const baseUserId = curr?.baseUser?.id;
            const { marks = "", id = "" } = testResultData?.[baseUserId] || {};
            return {
                ...prev,
                [baseUserId]: {
                    checked: Boolean(testResultData?.[baseUserId]) || false,
                    student: curr.id,
                    marks,
                    id,
                },
            };
        }, {});

        setTestResults(studentData);
    }, [students, testResultData]);

    return (
        <div>
            <IconButton onClick={() => setOpen(true)}>
                <Edit />
            </IconButton>

            <Dialog
                open={open}
                maxWidth="md"
                sx={{ backdropFilter: "blur(5px)" }}
                onClose={() => setOpen(false)}
                PaperProps={{
                    sx: { height: "100%" },
                }}
            >
                <DialogContent>
                    <Grid item xs={12} p={"10px"}>
                        {feedback.hidden && (
                            <Alert severity={feedback.severity as any}>
                                {feedback.message}
                            </Alert>
                        )}
                    </Grid>

                    <Grid container style={{ paddingTop: "2rem" }} spacing={1}>
                        {/* <Grid item xs={12} md={4}>
                            
                        </Grid> */}

                        {user?.baseUser?.userRegions?.length > 1 && (
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    api="/org/campus"
                                    setOutput={(c: any) =>
                                        setTestResultInfo({
                                            ...testResultInfo,
                                            campus: c?.id || "",
                                        })
                                    }
                                    label="Campus"
                                    labelKey="name"
                                    textFieldProps={{
                                        variant: "outlined",
                                        size: "small",
                                        // disabled: true,
                                    }}
                                    clear={clearFilters}
                                    defaultValue={row.campus}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/session"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        session: c || "",
                                    })
                                }
                                label="Session"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                    // disabled: true,
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    campus: testResultInfo.campus,
                                    status: "active",
                                }}
                                defaultValue={
                                    row &&
                                    JSON.parse(row.additionalData).session
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/academics/classroom"
                                setOutput={(c: any) =>
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        classroom: c?.id || "",
                                    })
                                }
                                label="Classroom"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                    // disabled: true,
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    campus: testResultInfo.campus,
                                    status: "active",
                                }}
                                defaultValue={row && row.classroom}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/academics/section"
                                setOutput={(c: any) =>
                                    setFilter({
                                        ...filter,
                                        section: c || "",
                                    })
                                }
                                label="Section"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                    // disabled: true,
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    classroom: testResultInfo.classroom,
                                    status: "active",
                                }}
                                defaultValue={
                                    row &&
                                    JSON.parse(row.additionalData).section
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/Academics/Subject"
                                setOutput={(c: any) =>
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        subject: c?.id || "",
                                    })
                                }
                                label="Subject"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                apiParams={{
                                    classroom: testResultInfo.classroom,
                                    status: "active",
                                }}
                                defaultValue={row && row.subject}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                api="/org/user"
                                setOutput={(c: any) =>
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        employee: c?.id || "",
                                    })
                                }
                                label="Employee"
                                labelKey="name"
                                textFieldProps={{
                                    variant: "outlined",
                                    size: "small",
                                }}
                                clear={clearFilters}
                                processor={(opt) => ({
                                    ...opt,
                                    name: opt?.baseUser?.name,
                                    id: opt?.baseUser?.id,
                                })}
                                defaultValue={row && row.employee}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                label="Name"
                                fullWidth
                                value={testResultInfo.name}
                                name="name"
                                onChange={(ev) => {
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        name: ev.target.value,
                                    });
                                }}
                                required
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                label="Date"
                                fullWidth
                                value={testResultInfo.date}
                                name="date"
                                onChange={(ev) => {
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        date: ev.target.value,
                                    });
                                }}
                                required
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                label="Total Marks"
                                fullWidth
                                value={testResultInfo.totalMarks}
                                name="totalMarks"
                                onChange={(ev) => {
                                    setTestResultInfo({
                                        ...testResultInfo,
                                        totalMarks: ev.target.value,
                                    });
                                }}
                                required
                                type="number"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <TablePagination
                            component="div"
                            onPageChange={(ev, page) =>
                                setPagination({ ...pagination, page: page })
                            }
                            onRowsPerPageChange={(ev) =>
                                setPagination({
                                    ...pagination,
                                    limit: parseInt(ev.target.value),
                                    page: 0,
                                })
                            }
                            count={pagination.count}
                            page={pagination.page}
                            rowsPerPage={pagination.limit}
                            rowsPerPageOptions={[100, 250, 500]}
                        />
                    </Grid>

                    <form
                        onSubmit={(ev) => {
                            handleSubmit(ev);
                        }}
                        style={{ paddingTop: "3rem" }}
                    >
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            <TableCell>
                                                <FormControl>
                                                    <Checkbox
                                                        checked={isAllChecked}
                                                        onChange={
                                                            handleAllCheck
                                                        }
                                                        indeterminate={
                                                            isSomeChecked
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>

                                            <TableCell>Id</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>File No</TableCell>
                                            <TableCell>Enroll No</TableCell>
                                            <TableCell>
                                                Obtained marks
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {students &&
                                            students?.map(
                                                (coll: any, id: number) => (
                                                    <TestResultRow
                                                        count={
                                                            id +
                                                            1 +
                                                            pagination.limit *
                                                                pagination.page
                                                        }
                                                        key={id}
                                                        singleStudent={coll}
                                                        testResult={testResults}
                                                        setTestResult={
                                                            setTestResults
                                                        }
                                                        checkAll={isAllChecked}
                                                    />
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            // disabled={status === "loading"}
                            size="large"
                            type="submit"
                            endIcon={
                                status === "loading" ? (
                                    <CircularProgress size="1rem" />
                                ) : null
                            }
                        >
                            {status === "loading"
                                ? "Updating Test Result..."
                                : "Update  Test Result"}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UpdateTestResultBulk;
