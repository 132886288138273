import { Grid } from "@material-ui/core";
import {
    Book,
    Circle,
    Close,
    Done,
    Inventory,
    MoreHoriz,
} from "@mui/icons-material";
import {
    AppBar,
    Box,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { MultiPermissionAuthorize } from "../../../MultiPermissionAuthorize";
import { StockService } from "../../../services/StockService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { Search } from "../../Filters/Search";
import { RUDTable } from "../../Table/RUDTable";
import EditInstance from "./EditInstance";

const Instances = ({ row }: any) => {
    const { user } = useContext(AppContext);

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState<any>({
        book: row.id,
        search: "",
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
    });
    const [pagination, setPagination] = useState({ page: 1, limit: 10 });
    const [changeCount, setChangeCount] = useState(0);

    return (
        <>
            <IconButton onClick={() => setOpen(true)} size="small">
                <Inventory fontSize="small" />
            </IconButton>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullScreen
                TransitionComponent={Slide}
            >
                <DialogTitle>
                    <AppBar position="fixed">
                        <Toolbar
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    gap: "8px",
                                    alignItems: "center",
                                }}
                            >
                                <Book />
                                <Typography>{`${row.name}`}</Typography>
                            </div>

                            <IconButton
                                onClick={() => setOpen(false)}
                                size="small"
                            >
                                <Close fontSize="small" />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent>
                    <Box mt={8}>
                        <Grid item container spacing={2}>
                            {user?.baseUser?.userRegions?.length > 1 && (
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        api="/org/campus"
                                        setOutput={(c: any) =>
                                            setFilter({
                                                ...filter,
                                                campus: c?.id || "",
                                            })
                                        }
                                        label="Campus"
                                        labelKey="name"
                                        textFieldProps={{
                                            variant: "outlined",
                                            size: "small",
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} md={6}>
                                <Search filter={filter} setFilter={setFilter} />
                            </Grid>
                        </Grid>

                        <RUDTable
                            getter={(page, limit) =>
                                StockService.getStock(page, limit, {
                                    book: filter.book,
                                    campus: filter.campus
                                        ? filter.campus
                                        : undefined,
                                    search: filter.search
                                        ? filter.search
                                        : undefined,
                                })
                            }
                            editor={StockService.editStock}
                            deletor={(id: string | number) =>
                                StockService.deleteStockHard(id)
                            }
                            rowsPreprocessor={(row) => ({
                                ...row,
                                deletionId: row.id,
                                accessationNo: `BK-${row.id}`,
                                campusName: row.campus.name,
                                campus: row.campus.id,
                                campusDetail: row.campus,
                                condition: row.condition,
                            })}
                            readables={{
                                accessationNo: "Accession No.",
                                campusName: "Campus",
                                Availablity: "Availablity",
                                Condition: "Condition",
                                Edit: "Edit",
                            }}
                            customCols={[
                                {
                                    header: "Availablity",
                                    content: (row) => (
                                        <Chip
                                            variant="outlined"
                                            icon={
                                                row.status === "available" ? (
                                                    <Circle
                                                        color="success"
                                                        fontSize="small"
                                                    />
                                                ) : (
                                                    <MoreHoriz
                                                        fontSize="small"
                                                        color="warning"
                                                    />
                                                )
                                            }
                                            label={row.status}
                                            sx={{ textTransform: "capitalize" }}
                                            color={
                                                row.status === "available"
                                                    ? "success"
                                                    : "warning"
                                            }
                                        />
                                    ),
                                },

                                {
                                    header: "Condition",
                                    content: (row) => (
                                        <Chip
                                            variant="outlined"
                                            icon={
                                                row.condition === "ok" ? (
                                                    <Done
                                                        color="success"
                                                        fontSize="small"
                                                    />
                                                ) : (
                                                    <Close
                                                        fontSize="small"
                                                        color="warning"
                                                    />
                                                )
                                            }
                                            label={row.condition}
                                            sx={{ textTransform: "capitalize" }}
                                            color={
                                                row.condition === "ok"
                                                    ? "success"
                                                    : "warning"
                                            }
                                        />
                                    ),
                                },

                                {
                                    header: user?.baseUser?.ops.includes(
                                        "UPDATE BOOK_INSTANCE"
                                    )
                                        ? "Edit"
                                        : "",
                                    content: (row) => (
                                        <MultiPermissionAuthorize
                                            ops={["UPDATE BOOK_INSTANCE"]}
                                        >
                                            <EditInstance
                                                row={row}
                                                changeCount={changeCount}
                                                setChangeCount={setChangeCount}
                                            />
                                        </MultiPermissionAuthorize>
                                    ),
                                },
                            ]}
                            defaultSelected={["accessationNo"]}
                            updatingAgents={[filter, changeCount]}
                            ops={{
                                read: "READ BOOK_INSTANCE",
                                edit: "",
                                delete: "DELETE BOOK_INSTANCEs",
                            }}
                            storageKey="instances"
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Instances;
