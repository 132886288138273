import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { DashboardService } from "../../services/DashboardService";
import { AppContext } from "../AppContext";
import { Fees } from "./Fees";
import { Students } from "./Students";

const Dashboard = () => {
    const userContext = useContext(AppContext);
    const [dashBoardData, setDashBoardData] = useState<any>(null);

    const getDashboardData = async () => {
        const [data, err] = await DashboardService.getData();
        if (data) {
            setDashBoardData(data.report);
        }
    };

    useEffect(() => {
        getDashboardData();
    }, []);

    return (
        <Grid container spacing={1}>
            <MultiPermissionAuthorize ops={["READ DASHBOARD"]}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        {userContext?.user?.baseUser?.name}!
                    </Typography>
                </Grid>

                <Fees feeData={dashBoardData} />

                <Students dashboardData={dashBoardData} />
            </MultiPermissionAuthorize>
        </Grid>
    );
};

export default Dashboard;
