import { Search } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

const SearchBar = ({ filter, setFilter }: any) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(ev.target.value);
    };

    const handleSearch = (ev: any) => {
        setFilter({ ...filter, search: searchTerm });
    };

    return (
        <div style={{ position: "relative", width: "100%" }}>
            <TextField
                fullWidth
                size="small"
                type="text"
                placeholder="Search"
                name="query"
                value={searchTerm}
                onChange={handleChange}
            />

            <Button
                variant="contained"
                color="primary"
                disableElevation
                sx={{ position: "absolute", height: "100%", right: 0 }}
                onClick={handleSearch}
            >
                <Search />
            </Button>
        </div>
    );
};

export default SearchBar;
