import React from "react";
import { ExpandMoreOutlined, Filter, FilterAlt } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";

const BaseAccordian = ({ children }: any) => {
  return (
    <div>
      <Accordion sx={{ marginTop: 2 }} elevation={0} disableGutters>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography
            color="primary"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <FilterAlt />
            Click to filter data
          </Typography>
        </AccordionSummary>

        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BaseAccordian;
