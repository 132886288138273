import { makeStyles } from "@material-ui/core";

import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    TextField,
    Theme,
} from "@mui/material";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { StudentCategoryService } from "../../../../services/StudentCategoryService";
import { AddViewContext } from "../../../AddView";
import { AppContext } from "../../../AppContext";

const useStyles = makeStyles((theme: Theme) => ({
    breadcrum: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
    },
}));

type Props = {
    row?: any;
    updateMode?: boolean;
};
export default function EditStudentCategory({ row, updateMode }: Props) {
    const { open, setOpen, triggerRowsRefetch } = useContext(AddViewContext);

    const classes = useStyles();
    const [category, setCategory] = useState({
        name: "",
    });
    const { feedback, setFeedback } = React.useContext(AppContext);

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setCategory({
            ...category,
            [ev.target.name]: value,
        });
    };

    const handlSubmit = async (ev: FormEvent) => {
        setFeedback({ ...feedback, loading: true });
        ev.preventDefault();

        if (!updateMode) {
            const [data, err] =
                await StudentCategoryService.createStudentCategory(category);
            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 1000);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 1000);
            }
        } else {
            const [data, err] =
                await StudentCategoryService.editStudentCategory(
                    row.id,
                    category
                );

            if (data) {
                setFeedback({
                    loading: false,
                    message: data.message,
                    severity: "success",
                    show: true,
                });
                const timer = setTimeout(() => {
                    setOpen(false);
                    triggerRowsRefetch();
                }, 3000);
            } else {
                setFeedback({
                    loading: false,
                    message: err,
                    severity: "error",
                    show: true,
                });
            }
        }
    };

    useEffect(() => {
        return () =>
            setFeedback({
                loading: false,
                message: "",
                severity: "success",
                show: false,
            });
    }, []);

    useEffect(() => {
        if (updateMode) {
            setCategory({
                name: row.name,
            });
        }
    }, []);

    return (
        <form onSubmit={handlSubmit}>
            <Grid container spacing={1.5}>
                <Grid item xs={12} padding={1}>
                    {feedback.show && (
                        <Alert severity={feedback.severity}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label="Name"
                        name="name"
                        value={category.name}
                        fullWidth
                        onChange={handleChange}
                        size="small"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size="1em" />
                            ) : null
                        }
                    >
                        {!updateMode
                            ? feedback.loading
                                ? "Adding student category..."
                                : "Add student category"
                            : feedback.loading
                            ? "Updating student category..."
                            : "Update student category"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
