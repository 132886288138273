import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { UserService } from "./services/UserService";

const LoadingUI = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </div>
    );
};

export default function Authenticate({ children, then, ...props }) {
    const [content, setContent] = useState(<LoadingUI />);
    const history = useHistory();

    const loginOrRedirect = async () => {
        const [data, err] = await UserService.isLoggedIn();

        if (data.logged) {
            if (then) {
                history.push(then);
            } else {
                // setContent(cloneElement(children, { ...props }));
                setContent(children);
            }
        } else {
            history.push(`/login`);
        }
    };

    useEffect(() => {
        loginOrRedirect();
    }, [history.location.pathname]);

    return content;
}
