import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Theme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClientService } from "../../../services/ClientService";
import { AppContext } from "../../AppContext";
import { ThemeItem } from "./ThemeItem";

const themeComponents = [
    {
        label: "Primary",
        name: "primary",
    },
    {
        label: "Secondary",
        name: "secondary",
    },
    {
        label: "Background 1",
        name: "bg1",
    },
    {
        label: "Background 2",
        name: "bg2",
    },
];

const defaultValues = {
    primary: "#fff",
    secondary: "#000",
    bg1: "#123",
    bg2: "#456",
    fontFamily: "Arial",
};

export const ClientTheme = () => {
    const [theme, setTheme] = useState(defaultValues);
    const [clientId, setClientId] = useState("");
    const { feedback, setFeedback } = useContext(AppContext);

    const getTheme = async () => {
        const [data, err] = await ClientService.getClientTheme();

        if (data) {
            const _theme: Theme = JSON.parse(data.client.theme || "{}");
            setTheme({
                primary: _theme.palette?.primary?.main || defaultValues.primary,
                secondary:
                    _theme.palette?.secondary?.main || defaultValues.secondary,
                bg1: _theme.palette?.background?.default || defaultValues.bg1,
                bg2: _theme.palette?.background?.paper || defaultValues.bg2,
                fontFamily:
                    _theme.typography?.fontFamily! || defaultValues.fontFamily,
            });
            setClientId(data.client.id);
        }
    };

    const updateTheme = async () => {
        if (
            Object.values(theme).filter((val) => val.length < 1).length > 1 ||
            !clientId
        ) {
            setFeedback({
                ...feedback,
                severity: "warning",
                message: "Must provide all components of the theme",
                show: true,
            });
            return;
        }

        setFeedback({ ...feedback, loading: true });

        const _theme = JSON.stringify({
            palette: {
                primary: {
                    main: theme.primary,
                },
                secondary: {
                    main: theme.secondary,
                },
                background: {
                    default: theme.bg1,
                    paper: theme.bg2,
                },
            },
            typography: {
                fontFamily: theme.fontFamily,
            },
        });

        const [data, err] = await ClientService.updateTheme(clientId, _theme);
        localStorage.setItem("custom-theme", _theme);

        if (data) {
            setFeedback({
                loading: false,
                severity: "success",
                message: data.message,
                show: true,
            });
        } else {
            setFeedback({
                loading: false,
                severity: "error",
                message: err,
                show: true,
            });
        }
    };

    useEffect(() => {
        getTheme();
    }, []);

    return (
        <Grid container spacing={1}>
            {themeComponents.map((tc, idx) => (
                <ThemeItem
                    key={idx}
                    item={tc}
                    theme={theme}
                    setTheme={setTheme}
                />
            ))}

            <Grid item xs={12} sm={6} lg={3}>
                <TextField
                    size="small"
                    fullWidth
                    label="Font Family"
                    name="fontFamily"
                    value={theme.fontFamily}
                    onChange={(ev) =>
                        setTheme({ ...theme, fontFamily: ev.target.value })
                    }
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
                <Button
                    size="large"
                    variant="outlined"
                    fullWidth
                    onClick={updateTheme}
                    endIcon={
                        feedback.loading ? (
                            <CircularProgress size="1em" />
                        ) : null
                    }
                >
                    Update theme
                </Button>
            </Grid>
        </Grid>
    );
};
