import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SectionServices {
    static url = `${baseAPI}/org/academics/section`;

    static async createSection(data: any) {
        try {
            const response = await axios.post(SectionServices.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                SectionServices.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getSections(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(SectionServices.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSectionSoft(id: string | number) {
        try {
            const response = await axios.delete(SectionServices.url + "/soft", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteSectionHard(id: string | number) {
        try {
            const response = await axios.delete(SectionServices.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editSection(id: string, newSection: any) {
        try {
            const response = await axios.patch(
                SectionServices.url,
                newSection,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
