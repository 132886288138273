import { Print } from "@mui/icons-material";
import { IconButton, SxProps, Theme } from "@mui/material";
import {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useContext,
    useMemo,
    useRef,
} from "react";
import ReactToPrint from "react-to-print";
import { AppContext } from "../AppContext";

type Props = {
    componentRef: MutableRefObject<any>;
    printMode?: boolean;
    setPrintMode: Dispatch<SetStateAction<boolean>>;
    sx?: SxProps<Theme>;
};

const PrintTable = ({
    componentRef,
    printMode = false,
    setPrintMode,
    sx,
}: Props) => {
    const triggerRef = useRef<any>(null);
    const { mode, setMode } = useContext(AppContext);

    const currTheme = useMemo(() => mode, []);

    const handlePrint = () => {
        setPrintMode(true);
        setMode("light");

        setTimeout(() => {
            triggerRef.current?.click();
            setPrintMode(false);

            setMode(currTheme);
        }, 1000);
    };

    return (
        <>
            <ReactToPrint
                trigger={() => <section hidden ref={triggerRef} />}
                content={() => componentRef.current}
            />

            <IconButton onClick={handlePrint} sx={sx}>
                <Print />
            </IconButton>
        </>
    );
};

export default PrintTable;
