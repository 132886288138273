import { Checkbox, FormControl, FormControlLabel, Grid } from "@mui/material";

export const HeaderComponents = ({ selected, setSelected }: any) => {
    const handleCheck = (ev: any) => {
        let value = ev.target.value === "true" ? true : false;
        setSelected({ ...selected, [ev.target.name]: !value });
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox checked={Boolean(selected.campusName)} />
                        }
                        onChange={(ev) => handleCheck(ev)}
                        label="Campus Name"
                        name="campusName"
                        value={selected.campusName}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(selected.campusAddress)}
                            />
                        }
                        onChange={(ev) => handleCheck(ev)}
                        label="Campus Address"
                        name="campusAddress"
                        value={selected.campusAddress}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox checked={Boolean(selected.clientEmail)} />
                        }
                        onChange={(ev) => handleCheck(ev)}
                        label="Email"
                        name="clientEmail"
                        value={selected.clientEmail}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6} lg={4} xl={3}>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox checked={Boolean(selected.clientPhone)} />
                        }
                        onChange={(ev) => handleCheck(ev)}
                        label="Phone"
                        name="clientPhone"
                        value={selected.clientPhone}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};
