import { Alert, List, ListItem, ListItemText } from "@mui/material";

export const ApplyFeeFeedback = ({ feedback, feedbackData }: any) => {
    return (
        feedback.show && (
            <Alert severity={feedback.severity}>
                {feedback.message}
                {feedbackData.partialSuccesses.length > 0 && (
                    <List disablePadding dense>
                        {feedbackData.partialSuccesses
                            .slice(0, 5)
                            .map((item: any, idx: number) => (
                                <ListItem
                                    key={idx}
                                    disablePadding
                                    disableGutters
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                        secondaryTypographyProps={{
                                            variant: "caption",
                                        }}
                                        primary={item.message}
                                        secondary={`Student ID: ${item.id}`}
                                        inset
                                    />
                                </ListItem>
                            ))}
                    </List>
                )}
            </Alert>
        )
    );
};
