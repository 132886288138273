import { Hidden, makeStyles } from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";
import {
    Alert,
    Avatar,
    Button,
    CircularProgress,
    Container,
    List,
    ListItem,
    Paper,
    TextField,
    Theme,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { UserType } from "../../types";
import { AppContext } from "../AppContext";
import { grey } from "@mui/material/colors";

const useStyles = makeStyles((theme: Theme) => ({
    rootContainer: {
        minHeight: "85vh",
        justifyContent: "space-around",
        alignItems: "center",
    },
    tabs: {
        marginBottom: theme.spacing(4),
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },

    avatar: {
        widht: "2%",
    },
    socialIcons: { height: "35px" },
}));

type Props = {
    variant: "superadmin" | "org";
};

const Login = ({ variant }: Props) => {
    const { setUser, setUserType } = useContext(AppContext);
    const history = useHistory();

    const [feedback, setFeedback] = useState({
        loading: false,
        show: false,
        message: "",
        severity: "success",
    });
    const [activeUserType, setActiveUserType] = useState<UserType>(
        variant === "org" ? "admin" : "superadmin"
    );
    const [credentials, setCredentials] = useState<any>({
        username: "",
        password: "",
        client: "",
    });
    const classes = useStyles();
    //for handling state
    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setCredentials({
            ...credentials,
            [ev.target.name]: value,
        });
    };

    const verifyClientInput = () => {
        const result = credentials.client.match(/(ZS|Zs|zs|zS)-[0-9]+/);
        if (!result) return "";
        try {
            const intForm = parseInt(credentials.client.split("-")[1]);
            // if (intForm === NaN) return "";
        } catch (error) {
            return "";
        }
        return credentials.client.split("-")[1];
    };

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setFeedback({ ...feedback, loading: true });

        const [data, err] = await UserService.loginUser({
            ...credentials,
            client: verifyClientInput(),
        });

        if (data) {
            // Go to next screen
            setUserType(activeUserType as any);
            if (data.user) {
                setUser(data.user);
            }
            history.push(`/`);
            setFeedback({
                show: true,
                loading: false,
                message: data.message,
                severity: "success",
            });
        } else {
            setFeedback({
                show: true,
                loading: false,
                message: err,
                severity: "error",
            });
        }
    };

    const loginUser = async () => {
        const [data, err] = await UserService.loginUser({});
        if (data) {
            setUser(data.user);
            setUserType(data.user.baseUser.type);
            history.push("/");
        }
    };

    useEffect(() => {
        loginUser();
    }, []);

    return (
        <Container maxWidth="lg">
            <Grid container className={classes.rootContainer}>
                <Hidden mdDown>
                    <Grid item md={6}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "60vh",
                            }}
                        >
                            <div>
                                <img
                                    src="/images/icon.png"
                                    style={{ marginBottom: "2em" }}
                                />
                                <Typography variant="h4" mb={1}>
                                    Welcome to Zamaschool
                                </Typography>

                                <Typography variant="h5">
                                    The leading School management system <br />{" "}
                                    in Pakistan
                                </Typography>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <List sx={{ display: "flex" }}>
                                    <ListItem>
                                        <Button
                                            href="https://www.facebook.com/zamaschooldotcom/"
                                            target="_blank"
                                        >
                                            <img
                                                src="/images/facebook.png"
                                                className={classes.socialIcons}
                                            />
                                        </Button>
                                    </ListItem>

                                    <ListItem>
                                        <img
                                            src="/images/instagram.png"
                                            className={classes.socialIcons}
                                        />
                                    </ListItem>

                                    <ListItem>
                                        <Button
                                            href="https://wa.me/923339414361"
                                            target="_blank"
                                        >
                                            <img
                                                src="/images/whatsapp.png"
                                                className={classes.socialIcons}
                                            />
                                        </Button>
                                    </ListItem>
                                </List>

                                {/* <Typography variant="h6">techable360</Typography> */}
                            </div>
                        </div>
                    </Grid>
                </Hidden>

                <Grid
                    item
                    xs={10}
                    sm={6}
                    lg={4}
                    component={Paper}
                    p={3}
                    borderRadius={2}
                    minHeight="60vh"
                >
                    <div>
                        {variant === "org" && (
                            <Tabs
                                variant="fullWidth"
                                onChange={(ev, value) =>
                                    setActiveUserType(value)
                                }
                                value={activeUserType}
                                className={classes.tabs}
                            >
                                <Tab label="User" value="admin" />
                                <Tab label="Faculty" value="faculty" />
                                <Tab label="Student" value="student" />
                            </Tabs>
                        )}

                        <form onSubmit={handleSubmit} className={classes.form}>
                            {variant === "org" && (
                                <TextField
                                    label="Organization Token"
                                    name="client"
                                    variant="outlined"
                                    value={credentials.client}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}

                            <TextField
                                label="Username"
                                name="username"
                                variant="outlined"
                                value={credentials.username}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />

                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                value={credentials.password}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />

                            <Button
                                type="submit"
                                variant="outlined"
                                size="large"
                                endIcon={
                                    feedback.loading ? (
                                        <CircularProgress size="1em" />
                                    ) : null
                                }
                            >
                                {feedback.loading ? "Logging in ..." : "Log in"}
                            </Button>

                            {feedback.show && (
                                <Alert severity={feedback.severity as any}>
                                    {feedback.message}
                                </Alert>
                            )}
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Login;
