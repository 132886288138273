import {
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  Compress,
  Expand,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction } from "react";

type Props = {
  fields: any;
  setFields: Dispatch<SetStateAction<any>>;
};

const Header = ({ fields, setFields }: Props) => {
  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      header: {
        ...fields.header,
        [e.target.name]: !fields.header[e.target.name],
      },
    });
  };

  const handleLogoPosition = (position: string) => {
    let newPosition;
    switch (position) {
      case "left":
        newPosition = "start";
        break;

      case "center":
        newPosition = "center";
        break;

      case "right":
        newPosition = "end";
        break;

      case "between":
        newPosition = "space-between";
        break;

      case "evenly":
        newPosition = "space-evenly";
        break;

      default:
        newPosition = "space-evenly";
        break;
    }

    setFields({
      ...fields,
      header: { ...fields.header, position: newPosition },
    });
  };

  return (
    <Grid container flexDirection="column">
      <Grid item container justifyContent="space-between">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={fields.header.logo}
                name="logo"
                onChange={handleCheckbox}
              />
            }
            label="Logo"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={fields.header.profilePicture}
                name="profilePicture"
                onChange={handleCheckbox}
              />
            }
            label="Profile Picture"
          />
        </Grid>
      </Grid>

      <Grid item container mt={1}>
        <Grid item xs={12}>
          <Typography>Alignment</Typography>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="space-between" mt={1}>
          <IconButton onClick={() => handleLogoPosition("left")}>
            <AlignHorizontalLeft
              fontSize="small"
              color={fields.header.position === "start" ? "primary" : "inherit"}
            />
          </IconButton>

          <IconButton onClick={() => handleLogoPosition("center")}>
            <AlignHorizontalCenter
              fontSize="small"
              color={
                fields.header.position === "center" ? "primary" : "inherit"
              }
            />
          </IconButton>

          <IconButton onClick={() => handleLogoPosition("right")}>
            <AlignHorizontalRight
              fontSize="small"
              color={fields.header.position === "end" ? "primary" : "inherit"}
            />
          </IconButton>

          <IconButton
            onClick={() => handleLogoPosition("between")}
            disabled={!fields.header.logo || !fields.header.profilePicture}
          >
            <Expand
              sx={{ rotate: "90deg" }}
              fontSize="small"
              color={
                fields.header.position === "space-between"
                  ? "primary"
                  : "inherit"
              }
            />
          </IconButton>

          <IconButton
            onClick={() => handleLogoPosition("evenly")}
            disabled={!fields.header.logo || !fields.header.profilePicture}
          >
            <Compress
              sx={{ rotate: "90deg" }}
              fontSize="small"
              color={
                fields.header.position === "space-evenly"
                  ? "primary"
                  : "inherit"
              }
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
