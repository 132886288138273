import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class FeeCategoryService {
    static url = `${baseAPI}/org/fees/category`;

    static async createFeeCategory(data: any) {
        try {
            const response = await axios.post(FeeCategoryService.url, data, {
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getFeeCategory(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(FeeCategoryService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteFeeCategorySoft(id: string | number) {
        try {
            const response = await axios.delete(
                FeeCategoryService.url + "/soft",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteFeeCategoryHard(id: string | number) {
        try {
            const response = await axios.delete(
                FeeCategoryService.url + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editFeeCategory(id: string, newFeeCategory: any) {
        try {
            const response = await axios.patch(
                FeeCategoryService.url,
                newFeeCategory,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                FeeCategoryService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
