import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class SessionService {
    static url = `${baseAPI}/org/session`;

    static async createSession(data: any) {
        try {
            const response = await axios.post(SessionService.url, data, {
                withCredentials: true,
            });
            // console.log(data);

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async getSession(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(SessionService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                SessionService.url + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    // Soft Deleting of the session
    static async deleteSessionSoft(id: string | number) {
        try {
            const response = await axios.delete(SessionService.url + "/soft", {
                params: { id },
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async deleteSessionHard(id: string | number) {
        try {
            const response = await axios.delete(SessionService.url + "/hard", {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editSession(id: string, newSession: any) {
        try {
            const response = await axios.patch(SessionService.url, newSession, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
