import { Settings } from "@mui/icons-material";
import Menu from "@mui/icons-material/Menu";
import { Box, Button, Divider, IconButton, Toolbar } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { MultiPermissionAuthorize } from "../../MultiPermissionAuthorize";
import { UserService } from "../../services/UserService";
import { AppContext } from "../AppContext";

export const Navbar = ({ sidebarOpen, setSidebarOpen }: any) => {
    const { user, setUser, setUserType } = useContext(AppContext);
    const history = useHistory();
    const drawerWidth = useMemo(() => 240, []);
    const AppBar = useMemo(
        () =>
            styled(MuiAppBar, {
                shouldForwardProp: (prop) => prop !== "open",
            })(({ theme }) => ({
                transition: theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                ...(sidebarOpen && {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: `${drawerWidth}px`,
                    transition: theme.transitions.create(["margin", "width"], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }),
            })),
        [sidebarOpen]
    );

    const handleLogout = async () => {
        const [data, err] = await UserService.logoutUser();
        setUser(null);
        setUserType(null);

        if (data) {
            history.push("/login");
        }
    };

    return (
        <AppBar
            position="fixed"
            sx={{ bgcolor: "background.default" }}
            elevation={0}
        >
            <Toolbar sx={{ justifyContent: "space-between" }}>
                <IconButton
                    color="primary"
                    onClick={() => setSidebarOpen((open: boolean) => !open)}
                    edge="start"
                    sx={{ mr: 2, ...(sidebarOpen && { display: "none" }) }}
                >
                    <Menu />
                </IconButton>

                <div></div>
                {/* <Typography variant="h5" noWrap component="div">
          Zama School
        </Typography> */}

                <Box
                    component="div"
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={2}
                >
                    {user && (
                        <>
                            <MultiPermissionAuthorize ops={["READ SETTING"]}>
                                <Link to="/settings">
                                    <IconButton>
                                        <Settings />
                                    </IconButton>
                                </Link>
                            </MultiPermissionAuthorize>
                            <Button
                                variant="outlined"
                                onClick={handleLogout}
                                color="inherit"
                            >
                                Logout
                            </Button>
                        </>
                    )}
                </Box>
            </Toolbar>
            <Divider />
        </AppBar>
    );
};
