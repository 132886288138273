import { Download, InfoOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { GET } from "../../../services/BaseService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import SearchableInput from "../../SearchableInput";
import { baseAPI } from "../../constants";

const getStudentCustomFields = async () => {
    const [data, err] = await GET("/customfield/template-component", {
        page: 1,
        limit: 0,
    });

    if (data) {
        return {
            rows: data.rows
                .filter(
                    (field: any) =>
                        field.template.name === "student" &&
                        field.type === "text"
                )
                .map((field: any) => field.key),
            error: null,
        };
    } else return { rows: null, error: err };
};

type StudentColumns = { defaultColumns: string[]; customColumns: string[] };

const ImportFromExcel = () => {
    const { user } = useContext(AppContext);
    const [file, setFile] = useState<File | null>(null);
    const [filter, setFilter] = useState({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        session: "",
        type: "",
    });
    const [studentColumns, setStudentColumns] = useState<StudentColumns>({
        defaultColumns: [],
        customColumns: [],
    });
    const [loading, setLoading] = useState<"loading" | "initial" | "finished">(
        "initial"
    );
    const [report, setReport] = useState({
        message: "",
        successes: [],
        errors: [],
    });

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        count: 0,
    });
    const [showResponseDetails, setShowResponseDetails] = useState(false);

    const generateExcelTemplate = () => {
        if (
            studentColumns.defaultColumns.length > 0 ||
            studentColumns.customColumns.length > 0
        ) {
            const customNDefinedCols = [
                ...studentColumns.defaultColumns.map((col) => ({ [col]: "" })),
                ...studentColumns.customColumns.map((col) => ({ [col]: "" })),
            ].reduce((prev: any, curr: any) => ({ ...prev, ...curr }), {});

            const excelTemplateHeaders = {
                Sheet1: [customNDefinedCols],
            };

            const ws = XLSX.utils.json_to_sheet(excelTemplateHeaders.Sheet1);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            XLSX.writeFile(wb, "Student Template.xlsx");
        }
    };

    const readExcelFileAndCreateStudents = async (
        bin: string | ArrayBuffer | null | undefined
    ): Promise<any> => {
        if (!filter.campus || !filter.session) {
            return;
        }

        const wb = XLSX.read(bin, { type: "binary" });
        let allSheetsData: any[] = [];

        const sheets = wb.Sheets;

        for (const sheet of Object.values(sheets)) {
            const json = XLSX.utils.sheet_to_json(sheet, {
                raw: false,
            });
            allSheetsData = [...allSheetsData, ...json];
        }

        console.log(
            allSheetsData.map((student: any) => ({
                ...student,
                classroom: parseInt(student.classroom),
                enrollmentNo: parseInt(student.enrollmentNo),
                fatherCNIC: parseInt(student.fatherCNIC),
                fatherMobile: parseInt(student.fatherMobile),
                fatherWhatsapp: parseInt(student.fatherWhatsapp),
                fileNo: parseInt(student.fileNo),
                section: parseInt(student.section),
                slcNo: parseInt(student.slcNo),
                studentCNIC: parseInt(student.studentCNIC),
                prePrimaryNo: student.prePrimaryNo
                    ? parseInt(student.prePrimaryNo)
                    : undefined,
                primaryNo: student.primaryNo
                    ? parseInt(student.primaryNo)
                    : undefined,
                middleNo: student.middleNo
                    ? parseInt(student.middleNo)
                    : undefined,
                highNo: student.highNo ? parseInt(student.highNo) : undefined,
                ...filter,
                customFields: studentColumns.customColumns,
            }))
        );

        const [data, errData] = await StudentService.createBulkStudents(
            allSheetsData.map((student: any) => ({
                ...student,
                classroom: parseInt(student.classroom),
                enrollmentNo: parseInt(student.enrollmentNo),
                fatherCNIC: parseInt(student.fatherCNIC),
                fatherMobile: parseInt(student.fatherMobile),
                fatherWhatsapp: parseInt(student.fatherWhatsapp),
                fileNo: parseInt(student.fileNo),
                section: parseInt(student.section),
                slcNo: parseInt(student.slcNo),
                studentCNIC: parseInt(student.studentCNIC),
                prePrimaryNo: student.prePrimaryNo
                    ? parseInt(student.prePrimaryNo)
                    : undefined,
                primaryNo: student.primaryNo
                    ? parseInt(student.primaryNo)
                    : undefined,
                middleNo: student.middleNo
                    ? parseInt(student.middleNo)
                    : undefined,
                highNo: student.highNo ? parseInt(student.highNo) : undefined,
                ...filter,
                customFields: studentColumns.customColumns,
                type: filter.type,
            }))
        );

        if (data) {
            return data;
        } else {
            return errData;
        }
    };

    const handleFileUpload = async (ev: ChangeEvent<HTMLInputElement>) => {
        const _file = ev.target.files && ev.target.files[0];
        setFile(_file);
    };

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setLoading("loading");

        const reader = new FileReader();

        if (file) {
            reader.onload = async (evt) => {
                const binary = evt.target?.result;
                const _report = await readExcelFileAndCreateStudents(binary);

                console.log(_report);

                setLoading("finished");
                setReport(_report);
                setPagination({ ...pagination, count: _report.errors.length });
            };

            reader.readAsBinaryString(file);
        }
    };

    useEffect(() => {
        getStudentCustomFields().then(({ rows, error }) => {
            setStudentColumns(() => ({
                customColumns: rows,
                defaultColumns: [
                    ...StudentService.studentCreationRequiredColumns,
                    ...StudentService.studentCreationOptionalColumns,
                ],
            }));
        });
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <Grid item container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                    <SearchableInput
                        api={`${baseAPI}/org/campus`}
                        filter={filter}
                        setFilter={setFilter}
                        label="Campus"
                        _name="campus"
                        required
                    />
                </Grid> */}

                {user?.baseUser?.userRegions?.length > 1 && (
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            api="/org/campus"
                            setOutput={(c) =>
                                setFilter({
                                    ...filter,
                                    campus: c?.id || "",
                                })
                            }
                            label="Campus"
                            labelKey="name"
                            textFieldProps={{
                                variant: "outlined",
                                size: "small",
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12} md={6}>
                    <SearchableInput
                        api={`${baseAPI}/org/Session`}
                        filter={filter}
                        setFilter={setFilter}
                        label="Session"
                        _name="session"
                        dep={filter.campus}
                        params={{ campus: filter.campus, status: "active" }}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <Autocomplete
                        setOutput={(c) =>
                            setFilter({
                                ...filter,
                                type: c?.id || "",
                            })
                        }
                        label="Import Type"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                        defaultOptions={[
                            { id: "withdrawl", name: "Withdraw Students" },
                            { id: "admission", name: "Admit Students" },
                            {
                                id: "withdrawlAndAdmission",
                                name: "Admit And Withdraw Students",
                            },
                        ]}
                    />
                </Grid>

                <Grid item container xs={12}>
                    <Card>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h4"
                                color="primary"
                            >
                                Import students
                            </Typography>
                            <Divider />

                            <div>
                                <div
                                    style={{
                                        marginTop: "1em",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                    >
                                        <input
                                            onChange={handleFileUpload}
                                            hidden
                                            type="file"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            required
                                        />
                                        Choose File
                                    </Button>

                                    <Typography
                                        variant="body2"
                                        style={{
                                            marginLeft: "1rem",
                                            flex: 1,
                                        }}
                                    >
                                        {file?.name || "Select file"}
                                    </Typography>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        endIcon={
                                            loading === "loading" ? (
                                                <CircularProgress size="1em" />
                                            ) : null
                                        }
                                    >
                                        Import
                                    </Button>
                                </div>

                                <Alert severity="info" sx={{ mt: "2em" }}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="start"
                                    >
                                        <Typography gutterBottom>
                                            Dates must be provided in the{" "}
                                            <a
                                                target="_blank"
                                                href="https://en.wikipedia.org/wiki/ISO_8601"
                                                rel="noopener"
                                            >
                                                ISO format
                                            </a>{" "}
                                            (YYYY-MM-DD)
                                            <br />
                                            <br />
                                            Must provide a .xlsx file and make
                                            sure it has valid values and the
                                            following columns:
                                        </Typography>

                                        <Button
                                            onClick={generateExcelTemplate}
                                            sx={{ mr: 1.5 }}
                                            endIcon={<Download />}
                                            variant="contained"
                                        >
                                            Download Template
                                        </Button>
                                    </Box>
                                    <Divider sx={{ mb: 1 }} />
                                    <Grid container sx={{ ml: 2 }}>
                                        {StudentService.studentCreationRequiredColumns
                                            .filter(
                                                (col) =>
                                                    ![
                                                        "prePrimaryNo",
                                                        "primaryNo",
                                                        "middleNo",
                                                        "highNo",
                                                    ].includes(col)
                                            )
                                            .map((col) => (
                                                <Grid
                                                    key={col}
                                                    item
                                                    xs={6}
                                                    md={6}
                                                >
                                                    <Typography>
                                                        {col}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        <Grid item xs={6}>
                                            <Typography>
                                                `prePrimaryNo` OR `primaryNo` OR
                                                `middleNo` OR `highNo`
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography gutterBottom sx={{ mt: 3 }}>
                                        Optional columns:
                                    </Typography>
                                    <Divider sx={{ mb: 1 }} />
                                    <Grid container sx={{ ml: 2 }}>
                                        {StudentService.studentCreationOptionalColumns.map(
                                            (col) => (
                                                <Grid
                                                    key={col}
                                                    item
                                                    xs={6}
                                                    md={3}
                                                >
                                                    <Typography>
                                                        {col}
                                                    </Typography>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Alert>

                                <Grid item xs={12}>
                                    {loading === "finished" && report && (
                                        <Alert
                                            severity={
                                                report?.errors?.length
                                                    ? "error"
                                                    : "success"
                                            }
                                            action={
                                                <IconButton
                                                    onClick={() => {
                                                        setShowResponseDetails(
                                                            !showResponseDetails
                                                        );
                                                    }}
                                                >
                                                    <InfoOutlined fontSize="small" />
                                                </IconButton>
                                            }
                                        >
                                            {report.message}
                                        </Alert>
                                    )}

                                    <Collapse in={showResponseDetails}>
                                        {report &&
                                            report.successes?.map(
                                                (
                                                    suc: {
                                                        message: string;
                                                        username:
                                                            | number
                                                            | string;
                                                    },
                                                    index: number
                                                ) => (
                                                    <Box
                                                        key={index}
                                                        bgcolor={"#0c130d"}
                                                        style={{
                                                            display: "flex",
                                                            gap: "2rem",
                                                            paddingLeft: "2rem",
                                                            paddingBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Typography variant="caption">
                                                            {" "}
                                                            {`FileNo (${suc.username}) - ${suc.message}`}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )}

                                        {report &&
                                            report.errors.map(
                                                (
                                                    err: {
                                                        message: string;
                                                        username:
                                                            | number
                                                            | string;
                                                    },
                                                    index: number
                                                ) => (
                                                    <Box
                                                        key={index}
                                                        bgcolor={"#160b0b"}
                                                        style={{
                                                            display: "flex",
                                                            gap: "2rem",
                                                            paddingLeft: "2rem",
                                                            paddingBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        <Typography variant="caption">
                                                            {`FileNo (${err.username}) - ${err.message}`}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )}
                                    </Collapse>
                                </Grid>

                                {/* {loading === "finished" && report && (
                                    <Alert
                                        severity={
                                            report?.errors?.length > 0
                                                ? "error"
                                                : "success"
                                        }
                                        sx={{ mt: 2 }}
                                    >
                                        <AlertTitle>
                                            {report?.message}
                                        </AlertTitle>
                                        <List dense>
                                            {report.errors
                                                .filter(
                                                    (err: any, idx: number) =>
                                                        idx >=
                                                            pagination.page *
                                                                pagination.limit &&
                                                        idx <
                                                            (pagination.page +
                                                                1) *
                                                                pagination.limit
                                                )
                                                .map(
                                                    (err: any, idx: number) => (
                                                        <ListItem
                                                            dense
                                                            key={idx}
                                                        >
                                                            <ListItemText
                                                                primary={`${
                                                                    idx + 1
                                                                }. ${
                                                                    err.message
                                                                }`}
                                                            />
                                                        </ListItem>
                                                    )
                                                )}
                                        </List>
                                        {report.errors.length > 0 && (
                                            <Typography variant="body2">{`Showing errors (${pagination.limit} / ${pagination.count})`}</Typography>
                                        )}
                                    </Alert>
                                )} */}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </form>
    );
};

export default ImportFromExcel;
