import {
    AlignHorizontalCenter,
    AlignHorizontalLeft,
    AlignHorizontalRight,
    Close,
    Compress,
    Expand,
    Image,
} from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import {
    CSSProperties,
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useRef,
    useState,
} from "react";
import { S3Service } from "../../../services/S3Service";
import { awsDirStruct } from "../../constants";
import { Certificate } from "../Certificates";

type Props = {
    certificate: Certificate;
    setCertificate: Dispatch<SetStateAction<Certificate>>;
    mode: "create" | "update";
};

type FieldId = number | string;

type TAlignment = CSSProperties["justifyContent"];

const Footer = ({ certificate, setCertificate, mode }: Props) => {
    const [activeFieldId, setActiveFieldId] = useState<null | FieldId>(null);
    const [alignment, setAlignment] = useState<TAlignment>("left");
    const inputRef = useRef<null | HTMLInputElement>(null);
    const theme = useTheme();

    async function deleteImageFromS3(fieldId: number | string) {
        const key = `${awsDirStruct.org.picture}/${certificate.id}/footer/${fieldId}`;

        const res = await S3Service.deleteS3ByKey(key);

        console.log(res);
    }

    function handleNewFooterItem(
        options:
            | { action: "add" }
            | { action: "remove"; id: FieldId }
            | { action: "check"; checked: boolean; id: FieldId }
            | { action: "change-label"; label: string; id: FieldId }
            | { action: "add-image"; id: FieldId; image: File | null }
    ) {
        let footerFields = certificate.fields.footer?.fields ?? [];

        switch (options.action) {
            case "add":
                footerFields.push({
                    checked: true,
                    id: footerFields.length + 1,
                    label:
                        "New Item " +
                        (certificate.fields.footer?.fields ?? []).length,
                    type: "text",
                });
                break;

            case "remove":
                const field = footerFields.find(
                    (item) => item.id == options.id
                );
                if (
                    mode === "update" &&
                    field?.type === "image" &&
                    !field.image
                ) {
                    deleteImageFromS3(options.id);
                }
                footerFields = footerFields.filter(
                    (item) => item.id !== options.id
                );
                break;

            case "check":
                footerFields = footerFields.map((item) =>
                    item.id != options.id
                        ? item
                        : { ...item, checked: options.checked }
                );
                break;

            case "change-label":
                footerFields = footerFields.map((item) =>
                    item.id == options.id
                        ? { ...item, label: options.label }
                        : item
                );

                break;

            case "add-image":
                footerFields = footerFields.map((item) =>
                    item.id == options.id
                        ? {
                              ...item,
                              url: URL.createObjectURL(options.image!),
                              type: "image",
                              image: options.image!,
                          }
                        : item
                );
                break;
        }

        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                footer: c.fields.footer
                    ? { ...c.fields.footer, fields: footerFields }
                    : undefined,
            },
        }));
    }

    function handleFile(event: ChangeEvent<HTMLInputElement>) {
        const file = (event.target.files ?? [])[0];

        if (!file) return;

        handleNewFooterItem({
            action: "add-image",
            image: file,
            id: activeFieldId!,
        });
    }

    function handleAlignment(position: TAlignment) {
        setAlignment(position);
        setCertificate((c) => ({
            ...c,
            fields: {
                ...c.fields,
                footer: c.fields.footer
                    ? { ...c.fields.footer, position }
                    : undefined,
            },
        }));
    }

    return (
        <Grid container spacing={2} style={{ flexDirection: "column" }}>
            <input
                ref={inputRef}
                hidden
                type="file"
                accept="image/*"
                onChange={handleFile}
            />

            <Grid item xs={12}>
                <Box>
                    <Typography>Alignment</Typography>

                    <Box my={1} display="flex" justifyContent="space-between">
                        <IconButton onClick={() => handleAlignment("left")}>
                            <AlignHorizontalLeft
                                fontSize="small"
                                htmlColor={
                                    alignment === "left"
                                        ? theme.palette.primary.main
                                        : "inherit"
                                }
                            />
                        </IconButton>

                        <IconButton onClick={() => handleAlignment("center")}>
                            <AlignHorizontalCenter
                                fontSize="small"
                                htmlColor={
                                    alignment === "center"
                                        ? theme.palette.primary.main
                                        : "inherit"
                                }
                            />
                        </IconButton>

                        <IconButton onClick={() => handleAlignment("right")}>
                            <AlignHorizontalRight
                                fontSize="small"
                                htmlColor={
                                    alignment === "right"
                                        ? theme.palette.primary.main
                                        : "inherit"
                                }
                            />
                        </IconButton>

                        <IconButton
                            onClick={() => handleAlignment("space-between")}
                        >
                            <Expand
                                fontSize="small"
                                sx={{ transform: "rotate(90deg)" }}
                                htmlColor={
                                    alignment === "space-between"
                                        ? theme.palette.primary.main
                                        : "inherit"
                                }
                            />
                        </IconButton>

                        <IconButton
                            onClick={() => handleAlignment("space-around")}
                        >
                            <Compress
                                fontSize="small"
                                sx={{ transform: "rotate(90deg)" }}
                                htmlColor={
                                    alignment === "space-around"
                                        ? theme.palette.primary.main
                                        : "inherit"
                                }
                            />
                        </IconButton>
                    </Box>
                </Box>

                <Divider />
            </Grid>

            {(certificate.fields.footer?.fields ?? [])?.map((field) => (
                <Grid item key={field.id} xs={12}>
                    <TextField
                        fullWidth
                        value={field.label}
                        onChange={(e) =>
                            handleNewFooterItem({
                                action: "change-label",
                                id: field.id,
                                label: e.target.value,
                            })
                        }
                        InputProps={{
                            startAdornment: (
                                <Checkbox
                                    color="primary"
                                    checked={field?.checked}
                                    onChange={(e, checked) =>
                                        handleNewFooterItem({
                                            action: "check",
                                            checked,
                                            id: field.id,
                                        })
                                    }
                                />
                            ),
                            endAdornment: (
                                <>
                                    <IconButton
                                        onClick={(e) => {
                                            setActiveFieldId(field.id);
                                            if (inputRef.current)
                                                inputRef.current.click();
                                        }}
                                    >
                                        <Image fontSize="small" />
                                    </IconButton>

                                    <IconButton
                                        onClick={() =>
                                            handleNewFooterItem({
                                                action: "remove",
                                                id: field.id,
                                            })
                                        }
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                </>
                            ),
                        }}
                    />
                </Grid>
            ))}

            <Grid
                item
                xs={12}
                onClick={() => handleNewFooterItem({ action: "add" })}
            >
                <TextField
                    fullWidth
                    value="Add New"
                    InputProps={{ startAdornment: <Checkbox disabled /> }}
                    style={{ cursor: "pointer" }}
                />
            </Grid>
        </Grid>
    );
};

export default Footer;
