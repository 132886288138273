import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { UserService } from "../../../services/UserService";

export const HeaderStructure = ({
    selected,
    leftImage,
    rightImage,
    campus,
    additionalRow,
    color,
}: any) => {
    const [client, setClient] = useState<any>(null);

    const getClient = async () => {
        const [data, err] = await UserService.getClient();
        if (data) {
            setClient(data);
        }
    };

    useEffect(() => {
        getClient();
    }, []);

    return campus && client ? (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {leftImage && (
                    <img
                        src={leftImage}
                        style={{
                            width: "100px",
                            height: "100px",
                        }}
                        alt=""
                    />
                )}
            </div>
            <Grid
                container
                px={2}
                py={1}
                minHeight="5cm"
                borderRadius={1}
                style={{ display: "flex", justifyContent: "center" }}
            >
                <Grid item textAlign="center">
                    {selected?.campusName ? (
                        <Typography
                            variant="h4"
                            color={`${color}`}
                            sx={{
                                textTransform: "uppercase",
                            }}
                        >
                            {campus?.name}
                        </Typography>
                    ) : null}

                    {selected?.campusAddress ? (
                        <Typography
                            variant="h6"
                            color={`${color}`}
                            sx={{ fontWeight: "bold" }}
                        >
                            {campus?.address}{" "}
                        </Typography>
                    ) : null}

                    {selected?.clientPhone ? (
                        <Typography variant="h6" color={`${color}`}>
                            {client?.client?.phone}
                        </Typography>
                    ) : null}

                    {selected?.clientEmail ? (
                        <Typography variant="h6" color={`${color}`}>
                            {client?.client?.email}
                        </Typography>
                    ) : null}

                    {additionalRow ? (
                        <Typography
                            variant="h6"
                            color={`${color}`}
                            sx={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                            }}
                        >
                            {additionalRow}
                        </Typography>
                    ) : null}
                </Grid>
                {/* <Grid item>
                    {rightImage && (
                        <Avatar
                            src={rightImage}
                            sx={{ width: "75px", height: "75px" }}
                        >
                            <BrokenImageRounded
                                sx={{ width: "50%", height: "50%" }}
                            />
                        </Avatar>
                    )}
                </Grid> */}
            </Grid>
        </>
    ) : null;
};
