import {
    AlignHorizontalCenter,
    AlignHorizontalLeft,
    AlignHorizontalRight,
    Compress,
    Expand,
} from "@mui/icons-material";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from "@mui/material";
import { CSSProperties, ChangeEvent, Dispatch, SetStateAction } from "react";
import { Certificate } from "../Certificates";

type TAlignment = CSSProperties["justifyContent"];

type THeaderProps = {
    certificate: Certificate;
    setCertificate: Dispatch<SetStateAction<Certificate>>;
};

const Header = ({ setCertificate, certificate }: THeaderProps) => {
    const theme = useTheme();

    //
    const handleCheckbox = (
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        const change = {
            [e.target.name]: checked,
        };

        setCertificate((c) => ({
            ...c,
            fields: { ...c.fields, header: { ...c.fields.header, ...change } },
        }));
    };

    const handleLogoPosition = (
        position: Certificate["fields"]["header"]["position"]
    ) => {
        setCertificate((c) => ({
            ...c,
            fields: { ...c.fields, header: { ...c.fields.header, position } },
        }));
    };

    return (
        <Grid container style={{ flexDirection: "column" }}>
            <Grid item container justifyContent="space-between">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="logo"
                                color="primary"
                                checked={certificate.fields.header.logo}
                                onChange={handleCheckbox}
                            />
                        }
                        label="Logo"
                    />
                </Grid>

                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="profilePicture"
                                color="primary"
                                checked={
                                    certificate.fields.header.profilePicture
                                }
                                onChange={handleCheckbox}
                            />
                        }
                        label="Profile Picture"
                    />
                </Grid>
            </Grid>

            <Grid item container style={{ marginTop: 2 }}>
                <Grid item xs={12}>
                    <Typography>Alignment</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: 1,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <IconButton onClick={() => handleLogoPosition("left")}>
                        <AlignHorizontalLeft
                            fontSize="small"
                            htmlColor={
                                certificate.fields.header.position === "left"
                                    ? theme.palette.primary.main
                                    : "inherit"
                            }
                        />
                    </IconButton>

                    <IconButton onClick={() => handleLogoPosition("center")}>
                        <AlignHorizontalCenter
                            fontSize="small"
                            htmlColor={
                                certificate.fields.header.position === "center"
                                    ? theme.palette.primary.main
                                    : "inherit"
                            }
                        />
                    </IconButton>

                    <IconButton onClick={() => handleLogoPosition("right")}>
                        <AlignHorizontalRight
                            fontSize="small"
                            htmlColor={
                                certificate.fields.header.position === "right"
                                    ? theme.palette.primary.main
                                    : "inherit"
                            }
                        />
                    </IconButton>

                    <IconButton
                        onClick={() => handleLogoPosition("space-between")}
                        disabled={
                            !certificate.fields.header.logo ||
                            !certificate.fields.header.profilePicture
                        }
                    >
                        <Expand
                            sx={{ rotate: "90deg" }}
                            fontSize="small"
                            htmlColor={
                                certificate.fields.header.position ===
                                "space-between"
                                    ? theme.palette.primary.main
                                    : "inherit"
                            }
                        />
                    </IconButton>

                    <IconButton
                        onClick={() => handleLogoPosition("space-evenly")}
                        disabled={
                            !certificate.fields.header.logo ||
                            !certificate.fields.header.profilePicture
                        }
                    >
                        <Compress
                            sx={{ rotate: "90deg" }}
                            fontSize="small"
                            htmlColor={
                                certificate.fields.header.position ===
                                "space-evenly"
                                    ? theme.palette.primary.main
                                    : "inherit"
                            }
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Header;
