import { Avatar, Box, Grid, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { S3Service } from "../../../services/S3Service";
import { AppContext } from "../../AppContext";
import { awsDirStruct, basePath } from "../../constants";
import PrintTable from "../../Export/PrintTable";
import { CertificateComponents } from "../Certificates";

const Academic = ({
    fields,
    logoPosition,
    isDuplicate = false,
    baseUser,
}: {
    fields: CertificateComponents;
    logoPosition?: any;
    isDuplicate?: boolean;
    baseUser?: any;
}) => {
    const [logo, setLogo] = useState("");
    const [printMode, setPrintMode] = useState(false);
    const [pic, setPic] = useState("");

    // const styles = useStyles();
    const { store } = useContext(AppContext);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();

    const reSizeFont = (fontSize: number) =>
        Math.ceil((fontSize / (wrapperRef.current?.clientWidth ?? 1)) * 100);

    const getProfilePic = async () => {
        const profilePicKey =
            awsDirStruct.profile.picture + "/" + baseUser?.userId;
        const [data, err] = await S3Service.getS3ByKey(profilePicKey);

        if (data.url) {
            try {
                console.log(data.url);
                setPic(data.url);
            } catch (error) {}
        }
    };

    useEffect(() => {
        if (store?.logo) setLogo(store.logo);
        else setLogo(`${basePath}/icon.jpg`);
    }, [store.logo]);

    useEffect(() => {
        getProfilePic();
    }, []);

    if (!fields) return <h1>fields not found</h1>;

    return (
        <>
            <div
                style={{
                    background: theme.palette.primary.main,
                    color: "white",
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "1rem",
                }}
            >
                <PrintTable
                    printMode={printMode}
                    setPrintMode={setPrintMode}
                    componentRef={wrapperRef}
                />
            </div>

            <div
                ref={wrapperRef}
                style={{
                    position: "relative",
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                {isDuplicate && (
                    <Box
                        position="absolute"
                        // border="1px solid red"
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            opacity: "0.06",
                            fontWeight: "bolder",
                            textTransform: "uppercase",
                            transform: "rotate(-30deg)",
                            color: "black",
                        }}
                    >
                        <Typography
                            variant="h1"
                            style={{ fontWeight: "bolder" }}
                        >
                            provisional
                        </Typography>
                    </Box>
                )}
                <Grid
                    container
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        aspectRatio: "11/8.5",
                        width: "100%",
                        padding: theme.spacing(2),
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        breakAfter: "always",
                    }}
                >
                    <Grid
                        item
                        container
                        style={{
                            alignItems: "center",
                            justifyContent: fields?.header?.position,
                            width: "90%",
                            gap: theme.spacing(4),
                        }}
                    >
                        {fields?.header?.logo && (
                            <Grid item>
                                <Avatar
                                    src={logo}
                                    style={{ width: "60px", height: "60px" }}
                                />
                            </Grid>
                        )}

                        {fields?.header?.profilePicture && (
                            <Grid item>
                                <Avatar
                                    style={{ width: "60px", height: "60px" }}
                                    src={pic}
                                ></Avatar>
                            </Grid>
                        )}
                    </Grid>

                    <Grid
                        item
                        style={{
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            letterSpacing: 2,
                        }}
                    >
                        <Typography
                            style={{
                                color: fields?.subTitle?.color,
                                fontSize: fields?.subTitle?.fontSize,
                            }}
                        >
                            {fields?.subTitle?.text}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography
                            variant="h4"
                            style={{
                                color: fields?.userName?.color,
                                fontSize: fields?.userName?.fontSize,
                                textTransform: "uppercase",
                                fontWeight: "bold",
                            }}
                        >
                            {fields?.userName?.text}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        style={{
                            width: "60%",
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        {/* className={styles.body} */}
                        <Typography
                            style={{
                                color: fields?.body?.color,
                                fontSize: fields?.body?.fontSize,
                            }}
                        >
                            {fields?.body?.text}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography
                            variant="h3"
                            style={{
                                textTransform: "uppercase",
                                letterSpacing: 2,
                                fontWeight: "bold",
                                color: fields?.title?.color,
                                fontSize: fields?.title?.fontSize,
                            }}
                        >
                            {fields?.title?.text}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography
                            style={{
                                width: "60%",
                                marginLeft: "auto",
                                marginRight: "auto",
                                textAlign: "center",
                                color: fields?.additionalBody?.color,
                                fontSize: fields?.additionalBody?.fontSize,
                            }}
                        >
                            {fields?.additionalBody?.text}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        spacing={1}
                        style={{
                            alignItems: "end",
                            width: "90%",
                            justifyContent: fields.footer?.position,
                            gap: theme.spacing(4),
                            marginTop: theme.spacing(4),
                        }}
                    >
                        {(fields?.footer?.fields ?? [])
                            ?.filter((item: any) => item.checked)
                            .map((item, id) => (
                                <Grid
                                    item
                                    key={id}
                                    style={{ textAlign: "center" }}
                                >
                                    {item.type === "image" && (
                                        <img
                                            src={item.url}
                                            style={{
                                                width: "75px",
                                                aspectRatio: "1/1",
                                                borderRadius:
                                                    theme.shape.borderRadius,
                                                marginBottom: theme.spacing(1),
                                                objectFit: "cover",
                                            }}
                                        />
                                    )}

                                    {item.type === "text" && (
                                        <span
                                            style={{
                                                width: "100%",
                                                height: "2px",
                                                backgroundColor: "gray",
                                                display: "block",
                                            }}
                                        />
                                    )}
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: "Gray",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.label}
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Academic;
