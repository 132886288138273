import { Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ClassroomService } from "../../services/ClassroomService";
import { StudentService } from "../../services/StudentService";

const TargetEntity = ({ row }: any) => {
    console.log(row, "row");
    const [target, setTarget] = useState<any>(null);

    const getTargetEntity = async () => {
        if (row.discountType === "classroom") {
            const [data, err] = await ClassroomService.getClassroom(1, 100, {
                id: row.targetId,
            });

            console.log(data, "classroom");

            if (data && data.rows.length > 0) {
                setTarget(data.rows[0]);
            }
        } else if (row.discountType === "student") {
            const [data, err] = await StudentService.getStudent(1, 100, {
                id: row.targetId,
            });
            console.log(data, "student");

            if (data && data.rows.length > 0) {
                setTarget(data.rows[0]);
            }
        }
    };

    useEffect(() => {
        if (row.id) getTargetEntity();
        console.log(target, "target");
    }, [row]);

    return (
        <Typography>
            {target && row.discountType === "classroom"
                ? target.name
                : target?.baseUser?.name}
        </Typography>
    );
};

export default TargetEntity;
