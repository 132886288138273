import { Check, Close } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FeeDiscountService } from "../../services/FeeDiscountService";
import { FeeStructureService } from "../../services/FeeStructureService";

const ShowFeeStructure = ({ row }: any) => {
    const [feeStructure, setFeeStructure] = useState<any>(null);
    const [feeDiscout, setFeeDiscout] = useState<any>([]);
    const [totalReport, setTotalReport] = useState({
        original: 0,
        discount: 0,
        net: 0,
    });
    const [reportPerCategory, setReportPerCategory] = useState<any>(null);

    const id = useParams<any>();

    const getFeeStructureByid = async () => {
        const [data, err] = await FeeStructureService.getFeeStructure(1, 1, id);
        setFeeStructure(data.rows[0]);
    };

    const getFeeDiscout = async () => {
        const otherOps = feeStructure.categories
            .map((cat: any) => cat.category.id)
            .reduce(
                (prevOp: any, currentId: number) => ({
                    op: "in",
                    isDate: false,
                    col: "category",
                    operands: [...prevOp.operands, currentId],
                }),
                { operands: [] }
            );
        const filter = {
            otherOps: JSON.stringify([
                { ...otherOps, operands: [otherOps.operands] },
            ]),
        };

        const [data, err] = await FeeDiscountService.getFeeDiscount(
            1,
            100,
            filter
        );
        if (data) {
            setFeeDiscout(data.rows);
        }
    };

    const createReportPerCat = () => {
        const _report: any = {};

        for (const cat of feeStructure.categories) {
            let discountAmount = 0;
            let totalAmount = 0;
            const categoryDiscounts = feeDiscout.filter(
                (d: any) => d.category.id === cat.category.id
            );

            for (const discount of categoryDiscounts) {
                discountAmount += discount.pct
                    ? cat.amount * (discount.pct / 100)
                    : discount.amount;
            }

            const netAmount = discountAmount;
            let discount: number = 0;
            let withoutDiscount: number = 0;

            if (netAmount === 0) {
                discount = 0;
                withoutDiscount = cat.amount;
            } else {
                discount = netAmount;
                totalAmount = cat.amount - netAmount;
            }

            _report[cat?.category?.id] = {
                original: cat?.amount,
                discount: discount,
                net: netAmount === 0 ? withoutDiscount : totalAmount,
            };
        }

        const totalOriginal = Object.values(_report).reduce(
            (acc: any, reportPerCat: any) => reportPerCat.original + acc,
            0
        ) as any;

        const totalDiscount = Object.values(_report).reduce(
            (acc: any, reportPerCat: any) => reportPerCat.discount + acc,
            0
        ) as any;

        const totalNet = totalOriginal - totalDiscount;

        setReportPerCategory(_report);
        setTotalReport({
            original: totalOriginal,
            discount: totalDiscount,
            net: totalNet,
        });
    };

    useEffect(() => {
        getFeeStructureByid();
    }, []);

    useEffect(() => {
        if (feeStructure) {
            getFeeDiscout();
        }
    }, [feeStructure]);

    useEffect(() => {
        if (feeStructure) {
            createReportPerCat();
        }
    }, [feeStructure, feeDiscout]);

    return feeStructure ? (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Fee Category</TableCell>
                        <TableCell>Optional</TableCell>
                        <TableCell>Editable</TableCell>
                        <TableCell>Total Amount</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Net Amout</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportPerCategory &&
                        feeStructure?.categories?.map((cat: any) => (
                            <TableRow>
                                <TableCell>{cat?.category?.name}</TableCell>
                                <TableCell>
                                    {cat?.category?.optional === true ? (
                                        <Check />
                                    ) : (
                                        <Close />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {cat?.category?.editable === true ? (
                                        <Check />
                                    ) : (
                                        <Close />
                                    )}
                                </TableCell>

                                <TableCell>
                                    {
                                        reportPerCategory[cat?.category?.id]
                                            .original
                                    }
                                </TableCell>
                                <TableCell>
                                    {
                                        reportPerCategory[cat?.category?.id]
                                            .discount
                                    }
                                </TableCell>
                                <TableCell>
                                    {reportPerCategory[cat?.category?.id].net}
                                </TableCell>
                            </TableRow>
                        ))}
                    <TableRow>
                        <TableCell colSpan={3}></TableCell>
                        <TableCell>{totalReport.original}</TableCell>
                        <TableCell>{totalReport.discount}</TableCell>
                        <TableCell>{totalReport.net}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <Alert severity="warning">
            <AlertTitle>
                Must provide a{" "}
                <Link to="/add-fee-structure" style={{ color: "orange" }}>
                    fees structure
                </Link>
                &nbsp; to show
            </AlertTitle>
        </Alert>
    );
};

export default ShowFeeStructure;
