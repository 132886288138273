import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class StudentCategoryService {
    static categoryUrl = `${baseAPI}/org/student/category`;
    static assignedCategoryUrl = `${baseAPI}/org/student/assigned-category`;

    static async createStudentCategory(data: any) {
        try {
            const response = await axios.post(
                StudentCategoryService.categoryUrl,
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (error: any) {
            return [null, error.response?.data?.message || notConnectedMessage];
        }
    }

    static async changeLevel(id: number | string, direction: string) {
        try {
            const response = await axios.patch(
                StudentCategoryService.categoryUrl + "/sequence",
                {},
                {
                    params: { id, direction },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getStudentCategory(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                StudentCategoryService.categoryUrl,
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    // Soft Deleting of the Student
    static async deleteStudentCategorySoft(id: string | number) {
        try {
            const response = await axios.delete(
                StudentCategoryService.categoryUrl + "/soft",
                {
                    params: { id },
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
    static async deleteStudentCategoryHard(id: string | number) {
        try {
            const response = await axios.delete(
                StudentCategoryService.categoryUrl + "/hard",
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async editStudentCategory(id: string, newStudentCategory: any) {
        try {
            const response = await axios.patch(
                StudentCategoryService.categoryUrl,
                newStudentCategory,
                {
                    withCredentials: true,
                    params: { id },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err.response);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async studentAssignedCategories(student: number) {
        try {
            const response = await axios.get(
                StudentCategoryService.assignedCategoryUrl,
                {
                    params: { student },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
