import { Upgrade } from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CampusService } from "../../../services/CampusService";
import { ClassroomService } from "../../../services/ClassroomService";
import {
    APIRequestStatus,
    useGetAPI,
    usePostAPI,
} from "../../../services/Hooks";
import { SectionServices } from "../../../services/SectionServices";
import { SessionService } from "../../../services/SessionService";
import { StudentService } from "../../../services/StudentService";
import { AppContext } from "../../AppContext";
import { Autocomplete } from "../../AutoComplete";
import { baseAPI } from "../../constants";

const studentCols = [
    "File no.",
    "Enroll no.",
    "Name",
    "Father name",
    "Classroom",
];

export const PromoteSection = () => {
    const history = useHistory();

    const { user } = useContext(AppContext);
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 0,
        count: 0,
    });
    const [feedback, setFeedback] = useState({
        message: "",
        hidden: false,
        severity: "",
        loading: false,
    });

    const [filterData, setFilterData] = useState<any>({
        _toSession: [],
    });
    const [campuses, setCampuses] = useState<any[]>([]);
    const [selected, setSelected] = useState<any>({
        campus:
            user?.baseUser?.userRegions?.length > 1
                ? ""
                : user?.baseUser?.userRegions[0]?.campus?.id,
        fromSession: "",
        fromClassroom: "",
        fromSection: "",
        toSession: "",
        toClassroom: "",
        toSection: "",
        exclude: [],
    });

    const getCampuses = async () => {
        const [data, err] = await CampusService.getCampus(1, 0);
        if (data) {
            setCampuses(data.rows);
        }
    };

    useEffect(() => {
        getCampuses();
    }, []);

    const [classrooms, _, classroomStatus, _m] = useGetAPI(
        ClassroomService.url,
        1,
        0,
        { campus: selected.campus },
        [selected.campus],
        [selected.campus]
    );

    const [sessions, ____, sessionStatus, ____m] = useGetAPI(
        SessionService.url,
        1,
        0,
        { campus: selected.campus },
        [selected.campus],
        [selected.campus]
    );

    const [fromSections, __, fromSectionsStatus, __m] = useGetAPI(
        SectionServices.url,
        1,
        0,
        { classroom: selected.fromClassroom },
        [selected.fromClassroom],
        [selected.fromClassroom]
    );

    const [toSections, ___, toSectionsStatus, ___m] = useGetAPI(
        SectionServices.url,
        1,
        0,
        { classroom: selected.toClassroom },
        [selected.toClassroom],
        [selected.toClassroom]
    );

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSelected({ ...selected, [ev.target.name]: ev.target.value });
    };

    const [
        promoteSection,
        promotionResponse,
        promotionStatus,
        promotionMessage,
    ] = usePostAPI(baseAPI + "/org/academics/promotion/by-section");

    const handlePromoteSection = async () => {
        console.log(selected);

        if (!selected.toSession) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a session  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else if (!selected.toClassroom) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a classroom  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else if (!selected.toSection) {
            setFeedback({
                loading: false,
                message:
                    "You must provide a section  in order to update a student",
                hidden: true,
                severity: "error",
            });
            return;
        } else {
            promoteSection(selected, {});
        }
    };

    const [students, studentCount, studentStatus, studentMessage] = useGetAPI(
        StudentService.url,
        pagination.page + 1,
        pagination.limit,
        { classroom: selected.fromClassroom, section: selected.fromSection },
        [selected.fromClassroom, selected.fromSection],
        [selected.fromClassroom, selected.fromSection]
    );

    const handleCheckChange = (type: "single" | "all", id?: number) => {
        if (type === "single") {
            if (selected.exclude.includes(id)) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: selected.exclude.filter(
                        (_id: number) => _id !== id
                    ),
                }));
            } else {
                setSelected((s: any) => ({
                    ...s,
                    exclude: [...s.exclude, id],
                }));
            }
        } else {
            if (selected.exclude.length === 0) {
                setSelected((s: any) => ({
                    ...s,
                    exclude: students.map((std: any) => std.id),
                }));
            } else {
                setSelected((s: any) => ({ ...s, exclude: [] }));
            }
        }
    };

    useEffect(() => {
        setPagination((_p) => ({ ..._p, count: studentCount }));
    }, [studentCount]);

    useEffect(() => {
        if (promotionResponse?.errors?.length) return;

        if (promotionStatus == "success") {
            const timer = setTimeout(() => {
                history.replace("/student");
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [promotionStatus, history]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    {feedback.hidden && (
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    )}
                </Grid>
                {(promotionStatus === APIRequestStatus.success ||
                    promotionStatus === APIRequestStatus.error) && (
                    <Alert
                        severity={
                            promotionResponse.errors.length
                                ? "error"
                                : "success"
                        }
                    >
                        <AlertTitle>Promote student</AlertTitle>
                        {promotionMessage}
                    </Alert>
                )}
            </Grid>

            {user?.baseUser?.userRegions?.length > 1 && (
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        api="/org/campus"
                        setOutput={(c) =>
                            setSelected({
                                ...selected,
                                campus: c?.id || "",
                            })
                        }
                        label="Campus"
                        labelKey="name"
                        textFieldProps={{ variant: "outlined", size: "small" }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSession: c?.id || "",
                        })
                    }
                    label="From Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromClassroom: c?.id || "",
                        })
                    }
                    label="From Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            fromSection: c?.id || "",
                        })
                    }
                    label="From Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.fromClassroom,
                        status: "active",
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/session"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSession: c?.id || "",
                        })
                    }
                    label="To Session"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        campus: selected.campus,
                        status: "active",
                    }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromSession)
                    }
                    dependencies={{ ["fromSession"]: selected.fromSession }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/classroom"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toClassroom: c?.id || "",
                        })
                    }
                    label="To Class"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{ campus: selected.campus, status: "active" }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromClassroom)
                    }
                    dependencies={{ ["fromClassroom"]: selected.fromClassroom }}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Autocomplete
                    api="/org/academics/section"
                    setOutput={(c) =>
                        setSelected({
                            ...selected,
                            toSection: c?.id || "",
                        })
                    }
                    label="To Section"
                    labelKey="name"
                    textFieldProps={{ variant: "outlined", size: "small" }}
                    apiParams={{
                        classroom: selected.toClassroom,
                        status: "active",
                    }}
                    filter={(opts) =>
                        opts.filter((opt) => opt.id !== selected.fromSection)
                    }
                    dependencies={{ ["fromSection"]: selected.fromSection }}
                />
            </Grid>

            {selected.campus == "" ||
            selected.fromSession == "" ||
            selected.fromClassroom == "" ||
            selected.fromSection == "" ? (
                <Alert
                    severity="info"
                    style={{ width: "100%", marginTop: "1rem" }}
                >
                    Please Select a campus,session,classroom and section
                </Alert>
            ) : (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow
                                    sx={{ "&>*": { bgcolor: "action.hover" } }}
                                >
                                    {studentStatus ===
                                        APIRequestStatus.loading && (
                                        <TableCell colSpan={6}>
                                            <LinearProgress />
                                        </TableCell>
                                    )}
                                    {studentStatus ===
                                        APIRequestStatus.error && (
                                        <TableCell colSpan={6}>
                                            <Alert severity="error">
                                                {studentMessage}
                                            </Alert>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                selected.exclude.length === 0
                                            }
                                            onChange={() =>
                                                handleCheckChange("all")
                                            }
                                            indeterminate={
                                                selected.exclude.length > 0 &&
                                                selected.exclude.length <
                                                    studentCount
                                            }
                                        />
                                    </TableCell>
                                    {studentCols.map((col) => (
                                        <TableCell key={col}>{col}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {studentStatus === APIRequestStatus.success &&
                                    studentCount === 0 && (
                                        <TableRow>
                                            <TableCell colSpan={6}>
                                                <Alert severity="info">
                                                    No students in classroom
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                {studentStatus === APIRequestStatus.success &&
                                    students.map((student: any) => (
                                        <TableRow key={student.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        !selected.exclude.includes(
                                                            student.id
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleCheckChange(
                                                            "single",
                                                            student.id
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {student.fileNo}
                                            </TableCell>
                                            <TableCell>
                                                {student.enrollmentNo}
                                            </TableCell>
                                            <TableCell>
                                                {student.baseUser.name}
                                            </TableCell>
                                            <TableCell>
                                                {student.fatherName}
                                            </TableCell>
                                            <TableCell>{`${student.classroom.name} (${student.section.name}), ${student.session.name}`}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={handlePromoteSection}
                    sx={{ height: "100%" }}
                    disabled={promotionStatus === APIRequestStatus.loading}
                    endIcon={
                        promotionStatus === APIRequestStatus.loading ? (
                            <CircularProgress size="1em" />
                        ) : (
                            <Upgrade />
                        )
                    }
                >
                    {promotionStatus === APIRequestStatus.loading
                        ? "Promoting..."
                        : "Promote"}
                </Button>
            </Grid>
        </Grid>
    );
};
