import { Add, FormatColorText, Remove } from "@mui/icons-material";
import {
    Button,
    ButtonGroup,
    Chip,
    Divider,
    Grid,
    IconButton,
    TextField,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";

type Props = {
    fields: any;
    setFields: Dispatch<SetStateAction<any>>;
    variables: any[];
    userType: string;
};

type ActiveField =
    | "title"
    | "subTitle"
    | "userName"
    | "body"
    | "additionalBody";

const useStyles = makeStyles((theme: Theme) => ({
    scrollBar: {
        "&::-webkit-scrollbar": {
            height: "5px",
            borderRadius: "1000px",
            border: `1px solid ${theme.palette.divider}`,
        },

        "&::-webkit-scrollbar-thumb": {
            background: theme.palette.background.default,
            borderRadius: "2.5px",
        },
    },
}));

const CertificateText = ({ fields, setFields, variables, userType }: Props) => {
    const [cursorPosition, setCursorPosition] = useState<any>(0);
    const [activeField, setActiveField] = useState<ActiveField>();
    const [activeFieldTextColor, setActiveFieldTextColor] = useState("");
    const classes = useStyles();
    const theme = useTheme();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCursorPosition(e.target.selectionStart);

        setFields({
            ...fields,
            [e.target.name]: { ...fields[e.target.name], text: e.target.value },
        });
    };

    const handleAddName = (e: any) => {
        setFields({
            ...fields,
            [activeField!]: {
                ...fields[activeField!],
                text:
                    fields[activeField!].text.substring(0, cursorPosition) +
                    e.target.textContent +
                    " " +
                    fields[activeField!].text.substring(
                        cursorPosition,
                        fields[activeField!].text.length
                    ),

                placeholders: [
                    ...fields[activeField!].placeholders,
                    e.target.textContent,
                ],
            },
        });
    };

    const handleActiveField = (e: any) => {
        setActiveField(() => e.target.name);
    };

    const handleFieldColor = (e: ChangeEvent<HTMLInputElement>) => {
        setFields({
            ...fields,
            [activeField!]: {
                ...fields[activeField!],
                color: e.target.value,
            },
        });
    };

    const handleFontSize = (button: string) => {
        let fontSize = fields[activeField!].fontSize;
        if (button === "add") {
            fontSize += 1;
        } else if (button === "remove") {
            fontSize -= 1;
        } else return;

        setFields({
            ...fields,
            [activeField!]: {
                ...fields[activeField!],
                fontSize: fontSize,
            },
        });
    };

    return (
        <>
            {activeField && (
                <Grid container alignItems="center" gap={2}>
                    <Grid item>
                        <Tooltip title="Text Color" placement="top">
                            <IconButton>
                                <FormatColorText
                                    sx={{ color: activeFieldTextColor }}
                                    fontSize="small"
                                />

                                <input
                                    type="color"
                                    name="textColor"
                                    onChange={handleFieldColor}
                                    style={{
                                        width: "60%",
                                        height: "60%",
                                        border: "none",
                                        outline: "none",
                                        opacity: "0",
                                        position: "absolute",
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <ButtonGroup color="inherit">
                            <Button
                                size="small"
                                onClick={() => handleFontSize("remove")}
                            >
                                <Remove fontSize="small" />
                            </Button>

                            <Button>{fields[activeField].fontSize}</Button>

                            <Button
                                size="small"
                                onClick={() => handleFontSize("add")}
                            >
                                <Add fontSize="small" />
                            </Button>
                        </ButtonGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            )}

            <div
                className={classes.scrollBar}
                style={{
                    margin: "12px 0 12px 0",
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    gap: "8px",
                    paddingBottom: "8px",
                }}
            >
                {variables[0][userType]?.map((v: any, id: number) => (
                    <Chip
                        key={id}
                        label={`[${v.replaceAll(" ", "_")}]`}
                        component="div"
                        onClick={handleAddName}
                        size="small"
                    />
                ))}
            </div>

            <TextField
                onFocus={handleActiveField}
                // onBlur={() => setActiveField(undefined)}
                label="Title"
                value={fields.title.text}
                name="title"
                size="small"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 1.5 }}
            />
            <TextField
                onFocus={handleActiveField}
                // onBlur={() => setActiveField(undefined)}
                label="Subtitle"
                value={fields.subTitle.text}
                name="subTitle"
                multiline
                size="small"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 1.5 }}
            />
            <TextField
                onFocus={handleActiveField}
                // onBlur={() => setActiveField(undefined)}
                label="Candiate Name"
                value={fields.userName.text}
                name="userName"
                multiline
                size="small"
                fullWidth
                // onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 1.5 }}
            />

            <TextField
                onFocus={handleActiveField}
                // onBlur={() => setActiveField(undefined)}
                multiline
                label="Body"
                size="small"
                fullWidth
                name="body"
                value={fields.body.text}
                onChange={(e) => {
                    return (
                        setFields({
                            ...fields,
                            body: { ...fields.body, text: e.target.value },
                        }),
                        setCursorPosition(e.target.selectionStart)
                    );
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 1.5 }}
            />
            {fields.additionalBody && (
                <TextField
                    onFocus={handleActiveField}
                    // onBlur={() => setActiveField(undefined)}
                    multiline
                    label="Additional Body"
                    size="small"
                    fullWidth
                    name="additionalBody"
                    value={fields.additionalBody.text}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                />
            )}

            <Grid
                item
                container
                spacing={1}
                style={{
                    alignItems: "end",
                    width: "90%",
                    justifyContent: fields.footer?.position,
                    gap: theme.spacing(4),
                    marginTop: theme.spacing(4),
                }}
            >
                {(fields?.footer?.fields ?? [])
                    ?.filter((item: any) => item.checked)
                    .map((item: any, id: any) => (
                        <Grid item key={id} style={{ textAlign: "center" }}>
                            {item.type === "image" && (
                                <img
                                    src={item.url}
                                    style={{
                                        width: "75px",
                                        aspectRatio: "1/1",
                                        borderRadius: theme.shape.borderRadius,
                                        marginBottom: theme.spacing(1),
                                        objectFit: "cover",
                                    }}
                                />
                            )}

                            {item.type === "text" && (
                                <span
                                    style={{
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor: "gray",
                                        display: "block",
                                    }}
                                />
                            )}
                            <Typography
                                variant="body2"
                                style={{
                                    color: "Gray",
                                    fontWeight: "bold",
                                }}
                            >
                                {item.label}
                            </Typography>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

export default CertificateText;
