import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class CertificateService {
    static url = `${baseAPI}/certificate`;

    static async createCertificate(data: any) {
        try {
            const response = await axios.post(CertificateService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getCertificates(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(CertificateService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteCertificate(certificateId: number) {
        try {
            const response = await axios.delete(CertificateService.url, {
                params: { id: certificateId },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getCertificatesHistory(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(
                CertificateService.url + "/history",
                {
                    params: { page, limit, ...filter },
                    withCredentials: true,
                }
            );

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }
}
