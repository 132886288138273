import { Done } from "@mui/icons-material";
import { CircularProgress, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

type Field = {
    id: number;
    key: string;
    type: "text" | "image";
    value: string;
    picture: any;
};

type Feedback = {
    show: boolean;
    loading: boolean;
    message: string;
    severity: "success" | "error" | "warning";
    deleted?: string;
};

type Props = {
    field: Field;
    items?: any;
    setItem: Dispatch<SetStateAction<any>>;
    feedback?: Feedback;
};

export default function ShowCustomField({
    field,
    items,
    setItem,
    feedback,
}: Props) {
    const handleChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        key: string
    ) => {
        setItem((its: any) => ({
            ...its,
            [key]: { ...its[key], value: e.target.value },
        }));
    };

    const handleImageChange = (e: any, key: string) => {
        console.log(e.target.files[0]);
        if (e.target.files) {
            setItem(() => ({
                ...items,
                [key]: {
                    ...items[key],
                    picture: e.target.files[0],
                    value: e.target.files[0] ? e.target.files[0].type : "",
                },
            }));
        }
    };

    return field.type === "text" ? (
        <TextField
            type="text"
            label={field.key}
            value={field.value}
            size="small"
            fullWidth
            onChange={(e) => handleChange(e, field.key)}
        />
    ) : (
        <TextField
            type="file"
            size="small"
            fullWidth
            label={field.key}
            InputProps={{
                endAdornment: feedback?.loading ? (
                    <CircularProgress size="20" />
                ) : feedback?.severity === "success" ? (
                    <Done color="success" />
                ) : null,
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: "image/*" }}
            onChange={(e) => handleImageChange(e, field.key)}
            helperText={
                feedback?.severity === "success"
                    ? "Image uploaded successfully!"
                    : ""
            }
        />
    );
}
