import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class AuthorService {
  static url = `${baseAPI}/library/author`;

  static async createAuthor(data: any) {
    try {
      const response = await axios.post(AuthorService.url, data, {
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async getAuthor(page = 1, limit = 20, filter = {}) {
    try {
      const response = await axios.get(AuthorService.url, {
        params: { page, limit, ...filter },
        withCredentials: true,
      });

      return [response.data, null];
    } catch (err: any) {
      console.log(err);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteAuthorSoft(id: string | number) {
    try {
      const response = await axios.delete(AuthorService.url + "/soft", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async deleteAuthorHard(id: string | number) {
    try {
      const response = await axios.delete(AuthorService.url + "/hard", {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }

  static async editAuthor(id: string, newAuthor: any) {
    try {
      const response = await axios.patch(AuthorService.url, newAuthor, {
        withCredentials: true,
        params: { id },
      });
      return [response.data, null];
    } catch (err: any) {
      console.log(err.response);
      return [null, err.response?.data?.message || notConnectedMessage];
    }
  }
}
