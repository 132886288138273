import { Close, Search } from "@mui/icons-material";
import { IconButton, TextField, TextFieldProps, useTheme } from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";

type Props = {
    setOutput: (value: string) => void;
    debounceChange?: boolean;
    label?: string;
    type?: TextFieldProps["type"];
};

const SearchBar = ({
    debounceChange = false,
    setOutput,
    label,
    type,
}: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const theme = useTheme();
    const searchRef = useRef(0);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(ev.target.value);

        if (!debounceChange) {
            // setFilter({ ...filter, search: ev.target.value });
            setOutput(ev.target.value);
        } else {
            searchRef.current++;
            const newRef = searchRef.current;

            setTimeout(() => {
                if (newRef === searchRef.current) {
                    // setFilter({ ...filter, search: ev.target.value });
                    setOutput(ev.target.value);
                    searchRef.current = 0;
                }
            }, 1000);
        }
    };

    return (
        <TextField
            fullWidth
            size="small"
            type={type ?? "text"}
            placeholder={label ?? "Search"}
            name="query"
            value={searchTerm}
            onChange={handleChange}
            sx={{ height: "100%" }}
            InputProps={{
                startAdornment: (
                    <Search
                        fontSize="small"
                        htmlColor={theme.palette.action.disabled}
                        sx={{ mr: theme.spacing(1) }}
                    />
                ),

                endAdornment: searchTerm && (
                    <IconButton
                        size="small"
                        onClick={() => {
                            setSearchTerm("");
                            setOutput("");
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                ),
            }}
        />
    );
};

export default SearchBar;
