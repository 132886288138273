import { Image, Subject } from "@mui/icons-material";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomFieldService } from "../../../services/CustomFieldService";
import AddView from "../../AddView";
import { RUDTable } from "../../Table/RUDTable";
import AddCustomFields from "./AddCustomFields";

type Props = {
    name: "campus" | "student";
};

const CustomFields = ({ name }: Props) => {
    const [changes, setChanges] = useState(0);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const id = query.get("template");

    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h4" color="primary">
                        {name === "campus" ? "Campus" : "Student"} Fields
                    </Typography>
                </Grid>

                <Grid item>
                    <AddView
                        label={`Add ${name} Fields`}
                        triggerRowsRefetch={() => setChanges(changes + 1)}
                    >
                        <AddCustomFields name={name} />
                    </AddView>
                </Grid>

                <Grid item xs={12}>
                    <RUDTable
                        getter={(page, limit) =>
                            CustomFieldService.getCustomFieldsTemplateComponents(
                                page,
                                limit,
                                { template: id }
                            )
                        }
                        editor={function (...args: any): Promise<any[]> {
                            throw new Error("Function not implemented.");
                        }}
                        deletor={(id: number | string) =>
                            CustomFieldService.deleteTemplateComponentSoft(id)
                        }
                        rowsPreprocessor={(row) => ({
                            ...row,
                            deletionId: row.id,
                        })}
                        readables={{
                            id: "ID",
                            key: "Field Name",
                        }}
                        editables={[]}
                        customCols={[
                            {
                                header: "Field Type",
                                content: (row) => (
                                    <Chip
                                        sx={{ textTransform: "capitalize" }}
                                        label={row.type}
                                        size="small"
                                        icon={
                                            row.type === "image" ? (
                                                <Image fontSize="small" />
                                            ) : (
                                                <Subject fontSize="small" />
                                            )
                                        }
                                    />
                                ),
                            },
                        ]}
                        updatingAgents={[id, changes]}
                        ops={{
                            read: "READ CUSTOM_FIELDS",
                            edit: "EDIT CUSTOM_FIELDS",
                            delete: "DELETE CUSTOM_FIELDS",
                        }}
                        storageKey="customFields"
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomFields;
