import { createTheme } from "@mui/material";
import { lightThemeOpts } from "./Light";

export const PrintTheme = createTheme({
    ...lightThemeOpts,
    palette: {
        ...lightThemeOpts.palette,
        background: {
            ...lightThemeOpts.palette?.background,
            default: "#fff",
        },
    },
    components: {
        MuiPaper: {
            defaultProps: { elevation: 0 },
        },
    },
});
