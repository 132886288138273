import { createTheme, ThemeOptions } from "@mui/material";

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#fcca4a",
    },
    // secondary: {
    //     main: "#303030",
    // },
    background: {
      paper: "#303030",
      default: "#202020",
    },
  },
  // components: {
  //     MuiAppBar: {
  //         styleOverrides: {
  //             root: {
  //                 backgroundColor: "#303030",
  //             },
  //         },
  //     },
  // },
} as ThemeOptions);
