export const basePath =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";
export const baseAPI =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8015/api"
        : "/api";

export const notConnectedMessage =
    "Unable to perform the task at this moment, make sure you're connected to the internet";

export const awsDirStruct = {
    profile: {
        picture: "profile/pictures",
        customData: "profile/custom_data",
    },

    campus: {
        picture: "campus/pictures",
    },

    library: {
        bookPictures: "library/books/covers",
        bookPdfs: "library/books/pdfs",
    },

    logsInfo: {
        logs: "logs",
    },
    org: {
        picture: "picture/certificate",
    },
};

export const dateFormatter = Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
});

export const monthFormatter = Intl.DateTimeFormat("en", {
    month: "long",
});

export const currencyFormatter = (value: number) => {
    const formatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "PKR",
    });
    return formatter.format(value);
};

const enOrdinalRules = new Intl.PluralRules("en-US", {
    type: "ordinal",
});

const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
]);

export const formatOrdinals = (n: number) => {
    const rule = enOrdinalRules.select(n);
    const suffix = suffixes.get(rule);
    return `${n}${suffix}`;
};
