import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { formatNumber } from "../../services/Hooks";

export const Students = ({ dashboardData }: any) => {
    const theme = useTheme();
    return dashboardData ? (
        <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item xs={12} md={6}>
                <ReactApexChart
                    options={{
                        labels: ["Male", "Female", "Others"],
                        legend: {
                            labels: { colors: [theme.palette.text.primary] },
                        },
                    }}
                    series={[
                        dashboardData.students.male,
                        dashboardData.students.female,
                        dashboardData.students.other,
                    ]}
                    type="pie"
                    height="300px"
                    width="450px"
                />
            </Grid>

            <Grid item container xs={12} md={6} spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardHeader subheader="Male Students" />
                        <CardContent>
                            <Typography variant="h4" color="#2196f3">
                                {formatNumber(dashboardData.students.male)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardHeader subheader="Female Students" />
                        <CardContent>
                            <Typography
                                variant="h4"
                                color="#00e676
"
                            >
                                {formatNumber(dashboardData.students.female)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardHeader subheader="Other Students" />
                        <CardContent>
                            <Typography variant="h4" color="primary">
                                {formatNumber(dashboardData.students.other)}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card elevation={0}>
                        <CardHeader subheader="Total Students" />
                        <CardContent>
                            <Typography variant="h4">
                                {formatNumber(
                                    dashboardData.students.male +
                                        dashboardData.students.female +
                                        dashboardData.students.other
                                )}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    ) : null;
};
