import { Certificate } from "./Certificates";
import Academic from "./Variants/Academic";

export const certificatesData: Certificate[] = [
  {
    type: "academic",
    fields: {
      header: { logo: false, profilePicture: false, position: "space-evenly" },
      title: {
        text: "[certificate title]",
        placeholders: [],
        color: "",
        fontSize: 48,
      },
      subTitle: {
        text: "this certifies that",
        placeholders: [],
        color: "",
        fontSize: 16,
      },
      userName: {
        text: "[Name]",
        placeholders: ["[Name]"],
        color: "",
        fontSize: 34,
      },
      body: {
        text: "Has completed the elementary course of study at Ridgebow Preparatory School and its therefore entitled to this",
        placeholders: [],
        color: "",
        fontSize: 16,
      },
      additionalBody: {
        text: " Given at the Ridgebow Auditorium this 25th day of February, year of 2022",
        placeholders: [],
        color: "",
        fontSize: 16,
      },
    },
    variant: Academic,
    thumbnail: "images/certificates/academic.jpg",
  },
];
