import axios from "axios";
import { baseAPI, notConnectedMessage } from "../components/constants";

export class FeeService {
    // static instance: FeeService | null = null
    // static getInstance() {
    //     if (!this.instance) {
    //         this.instance = new FeeService();
    //         return this.instance;
    //     } else {
    //         return this.instance;
    //     }
    // }

    static url = `${baseAPI}/org/fees`;

    static async getFees(page = 1, limit = 12, filter = {}) {
        try {
            const response = await axios.get(FeeService.url, {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async getAppliedFees(page = 1, limit = 10, filter = {}) {
        try {
            const response = await axios.get(FeeService.url + "/all-fees", {
                params: { page, limit, ...filter },
                withCredentials: true,
            });

            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async applyFeesByClassroom(data: any) {
        try {
            const response = await axios.post(
                FeeService.url + "/classroom",
                data,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async applyFeesByStudent(data: any) {
        try {
            const response = await axios.post(FeeService.url, data, {
                withCredentials: true,
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getFeeReport(student: string | number, collectionDate: any) {
        try {
            const response = await axios.get(FeeService.url + "/report", {
                withCredentials: true,
                params: { student, collectionDate },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async collect(collectedFee: any) {
        try {
            const response = await axios.post(
                FeeService.url + "/collection/collect",
                collectedFee,
                {
                    withCredentials: true,
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getCollections(page = 1, limit = 20, filter = {}) {
        try {
            const response = await axios.get(FeeService.url + "/collection", {
                withCredentials: true,
                params: { page, limit, ...filter },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async edit(id: string | number, newFees: any) {
        try {
            const response = await axios.patch(FeeService.url + "/", newFees, {
                withCredentials: true,
                params: { id },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async editMultiple(data: any, month: number) {
        try {
            const response = await axios.patch(
                FeeService.url + "/multiple",
                data,
                {
                    withCredentials: true,
                    params: { month },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getFeeGeneratedReport(
        campus: string,
        session: string,
        classroom: string,
        months: number[] = []
    ) {
        try {
            const response = await axios.get(FeeService.url + "/fee-report/", {
                withCredentials: true,
                params: {
                    campus,
                    session,
                    months: months.join(","),
                    classroom: classroom === "All" ? undefined : classroom,
                },
            });
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async getDefaulterReport(
        campus: any,
        classroom: number | string,
        threshold: any
    ) {
        try {
            const response = await axios.get(
                FeeService.url + "/fee-defaulter-report/",
                {
                    withCredentials: true,
                    params: {
                        campus,
                        classroom: classroom === "All" ? undefined : classroom,

                        threshold,
                    },
                }
            );
            return [response.data, null];
        } catch (err: any) {
            console.log(err);
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }
}
